import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../utils/Modal";
import xIcon from "../App/img/x-dark.png";

import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./TabSelector";
import SponsorForm from "./SponsorForm";
import PaymentForm from "./PaymentForm";
import { SubmissionError } from "redux-form";
import Api from "../../api/apiService";
import { sendBlast as sendSponsorshipBlast } from "../../actions/blastActions";
import { useDispatch } from "react-redux";
const SponsorModal = ({
  arena,
  user,
  type,
  isInitOpen,
  title,
  message,
  toggleModal,
  callBack,
  fetchArenaSponsors,
  arenaSponsors,
  fetchSponsorships
}) => {
  const [selectedTab, setSelectedTab] = useTabs(["Basic", "Payment"]);

  const [latestSponsorship, setLatestSponsorship] = useState([]);

  const [sponsorShip, setSponsorShip] = useState([]);

  const [showPayment, setShowPayment] = useState(false);

  const[isProcessing, setIsProcessing] =useState(false);

  const toggleModalWindow = (event) => {
    event.stopPropagation();
    toggleModal(!isInitOpen);
  };
  const onAccept = () => {
    callBack(!isInitOpen);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const arenaId = arena.id;
    const keysData = Object.keys(arenaSponsors);
    if (arenaId && keysData.includes(arenaId)) {
      const venueSponsor = arenaSponsors[arenaId];
      /*TODO: Check all the sponsorship records against current arena instead o f latest single record */
      const latestSponsorshipData = venueSponsor[0];
      const venuePaymentStatus = latestSponsorshipData?.payment?.status;

      if (venueSponsor && venuePaymentStatus === "succeeded") {
        //nothing to do here. Need optimization of this condition
      } else if (venueSponsor) {
        setSelectedTab("Payment");
        setLatestSponsorship(latestSponsorshipData);
      }
    }
  }, [arenaSponsors]);

  const formResult = async (values) => {

    const formValues = Object.values(values);
    const formKeys = Object.keys(values);
    const linkErrMsg = "Link is required";
    const joiningTermsErrMsg = "Joining Term is required";
    const logoErrMsg = "Please upload logo or use default log";
    if (formValues.length == 0) {
      throw new SubmissionError({
        link: linkErrMsg,
        attachment: logoErrMsg,
        joining_terms: joiningTermsErrMsg,
      });
    }
    const errorFields = {};
    if (!formKeys.includes("link") || values?.link?.trim() == "") {
      errorFields["link"] = linkErrMsg;
    }
    if (!formKeys.includes("attachment") && values?.profile == false) {
      errorFields["attachment"] = logoErrMsg;
    }

    if (!formKeys.includes("joining_terms") || values?.joining_terms == false) {
      errorFields["joining_terms"] = joiningTermsErrMsg;
    }
    if (Object.keys(errorFields).length > 0) {
      throw new SubmissionError({
        link: errorFields?.link ? errorFields?.link : "",
        profile: errorFields?.attachment ? errorFields?.attachment : "",
        joining_terms: errorFields?.joining_terms
          ? errorFields?.joining_terms
          : "",
      });
    }

    let formData = await new FormData();
    const file = await values?.attachment;
    await formData.append("image", file);
    await formData.append("link", values?.link);
    await formData.append("blast", values?.blast);
    await formData.append("arenaId", arena.id);
    await formData.append("useProfileImg", values?.profile);
    await formData.append("sponsorId", user.id);
    await formData.append(
      "type",
      type === "Primary Sponsor" ? "Primary" : "Preferred"
    );
    setIsProcessing(true)
    await Api.post("ArenasSponsors/create-sponsor", formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {

        setSponsorShip(result);
        fetchArenaSponsors(arena.id);
        //Save Blast Message here
        const text = {text: values?.blast};
        dispatch(sendSponsorshipBlast(text))
        setIsProcessing(false)
      })
      .catch((error) => {
        setIsProcessing(false)
        console.log("ERROR is ", error);
      });
    setSelectedTab("Basic");
  };

  const paymentStatus = (data) => {
    setShowPayment(data);
  };

  return (
    <Modal
      isOpen={isInitOpen}
      className={`sponsor-modal ${isProcessing ? 'disable-element':''}`}
      overlayClassName={`auth-popup-overlay ${isProcessing ? 'loading': ''}`}
    >
      <div className="message-container">
        <span
          onClick={toggleModalWindow}
          title="Close"
          style={{
            position: "absolute",
            top: "3px",
            right: "4px",
            background: "#ff00006b",
            padding: "6px 8px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <img src={xIcon} />
        </span>
        {!showPayment?
        (<div>
          <h1 style={{ margin: "1.2rem" }}>{`Venue® Sponsorship`}</h1>
          <div className="title-text">You are sponsoring the</div>
          <div className="title-text">{arena.name}</div>
          <div className="title-text">as a {type}</div>
        </div>
        ):(<div>
          <h1 style={{ margin: "1.2rem" }}>{`Venue® Sponsorship`}</h1>
          <div className="title-text">Congratulations!</div>
          <div className="title-text">You are {type} of the</div>
          <div className="title-text">{arena.name}</div>
       </div>
        )}
        <>
          <nav className="tab-container flex border-b border-gray-300">
            <TabSelector
              isActive={selectedTab === "Basic"}
              onClick={() => setSelectedTab("Basic")}
            >
              Basic Info
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "Payment"}
              onClick={() => setSelectedTab("Payment")}
            >
              Payment
            </TabSelector>
          </nav>
          <div className="tab-data-container" style={{ marginTop: "25px" }}>
            <TabPanel hidden={selectedTab !== "Basic"}>
              <SponsorForm
                onSubmit={formResult}
                fetchArenaSponsors={fetchArenaSponsors}
                type={type}
                data={latestSponsorship}
                profile={user.profilePicture}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "Payment"}>
              <PaymentForm
                paymentStatus={paymentStatus}
                onSubmit={formResult}
                arena={arena}
                user={user}
                type={type}
                fetchArenaSponsors={fetchArenaSponsors}
                arenaSponsors={arenaSponsors}
                fetchSponsorships={fetchSponsorships}
              />
            </TabPanel>
          </div>
        </>
      </div>
    </Modal>
  );
};
SponsorModal.propTypes = {
  arena: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  isInitOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default SponsorModal;
