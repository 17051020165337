import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MediaList from '../Media/MediaList';
//import { mediaListIds } from '../../constants';
import { mediaListIds, tooltipIds, toolTipMessages } from '../../constants';
import ToolTipR from '../Common/Tooltip/Tooltip';
const {id, place, type, effect, message } = toolTipMessages.greenLightInfo

import { getParameterByName } from 'utils/helpers';

class MyStream extends Component {
  UNSAFE_componentWillMount() {
    const { fetchStream, user, filters, sortType, sortOrder,
      currentPage } = this.props;
      fetchStream(user.id, currentPage, filters, sortType, sortOrder);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.fetchNextPage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchStream, user, filters, sortType, sortOrder, currentPage,
      filterArenaIds, typeIds } = this.props;
      if (filters !== nextProps.filters ||
        sortType !== nextProps.sortType ||
        sortOrder !== nextProps.sortOrder ||
        filterArenaIds !== nextProps.filterArenaIds ||
        typeIds !== nextProps.typeIds) {

      let filtered = true;
      fetchStream(user.id, currentPage, nextProps.filters, nextProps.sortType,
        nextProps.sortOrder, nextProps.filterArenaIds, nextProps.typeIds,
        filtered);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.fetchNextPage);
  }

  fetchNextPage = () => {
    const { fetchStream, user, filters, sortType, sortOrder, currentPage, hasMoreStreams } = this.props;

    if (hasMoreStreams && (window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      fetchStream(user.id, currentPage, filters, sortType, sortOrder);
    }
  }

  render() {
   const { myStream, greenlightMedia, arenasList } = this.props;
    return (
      <div className="full-page outerspace-bg">
        <div className="mystream-container">
          <div className="title-container has-tooltip">
            <h1>My 7G Stream™</h1>

            <ToolTipR
              id={id}
              place={place}
              type={type}
              effect={effect}
              message={message}
            />
          </div>
          <MediaList
            greenlightMedia={(id) => {
              const answer = confirm('Are you sure you want to remove this media content from your Stream?');
              answer && greenlightMedia(id);
            }}
            media={myStream}
            hasArenaFilter
            arenasList={arenasList}
            type={mediaListIds.myStream}
          />
        </div>
      </div>
    );
  }
}

MyStream.propTypes = {
  user: PropTypes.object.isRequired,
  myStream: PropTypes.array.isRequired,
  fetchStream: PropTypes.func.isRequired,
  greenlightMedia: PropTypes.func.isRequired,
  filters: PropTypes.array,
  sortType: PropTypes.number,
  sortOrder: PropTypes.number,
  filterArenaIds: PropTypes.array,
  typeIds: PropTypes.array,
  arenasList: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
};

export default MyStream;
