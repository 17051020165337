import React from 'react'; import PropTypes from 'prop-types';


const BlastField = ({ input, placeholder, type, maxLength, meta: { touched, error } }) => {
  return (
    <div className="field-container">
      <input
        {...input}
        placeholder={placeholder}
        className="field-text blast-input"
        type={type}
        maxLength={maxLength}
        />
      {
        touched && error &&
        <i className="error error-color">{error}</i>
      }
    </div>
  );
};

BlastField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export default BlastField;
