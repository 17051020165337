import React from "react";
import PropTypes from "prop-types";

import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import LinkList from "./LinkList";
import MediaField from "./MediaField";
// import Embedder from './Embedder';

const required = (value) => (value ? undefined : "Required");

const MediaForm = ({
  handleSubmit,
  submitMedia,
  updateCurrentLink,
  currentWhereLink,
  mediaUrl,
  contentMedium,
  setMediaLink,
  toggleUploadMedia,
  isEdit,
  contentType,
  thumbnailUrl,
}) => (
  <form
    onSubmit={handleSubmit((media) => {
      media["contentType"] = contentType;
      media["image"] = thumbnailUrl;
      media["medium"] = contentMedium;
      submitMedia(media);
    })}
    className="form-container media-form"
    autoComplete="off"
  >
    <div className="fields-container">
      <Field
        name="name"
        component={MediaField}
        type="text"
        label="Content Title* "
        validate={required}
      />
      <Field
        name="mediaUrl"
        component={MediaField}
        type="text"
        label="Content Link* "
        onChangeAction={setMediaLink}
        isEdit={isEdit}
        validate={required}
      />
      {/* <Embedder mediaUrl={mediaUrl} /> */}
      <label className="where-label">Download or Stream Link(s)</label>
    </div>
    <FieldArray
      name="links"
      component={LinkList}
      currentWhereLink={currentWhereLink}
      updateCurrentLink={updateCurrentLink}
    />
    <div className="line" />
    <div className="edit-media-buttons">
      <button
        type="button"
        onClick={toggleUploadMedia}
        className="cancel-button"
      >
        Cancel
      </button>
      <button className="form-button confirm-button">
        {isEdit ? "Update Media " : "Upload Content"}
      </button>
    </div>
  </form>
);

MediaForm.propTypes = {
  submitMedia: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateCurrentLink: PropTypes.func.isRequired,
  currentWhereLink: PropTypes.string,
  mediaUrl: PropTypes.string,
  contentMedium: PropTypes.string,
  setMediaLink: PropTypes.func,
  toggleUploadMedia: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  contentType: PropTypes.number,
  thumbnailUrl: PropTypes.string,
};

let MediaReduxForm = reduxForm({
  form: "signup",
})(MediaForm);

MediaReduxForm = connect((state) => ({
  initialValues: state.mediaReducer.editingMedia,
  contentType: state.mediaReducer.contentType,
  thumbnailUrl: state.mediaReducer.thumbnailUrl,
}))(MediaReduxForm);

export default MediaReduxForm;
