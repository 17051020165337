import React, { useEffect, useRef, useState } from "react";
import { fetchVenueRequestData } from "../../api/venueRequestApi";
import Greenlight from '../Greenlight/Greenlight';
import { generateImagePath } from "../../utils/helpers";
import { greenlightVenueRequst } from "../../actions/greenlightActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchVenueRequestDataAction, fetchVenueRequestDataSuccess } from "../../actions/venueActions";

const ShowVenueList = ({ changeSelectedTabToVenue, closeModal }) => {
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const venuesPerPage = 10;
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const venueRequestData = useSelector((state) => state.venueRequestReducer?.venueRequestData);
    const fieldConfig = [
        { fieldName: "arenaType", label: "Venue® Type" },
        { fieldName: "name", label: "Venue® name" },
        { fieldName: "description", label: "Venue Description" },
        { fieldName: "image", label: "Venue® image" },
    ];
    const loadMoreData = () => {
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        if (!venueRequestData || venueRequestData.length === 0) {
            fetchVenueRequestData()
                .then((res) => {
                    dispatch(fetchVenueRequestDataSuccess(res));
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [venueRequestData, dispatch]);


    useEffect(() => {
        const handleScroll = () => {
            const element = contentRef.current;
            if (element.scrollHeight - element.clientHeight - element.scrollTop < 50 &&
                currentPage * venuesPerPage < venueRequestData.length) {
                loadMoreData();
            }
        };
        contentRef?.current?.addEventListener('scroll', handleScroll);
        return () => {
            contentRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage, venueRequestData]);


    const greenlightVenue = (venueId) => {
        dispatch(greenlightVenueRequst(venueId))
            .then(() => {
                dispatch(fetchVenueRequestDataAction());
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const greenlightSingleVenue = (venueId) => {
        dispatch(greenlightVenueRequst(venueId))
            .then(() => {
                fetchVenueRequestData()
                    .then((res) => {
                        const updatedVenueIndex = res.findIndex((venue) => venue.id === venueId);
                        if (updatedVenueIndex !== -1) {
                            const updatedData = [...venueRequestData];
                            updatedData[updatedVenueIndex] = res[updatedVenueIndex];
                            dispatch(fetchVenueRequestDataSuccess(updatedData));
                            setSelectedVenue(res[updatedVenueIndex]);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const showVenueDetails = (venue) => {
        setSelectedVenue(venue);
    };

    const goBack = () => {
        setSelectedVenue(null);
    };

    return (
        <div>
            {selectedVenue ? (
                <>
                    <div
                        style={{

                            alignItems: "center",
                            marginBottom: "1rem"
                        }}
                    >
                        <div>
                            {fieldConfig.map((field) => (
                                <div
                                    key={field.fieldName}
                                    className={`venue-${field.fieldName}`}
                                    style={{ display: "flex", justifyContent: "space-around", marginBottom: "2rem" }} // Align label and value
                                >
                                    {field.fieldName === "image" ? (
                                        <>
                                            <div style={{ marginRight: "10px", display: "flex", justifyContent: "flex-start", width: "100%", fontSize: "15px", }}>
                                                {field.label}:
                                            </div>
                                            <img
                                                className="media-item-img"
                                                src={generateImagePath(selectedVenue)}
                                                alt="Venue Image"
                                                style={{ width: "100px", display: "flex", justifyContent: "flex-start" }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ marginRight: "10px", display: "flex", justifyContent: "flex-start", width: "100%", fontSize: "15px", }}>
                                                {field.label}:
                                            </div>
                                            <div style={{ marginRight: "10px", display: "flex", justifyContent: "flex-start", width: "100%", fontSize: "15px", }}>
                                                {selectedVenue[field.fieldName]}
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                            <div
                                className="venue-details"
                                style={{
                                    position: "absolute",
                                    top: "100px",
                                    right: "10px",
                                    backgroundColor: "rgb(142, 142, 142)"
                                }}
                            >
                                <div className="venue-greenlight">
                                    <Greenlight
                                        counter={selectedVenue.greenlightsCount}
                                        greenlit={selectedVenue?.Greenlights?.length}
                                        greenlightAction={() => greenlightSingleVenue(selectedVenue.id)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <button onClick={goBack} style={{ background: "black", color: "white", width: "100px", height: "1rem" }}>
                        Back
                    </button>
                </>
            ) : (
                <div style={{ height: "400px", overflowY: "auto", backgroundColor: "rgb(142, 142, 142)", position: 'relative', }} ref={contentRef}>
                    <div className="venue-list-container">
                        {venueRequestData?.slice(0, currentPage * venuesPerPage).map((venue, index) => (
                            <div
                                key={venue.id}
                                className="venue-item"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "1rem"
                                }}
                                onClick={() => showVenueDetails(venue)}
                            >
                                <div className="venue-img">
                                    <img
                                        className="media-item-img"
                                        src={generateImagePath(venue)}
                                        alt="Venue Image"
                                        style={{ width: "50px" }}
                                    />
                                </div>
                                <div className="venue-name">{venue.arenaType}</div>
                                <div className="venue-greenlight">
                                    <Greenlight
                                        counter={venue?.greenlightsCount}
                                        greenlit={venue?.Greenlights?.length}
                                        greenlightAction={() => greenlightVenue(venue.id)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            )}

        </div>
    );
};

export default ShowVenueList;
