import "./styles/index.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { compose, createStore, combineReducers, applyMiddleware } from "redux";
import * as reducers from "./reducers";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import axios from "axios";
import Config from "./Config";
import createLogger from "redux-logger";
import {
  persistStore,
  autoRehydrate,
  persistReducer,
  persistCombineReducers
} from "redux-persist";
import createFilter from "redux-persist-transform-filter";
import { reducer as burgerMenu } from "redux-burger-menu";
import CookieStorage from "redux-persist-cookie-storage";
import localForage from "localforage";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Router, browserHistory } from "react-router";
import { getRoutes } from "./routes";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import SetTransform from "./Transforms";

const reducer = combineReducers({ ...reducers, form: formReducer, burgerMenu });
const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== "production"
});

let enableLocalStorage = true;
try {
  window.localStorage.setItem("__u", "u");
} catch (e) {
  enableLocalStorage = false;
}
const persistWhiteList = ["loginReducer", "commonReducer"];

const persistConfig = {
  key: "root",
  /* storage:  enableLocalStorage ? localForage : new CookieStorage({
        expiration: {
          'default': null,
          'storeKey': 3600 * 24
        }
      }), */
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: persistWhiteList,
  transforms: [SetTransform]
};
const pReducer = persistReducer(persistConfig, reducer);
const enhancer = compose(applyMiddleware(thunk, logger));
let persistor;
let store;

export function getPersistor() {
  return persistor;
}

/*Only for using in API service*/
export function getStore() {
  return store;
}

function configureStore() {
  return new Promise((resolve, reject) => {
    try {
      const store = createStore(pReducer, applyMiddleware(thunk, logger));

      resolve(store);
      // persistor = persistStore(
      //   store,
      //   () => resolve(store)
      // );
    } catch (e) {
      console.log("eeeee", e);
      reject(e);
    }
  });
}

class ReduxAppWrapper extends Component {
  UNSAFE_componentWillMount() {
    console.log(Config)
    axios.defaults.headers.common["Content-Type"] = "*/*";
    axios.defaults.headers.common["Accept"] = "*/*";
    axios.defaults.baseURL = Config.serverUrl;
  }

  render() {
    const { store, persistor } = this.props;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={browserHistory}>{getRoutes(store)}</Router>
        </PersistGate>
      </Provider>
    );
  }
}

ReduxAppWrapper.propTypes = {
  store: PropTypes.object,
  persistor: PropTypes.object
};

async function init() {
  store = await configureStore();
  const persistor = persistStore(store);
  const container = document.getElementById("react-root");
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(<ReduxAppWrapper store={store} persistor={persistor} />);

  // ReactDOM.render(
  //   <ReduxAppWrapper store={store} />,
  //   document.getElementById('react-root')
  // );
}

init();
