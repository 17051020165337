import React from 'react';
import PropTypes from 'prop-types';

import FilterSort from '../../FilterSort/Arenas/FilterSort';
import ReactTooltip from 'react-tooltip';
import ToolTipR from '../../Common/Tooltip/Tooltip';
import { toolTipMessages } from '../../../constants';

const ArenaHubHeader = ({ title, seeAll = false, displayFilterBox = false }) => {

  const ToolTipSlug = () => {
    const {id, place, type, effect, message } = toolTipMessages.venueInfo
    return (
      <ToolTipR
          id={id}
          place={place}
          type={type}
          effect={effect}
          message={message}
      />
    )
  }

  return (
    <div className="arena-hub-header">
      <h2 className="header-title">
        { title } { seeAll && <a>SEE ALL</a> } <ToolTipSlug />
      </h2>
      {
        displayFilterBox &&
        <FilterSort id="allArenas" />
      }
    </div>
  );
};

ArenaHubHeader.propTypes = {
  title: PropTypes.string,
  seeAll: PropTypes.bool,
  displayFilterBox: PropTypes.bool,
};

export default ArenaHubHeader;
