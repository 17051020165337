import React from 'react';
import PropTypes from 'prop-types';

const FormField = ({
    input,
    label,
    type,
    defaultChecked,
    placeholder,
    paddingLeft0,
    paddingRight0,
    meta: { touched, error, warning },
    pattern,
    onBlur
  }) => {

  return (
    <div
      className={`
        field-container
        ${ paddingLeft0 ? 'padding-left-0' : ''}
        ${ paddingRight0 ? 'padding-right-0' : ''}
      `}
    >
      <div className="field-title-container">
       {type != 'checkbox' ? <label
          className={`field-title dark-color ${(touched && error) ? 'error-color' : ''}`}
        >
          {label}
        </label>: ''}
        {touched && error && <i className="field-error strong error-color">{error}</i>}
      </div>
      {type != 'checkbox' ? <input
        {...input}
        placeholder={placeholder}
        className="field-text"
        type={type}
        pattern={pattern}
        onBlur={onBlur && (value => input.onBlur(onBlur(value)))}
      /> : ''}
      {type != 'checkbox' ? <div className={`field-bottom-line dark-background ${(touched && error) ? 'background-error' : ''}`}/> : ''}
      {type == 'checkbox' ? <label
          className={`checkbox-label field-title dark-color ${(touched && error) ? 'error-color' : ''}`}
        >
           <input
            {...input}
            placeholder={placeholder}
            className="field-text"
            type={type}
            checked={defaultChecked}
            pattern={pattern}
            onBlur={onBlur && (value => input.onBlur(onBlur(value)))}
          /> {label}
        </label> : ''}
    </div>
  );
};

FormField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  paddingRight0: PropTypes.bool,
  paddingLeft0: PropTypes.bool,
  pattern: PropTypes.string,
  onBlur: PropTypes.func,
};

export default FormField;
