import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../../constants';
import { preventBoth } from '../../../utils/helpers';
import untickedIcon from 'components/Arena/img/unticked.png';
import tickedIcon from 'components/Arena/img/ticked.png';

import selectedIcon from './img/selected.svg';
import nonSelectedIcon from './img/not-selected.svg';

//import nonSelectedIcon from './img/not-selected.svg';
import { preventBothSort } from '../../../utils/helpers';

const SortBox = ({enabled, changeSort, filterSortId, toggleSort }) => {

  // Uncomment this for get current users greenlit venues only.
  // if (!authenticated) {
  //   filters = filters.filter(x => x.name != 'greenlit');
  // }
  console.log('filterSortId ' , filterSortId, enabled, toggleSort)
  const isGreenLightFilter = false;
  const inactiveIcon = isGreenLightFilter ? untickedIcon : nonSelectedIcon;

  const isActive = false;

  return (
    <div className="filters-box">
      <div className="filter-header">
        <h2>Sorting</h2>
      </div>
      <div className="filter-container">
        {
          constants.arenasSortingOptions.map((sorting) =>

            <div key={sorting.id} className='filter-list'>
              <div className='filter'>
                <button
                  onClick={() => preventBothSort(sorting.id, enabled, toggleSort)}
                  className= {(enabled == sorting.id) ? 'filter-item active filter-hide': 'filter-item  filter-hide'}
                >
                  {sorting.label}
                </button>
                <span
                  className= {(enabled.includes(sorting.id)) ? 'filter-item active': 'filter-item'}
                  onClick={() => preventBothSort(sorting.id, enabled, toggleSort)}
                >
                  <img src={(enabled.includes(sorting.id)) ? selectedIcon : nonSelectedIcon} />
                <label>{sorting.label}</label>
                </span>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

SortBox.propTypes = {
  filterSortId: PropTypes.string.isRequired,
  enabled: PropTypes.array,
  changeSort: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
};

export default SortBox;
