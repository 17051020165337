import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Greenlight from "../Greenlight/Greenlight";
import ArenaSponsor from "./ArenaSponsor";
import ArenaStats from "./ArenaStats";
import speakerIcon from "../Blast/img/green-speaker.png";
import SponsorContainer from "./SponsorContainer";
import { fetchDate } from "../../api/arenaApi";
import AlreadyPostedModel from "./AlreadyPostedModel";
import { mediaListIds, tooltipIds, toolTipMessages } from '../../constants';
import ToolTipR from "../Common/Tooltip/Tooltip";
const {id, place, type, effect, message } = toolTipMessages.sponsorship

const ArenaCover = ({
  arena,
  greenlightArena,
  toggleShareModal,
  toggleSponerPane,
  isSponsorPane,
  user,
  sponsorships,
  arenaSponsors,
  fetchArenaSponsors,
  fetchSponsorships,
  authenticated,
  toggleSponsor,
  showHideSponsor,
  setSponsorFalse,
  setSponsorTrue,
}) => {

  let background = {
    backgroundImage: `url(${arena.image})`,
  };

  let venueSponsors;
  const [isItSponsored, setIsItSponsored] = useState(false);
  const [isCutoff, setIsCutoff] = useState(false);
  const [isEnded, setIsEnded] = useState(false);

  var currentDate;

  const isSponsored = () => {
      if (sponsorships.length == 0) {
      //setIsItSponsored(false)
      //return false
    }
    const arenaId = arena.id;
    const sponsorship = sponsorships.filter(
      (sponsorshipData) => sponsorshipData.arenaId == arenaId
    );

    if (sponsorship.length > 0) {
      //setIsItSponsored(true)
      // return true
    } else {
      //setIsItSponsored(false)
    }

    //return false
  };

  const fetchVenueSponsors = () => {
    const arenaId = arena.id;
    if (Object.keys(arenaSponsors).includes(arenaId)) {
      const sponsor = arenaSponsors[arenaId][0];
      if (sponsor?.payment?.status === "succeeded") {
        return arenaSponsors[arenaId];
      }
    } else {
      return (arenaSponsors[arenaId] = []);
    }
  };

  useEffect(() => {
    fetchVenueSponsors();
  }, [arenaSponsors]);

  useEffect(() => {
    isSponsored();
  }, [sponsorships]);

  useEffect(() => {
    fetchCurrentDate();
  },[]);

  const fetchCurrentDate = async () => {
    currentDate = await fetchDate();
  };

  const handelClick = async (event) => {
    setSponsorFalse();
    toggleSponerPane();
    // if (currentDate.object.date > arena.endDate) {
    //    setIsEnded(true); //Working
    //           // toggleSponerPane();
    // } else if (currentDate.object.date > arena.cutOffDate) {
    //   setIsCutoff(true); //Working
    //            // toggleSponerPane();
    // } else {
    //   toggleSponerPane();
    // }
  };

  return (
    <div
      className="arena-cover-container"
      style={{ backgroundImage: "url(" + arena.image + ")" }}
    >
      <div className={`arena-cover-gradient ${arena.greenlit && "greenlit"}`} />
      <div className="arena-cover-overlay" />
      <div className="arena-info-ontop">
        <div className="arena-top-bar">
          <img className="arena-icon" />
          <h1>{arena.name}</h1>
          <Greenlight
            counter={arena.greenlightsCount}
            greenlit={arena.greenlit}
            greenlightAction={greenlightArena}
            noPadding={true}
          />
          <div className="divider-line" />
          <button className="share-button" onClick={toggleShareModal} />
        </div>
        <label className="arena-hashtag">{`#${
          arena.name ? arena.name.replace(/\s/g, "") : null
        }, #7GreenLights #VenueRevenue®`}</label>
        <div className="arena-blast-container">
          <img src={speakerIcon} />
          <label>{`"${arena.blast ? arena.blast : ""}"`}</label>
        </div>
        {!isItSponsored && (
          <span>
          <button
            style={{ padding: "0 10px", width: "auto" }}
            onClick={handelClick}
            className="form-button post-media"
          >
            Sponsor this Venue®
          </button>
          <ToolTipR
              id={id}
              place={place}
              type={type}
              effect={effect}
              message={message}
            />
            </span>
        )}
      </div>
      {isEnded && (
        <AlreadyPostedModel
          isInitOpen={isEnded}
          message={`Venue® is ended, You still can Sponsor.`}
          title={`Confirm action`}
          toggleModal={(status) => {
            setIsEnded(status);
          }}
        />
      )}
      {isCutoff && (
        <AlreadyPostedModel
          isInitOpen={isCutoff}
          message={`Cutoff date has passed, You still can Sponsor`}
          title={`Confirm action`}
          toggleModal={(status) => {
            setIsCutoff(status);
          }}
        />
      )}
      {!isEnded && !isCutoff && (
        <ArenaSponsor
          toggleSponsor={toggleSponsor}
          showHideSponsor={showHideSponsor}
          isSponsorPane={isSponsorPane}
          arena={arena}
          user={user}
          sponsorships={sponsorships}
          arenaSponsors={arenaSponsors}
          fetchArenaSponsors={fetchArenaSponsors}
          fetchSponsorships={fetchSponsorships}
        />
      )}
      {/* <div className="arena-sponsors">
        {
            fetchVenueSponsors() && fetchVenueSponsors().map((sponsor, index) =>
            <a
              key={index}
              href={sponsor.link}
              target="_blank"
            >
                <img src={sponsor.logo}/>
            </a>
          )
        }
      </div> */}

      <SponsorContainer
        toggleSponsor={toggleSponsor}
        showHideSponsor={showHideSponsor}
        sponsors={sponsorships}
        arenaSponsors={arenaSponsors}
        arena={arena}
        setSponsorTrue={setSponsorTrue}
        user={user}
      />
      <div className="arena-info-ontop">
        <div className="divider-line" />
        <ArenaStats arena={arena} />
      </div>
    </div>
  );
};

ArenaCover.propTypes = {
  arena: PropTypes.object.isRequired,
  isSponsorPane: PropTypes.bool.isRequired,
  toggleSponerPane: PropTypes.func.isRequired,
  greenlightArena: PropTypes.func.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
};

export default ArenaCover;
