import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

const ToolTip = ({ id, place, type, message, effect }) => {
  place = place ? place : "top"; // top(default) | right | left | bottom
  type = type ? type : "info"; // dark(default) | success | warning | error | info | light
  effect = effect ? effect : "float"; // float(default) | solid

  return (
    <>
      {/* <span
        className='tooltip-mark'
        data-tip data-for={id}
      >

        i

      </span> */}
      <span className="tooltip-mark" data-tip data-for={id}>
        <span className="fa-layers fa-fw"  >
          <FontAwesomeIcon icon={faCircle} color="gray" size="xs"  transform="shrink-2"/>
          <FontAwesomeIcon icon={faInfo} size="xs" transform="shrink-7"/>
        </span>
      </span>

      <ReactTooltip
        id={id}
        className="tooltip-container"
        arrowRadius="3"
        place={place}
        type={type}
        effect={effect}
      >
        <p >{message}</p>
      </ReactTooltip>
    </>
  );
};
ToolTip.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
export default ToolTip;
