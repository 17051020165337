export const TOGGLE_IN_HOMEPAGE = "TOGGLE_IN_HOMEPAGE";
export const TOGGLE_TOPBAR_MODAL = "TOGGLE_TOPBAR_MODAL";
export const TOGGLE_VENUE_MENUS = "TOGGLE_VENUE_MENUS";
export const CLOSE_VENUE_MENUS = "CLOSE_VENUE_MENUS";
export const TOGGLE_AUTH_MODAL = "TOGGLE_AUTH_MODAL";
export const TOGGLE_AUTH_MODAL_TYPE = "TOGGLE_AUTH_MODAL_TYPE";

export const SHOW_HIDE_SPONSOR = "SHOW_HIDE_SPONSOR";
export const SET_SPONSOR_TRUE = "SET_SPONSOR_TRUE";
export const SET_SPONSOR_FALSE = "SET_SPONSOR_FALSE";

export const UPDATE_COUNTDOWN_TIMER = "UPDATE_COUNTDOWN_TIMER";
export const GREENLIGHT_ARENA_TOGGLE = "GREENLIGHT_ARENA_TOGGLE";
export const GREENLIGHT_VENUE_REQUEST_TOGGLE = "GREENLIGHT_VENUE_REQUEST_TOGGLE";
export const REMOVE_MEDIA_LINK = "REMOVE_MEDIA_LINK";
export const SOCIAL_LOGIN_ERROR = "SOCIAL_LOGIN_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_EXIT = "SIGNUP_EXIT";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";

export const FETCH_ARENAS_REQUEST = "FETCH_ARENAS_REQUEST";
export const FETCH_ARENAS_FAILURE = "FETCH_ARENAS_FAILURE";
export const FETCH_ARENAS_SUCCESS = "FETCH_ARENAS_SUCCESS";
export const FETCH_ARENA_REQUEST = "FETCH_ARENA_REQUEST";
export const FETCH_ARENA_FAILURE = "FETCH_ARENA_FAILURE";
export const FETCH_ARENA_SUCCESS = "FETCH_ARENA_SUCCESS";
export const RESET_ARENAS_PAGE = "RESET_ARENAS_PAGE";
export const FETCH_ARENAS_MEDIA_FAILURE = "FETCH_ARENAS_MEDIA_FAILURE";
export const FETCH_ARENAS_MEDIA_SUCCESS = "FETCH_ARENAS_MEDIA_SUCCESS";
export const POST_MEDIA_ARENA_REQUEST = "POST_MEDIA_ARENA_REQUEST";
export const POST_MEDIA_ARENA_SUCCESS = "POST_MEDIA_ARENA_SUCCESS";
export const POST_MEDIA_ARENA_FAILURE = "POST_MEDIA_ARENA_FAILURE";
export const FETCH_ARENAS_USER_POSTED_SUCCESS = "FETCH_ARENAS_USER_POSTED_SUCCESS";
export const FETCH_VENUES_USER_POSTED_SUCCESS = "FETCH_VENUES_USER_POSTED_SUCCESS";
export const FETCH_FEATURED_ARENAS_SUCCESS = "FETCH_FEATURED_ARENAS_SUCCESS";
export const STOP_ARENA_LOADING = "STOP_ARENA_LOADING";
export const STOP_MORE_ARENA = "STOP_MORE_ARENA";
export const RESET_ARENA_LOADING = "RESET_ARENA_LOADING";

export const GREENLIGHT_ARENA_REQUEST = "GREENLIGHT_ARENA_REQUEST";
export const GREENLIGHT_VENUE_REQUEST = "GREENLIGHT_VENUE_REQUEST";
export const GREENLIGHT_ARENA_FAILURE = "GREENLIGHT_ARENA_TOGGLE";
export const GREENLIGHT_MEDIA_REQUEST = "GREENLIGHT_MEDIA_REQUEST";
export const GREENLIGHT_MEDIA_TOGGLE = "GREENLIGHT_MEDIA_TOGGLE";
export const GREENLIGHT_USER_REQUEST = "GREENLIGHT_USER_REQUEST";
export const GREENLIGHT_PROFILE_REQUEST = "GREENLIGHT_PROFILE_REQUEST";
export const GREENLIGHT_USER_TOGGLE = "GREENLIGHT_USER_TOGGLE";
export const GREENLIGHT_NOTE_REQUEST = "GREENLIGHT_NOTE_REQUEST";
export const GREENLIGHT_NOTE_TOGGLE = "GREENLIGHT_NOTE_TOGGLE";
export const STOP_STREAM_LOADING = "STOP_STREAM_LOADING";
export const ENABLE_STREAM_LOADING = "ENABLE_STREAM_LOADING";

export const ADD_MEDIA = "ADD_MEDIA";
export const SET_MEDIA_LINK = "SET_MEDIA_LINK";
export const UPDATE_CURRENT_LINK = "UPDATE_CURRENT_LINK";
export const FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const UPDATE_MEDIA_SUCCESS = "UPDATE_MEDIA_SUCCESS";
export const TOGGLE_SHOW_UPLOAD_MEDIA = "TOGGLE_SHOW_UPLOAD_MEDIA";
export const TOGGLE_EDIT_MEDIA = "TOGGLE_EDIT_MEDIA";
export const SET_MEDIA_EDIT_MODE = "SET_MEDIA_EDIT_MODE";
export const TOGGLE_REPORT_MODAL = "TOGGLE_REPORT_MODAL";
export const REPORT_MEDIA_FAILURE = "REPORT_MEDIA_FAILURE";
export const REPORT_MEDIA_TOGGLE_MODE = "REPORT_MEDIA_TOGGLE_MODE";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILURE = "FETCH_NOTES_FAILURE";

export const FETCH_STREAM_SUCCESS = "FETCH_STREAM_SUCCESS";

export const TOGGLE_POST_TO_ARENA = "TOGGLE_POST_TO_ARENA";
export const ADD_MEDIA_TO_POST = "ADD_MEDIA_TO_POST";
export const REMOVE_MEDIA_TO_POST = "REMOVE_MEDIA_TO_POST";

export const SWITCH_PROFILE_TAB = "SWITCH_PROFILE_TAB";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_FAILURE = "GET_MY_PROFILE_FAILURE";
export const GET_MY_BLAST_SUCCESS = "GET_MY_BLAST_SUCCESS";
export const GET_MY_NOTES ="GET_MY_NOTES";
export const GET_MY_NOTES_FAILURE ="GET_MY_NOTES_FAILURE";

export const GET_MY_BLAST_FAILURE = "GET_MY_BLAST_FAILURE";
export const GET_GREENLIT_PEOPLE_SUCCESS = "GET_GREENLIT_PEOPLE_SUCCESS";
export const GET_GREENLIT_PEOPLE_FAILURE = "GET_GREENLIT_PEOPLE_FAILURE";
export const GET_GREENLIGHT_PEOPLE_SUCCESS = "GET_GREENLIGHT_PEOPLE_SUCCESS";
export const GET_GREENLIGHT_PEOPLE_FAILURE = "GET_GREENLIGHT_PEOPLE_FAILURE";
export const UNMOUNT_PROFILE = "UNMOUNT_PROFILE";
export const FETCH_PROFILE_MEDIA_SUCCESS = "FETCH_PROFILE_MEDIA_SUCCESS";
export const FETCH_PROFILE_MEDIA_FAILURE = "FETCH_PROFILE_MEDIA_FAILURE";
export const TOGGLE_FILTER_BOX = "TOGGLE_FILTER_BOX";
export const TOGGLE_SORT_MODAL = "TOGGLE_SORT_MODAL";

export const SEND_BLAST_SUCCESS = "SEND_BLAST_SUCCESS";
export const SEND_BLAST_FAILURE = "SEND_BLAST_FAILURE";

export const CHANGE_SORT = "CHANGE_SORT";
export const TOGGLE_SORT = "TOGGLE_SORT";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const TOGGLE_FILTER_BY_ARENA = "TOGGLE_FILTER_BY_ARENA";
export const TOGGLE_FILTER_BY_TYPE = "TOGGLE_FILTER_BY_TYPE";
export const TOGGLE_FILTER_BY_POPS = "TOGGLE_FILTER_BY_POPS";
export const RESET_FILTERS = "RESET_FILTERS";

export const TOGGLE_SHARE_MODAL = "TOGGLE_SHARE_MODAL";

export const SWITCH_ABOUT_TAB = "SWITCH_ABOUT_TAB";

export const FEATURE_NOTE = "FEATURE_NOTE";
export const DISREGARD_NOTE = "DISREGARD_NOTE";
export const FEATURE_NOTES_SUCCESS = "FEATURE_NOTES_SUCCESS";
export const FEATURE_NOTES_FAILURE = "FEATURE_NOTES_FAILURE";
export const TOGGLE_NOTE_MODAL = "TOGGLE_NOTE_MODAL";

export const TOGGLE_NOTIFICATIONS_MODAL = "TOGGLE_NOTIFICATIONS_MODAL";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const CLOSE_NOTIFICATIONS_MODAL = "CLOSE_NOTIFICATIONS_MODAL";
export const UPDATE_UNREAD_NOTIFICATION_COUNT= "UPDATE_UNREAD_NOTIFICATION_COUNT"
export const MARK_NOTIFICATION_AS_READ="MARK_NOTIFICATION_AS_READ"

export const TOGGLE_MESSAGE_MODAL = "TOGGLE_MESSAGE_MODAL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const CLEAR_LOGIN_MODAL_MESSAGES = "CLEAR_LOGIN_MODAL_MESSAGES";
export const INITIALIZE_EDIT_PASSWORD = "INITIALIZE_EDIT_PASSWORD";

export const TOGGLE_TOOLTIP_SUCCESS = "TOGGLE_TOOLTIP_SUCCESS";

export const FETCH_CONTENT_REQUEST = "FETCH_CONTENT_REQUEST";
export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";
export const FETCH_CONTENT_FAILURE = "FETCH_CONTENT_FAILURE";
export const STOP_MORE_CONTENT = "STOP_MORE_CONTENT";
export const FETCH_CONTENT_TABS_SUCCESS = "FETCH_CONTENT_TABS_SUCCESS";
export const FETCH_CONTENT_TABS_FAILURE = "FETCH_CONTENT_TABS_FAILURE";

export const FETCH_SPONSORSHIPS_SUCCESS = "FETCH_SPONSORSHIPS_SUCCESS";
export const FETCH_SPONSORSHIP_REQUEST = "FETCH_SPONSORSHIP_REQUEST";
export const FETCH_SPONSORSHIPS_FAILURE = "FETCH_SPONSORSHIPS_FAILURE";
export const FETCH_ARENA_SPONSORS_FAILURE = "FETCH_ARENA_SPONSORS_FAILURE";
export const FETCH_ARENA_SPONSORS_SUCCESS = "FETCH_ARENA_SPONSORS_SUCCESS";

export const VENUE_REQUEST_SUCCESS = "VENUE_REQUEST_SUCCESS";
export const VENUE_REQUEST_DATA_SUCCESS = "VENUE_REQUEST_DATA_SUCCESS";



