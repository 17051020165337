import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import Api from "../../api/apiService"

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const {arena, user, type, savePayment,paymentStatus} = props;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [amount, setAmount] = useState(0)
  const setTextMessage = (status, amount = 0) => {
    switch (status) {
      case "succeeded":
        setMessage("Payment succeeded!");
        setPaymentSucceeded(true)
        paymentStatus(true)  //callback to SponsorModal
        break;
      case "processing":
        console.log("processing")
        setMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        console.log("requires_payment_method")
        setMessage("Your payment was not successful, please try again.");
        break;
      default:
        console.log("DEFAULT")
        setMessage("Something went wrong.");
        paymentStatus(false)  //callback to SponsorModal
        break;
    }
  }

  const paymentAmount = props.paymentAmount

  const formatMoney = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount)
  }

  const savePaymentStatus = (data) => {
    const status = data?.paymentIntent?.status
    // console.log('in payment form calling save now ')
    savePayment(data)
    switch (status) {
      case "succeeded":
        console.log("succeeded")

        break;
      case "processing":
        console.log("processing")

        break;
      case "requires_payment_method":
        // console.log("requires_payment_method")
        setMessage("Your payment was not successful, please try again.");
        break;
      default:
        // console.log("DEFAULT")
        setMessage("Something went wrong.");
        break;
    }
  }
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setTextMessage(paymentIntent.status)
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const confirmPaymentResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:8000/arena/638dacd7d004e50533be0bae",
      },
      redirect: 'if_required'
    });
    const { error, ...result } = confirmPaymentResult
    // Following comment will be true incase we set key redirect to "always" in above confirmPayment call
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    }
    const paymentStatus = result?.paymentIntent?.status
    setTextMessage(paymentStatus)

    if(result?.paymentIntent?.amount > 0) {
      const amountToDollar = result?.paymentIntent?.amount/100
      setAmount(amountToDollar)
    }
    savePaymentStatus(confirmPaymentResult)
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <>
    {paymentSucceeded ?
    <div className="payment-completed">
      <span className="payment-success"> </span>
      <span className="payment-success-msg">Success!</span>
      <p className="payment-description">{`You're payment of $${amount} is successful`}</p>
    </div>
    :<form id="payment-form" onSubmit={handleSubmit}>
      <div style={{
        fontSize:'18px',
        fontWeight:'bold',
        marginBottom:'15px'

      }}><span>Amount to pay: {formatMoney(paymentAmount)}</span></div>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="form-button greenlight-btn">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    }
    </>
  );
}