import * as types from './actionTypes';
import * as streamApi from '../api/streamApi';
import { toggleMessageModal, toggleLoadingIndicator } from './commonActions';
import * as constants from '../constants';

export function fetchStreamSuccess(stream, arena, page, filtered) {
  return {
    type: types.FETCH_STREAM_SUCCESS,
    stream,
    arena,
    page,
    filtered,
  };
}

export function fetchStream(userId, page, filters, sortType, sortOrder, arenaIds,
  typeIds, filtered = false) {
  return (dispatch) => {
    filtered && (page = 1);
    filtered && dispatch(toggleLoadingIndicator());
    return streamApi.fetchStream(userId, page, filters, sortType, sortOrder, arenaIds,
      typeIds)
      .then((response) => {
        dispatch(fetchStreamSuccess(response.stream, response.arena,  page,
          filtered));
        if(constants.STREAM_PAGE_SIZE > response?.stream?.length ) {
            dispatch(stopStreamLoad());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleMessageModal(error));
      });
  };
}

export function stopStreamLoad() {
  return {
    type: types.STOP_STREAM_LOADING
  };
}

export function enableStreamLoad() {
  return {
    type: types.ENABLE_STREAM_LOADING
  };
}
