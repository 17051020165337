import * as types from './actionTypes';
import axios from 'axios';
import * as loginApi from '../api/loginApi';
import { browserHistory } from 'react-router';
import * as constants from '../constants';
import { toggleLoadingIndicator } from './commonActions';
import { getPersistor, getStore } from '../index';

export function logout() {
  let user = getStore().getState().loginReducer.user;
  let accessToken = user ? user.token : null;
  return (dispatch) => {
    dispatch(toggleLoadingIndicator());
    return loginApi.logout(accessToken)
      .then(() => {
        browserHistory.push('/');
        axios.defaults.headers.common[constants.TOKEN_HEADER_NAME] = null;
        dispatch(logoutSuccess());
        //getPersistor().purge();
      })
      .catch((error) => {
        browserHistory.push('/');
        axios.defaults.headers.common[constants.TOKEN_HEADER_NAME] = null;
        console.log('LOGOUT ERROR ', error);
        dispatch(logoutSuccess());
      });
  };
}

export function logoutFailure() {
  return {
    type: types.LOGOUT_FAILURE,
    message: {
      text: 'There was an error logging out, please try again',
    }
  };
}

export function logoutSuccess() {
  return {
    type: types.LOGOUT_SUCCESS,
  };
}
