import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SponsorModal from "./SponsorModal";
import ConfirmSponsorship from "./ConfirmSponsorship";
import ConfirmPayment from "./ConfirmPayment";
import { fetchDate } from "../../api/arenaApi";

const ArenaSponsor = ({
  arena,
  user,
  isSponsorPane,
  autoInitSponsor = false,
  autoInitArchive = false,
  sponsorships,
  fetchArenaSponsors,
  arenaSponsors,
  fetchSponsorships,
  toggleSponsor,
  showHideSponsor,
}) => {
  // var currentDate;
  const [currentDate, setCurrentDate] = useState();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    // currentDate = await fetchDate();
    fetchCurrentDate();
  }, []);

  const fetchCurrentDate = async () => {
    setCurrentDate(await fetchDate());
  };

  const [isArchivedOpen, setIsArchivedOpen] = useState(autoInitArchive);
  const [isAlreadyArchive, setIsAlreadyArchive] = useState(false);

  const [isSponsorOpen, setIsSponsorOpen] = useState(autoInitSponsor);
  const [isAlreadySponsored, setIsAlreadySponsored] = useState(false);
  const [sponsorshipType, setSponsorshipType] = useState("Primary Sponsor");

  if (isSponsorPane === false) {
    return null;
  }

  const checkIfArchived = () => {
    if (currentDate?.object?.date > arena?.endDate) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfSponsored = () => {
    const arenaId = arena?.id;
    const keysData = Object.keys(arenaSponsors);

    if (arenaId && keysData.includes(arenaId)) {
      const venueSponsor = arenaSponsors[arenaId];
      for (let i = 0; i < venueSponsor?.length; i++) {
        if (
          venueSponsor[i]?.sponsorId === user.id &&
          venueSponsor[i]?.payment?.status === "succeeded"
        ) {
          setFlag(true);
          return true;
        } else {
          setFlag(false);
        }
      }
      if (flag) {
        return true;
      } else {
        return false;
      }

      //////  old code start

      // if (venueSponsor && venueSponsor[0]?.payment?.status === "succeeded") {
      //   return true;
      // } else {
      //   return false;
      // }

      //////  old code end
    } else {
      return false;
    }
  };

  const openSponsorshipForm = async (event) => {
    const type = event?.target?.value;
    if (type == "secondary") {
      setSponsorshipType("Preferred Sponsor");
    } else {
      setSponsorshipType("Primary Sponsor");
    }

    const archived = checkIfArchived();
    if (archived) {
      setIsAlreadyArchive(true);
    } else {
      const isOpen = !isSponsorOpen;
      setIsArchivedOpen(isOpen);
    }

    const isSponsored = checkIfSponsored();
    if (isSponsored) {
      setIsAlreadySponsored(true);
    } else {
      const isOpen = !isSponsorOpen;
      setIsSponsorOpen(isOpen);
    }
  };

  const setAdditionalArchive = (status) => {
    setIsAlreadyArchive(false);
    if (status) {
      // if true that means user to want to sponsor to archived venue
      setIsArchivedOpen(status);
    } else {
      setIsArchivedOpen(status);
    }
  };

  const setAdditionalSponsorship = (status) => {
    setIsAlreadySponsored(false);
    if (status) {
      // if true that means user to want to sponsor again
      setIsSponsorOpen(status);
    } else {
      setIsSponsorOpen(status);
    }
  };

  return (
    <>
      {!showHideSponsor ? (
        <div
          className="arena-sponsors"
          style={{ justifyContent: "flex-start", alignItems: "center" }}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <label style={{ fontSize: "18px", marginRight: "10px" }}>
                    Primary sponsor
                  </label>
                </td>
                <td>
                  <label style={{ fontSize: "18px", marginRight: "10px" }}>
                    ${arena.primarySponsorPrice}
                  </label>
                </td>
                <td>
                  <button
                    style={{ padding: "0 10px", width: "auto", margin: "5px" }}
                    className="form-button post-media greenlight-btn"
                    type="button"
                    value="primary"
                    onClick={(e) => openSponsorshipForm(e)}
                  >
                    Pay now
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <label style={{ fontSize: "18px", marginRight: "10px" }}>
                    Preferred sponsor
                  </label>
                </td>
                <td>
                  <label style={{ fontSize: "18px", marginRight: "10px" }}>
                    ${arena.secondarySponsorPrice}
                  </label>
                </td>
                <td>
                  <button
                    style={{ padding: "0 10px", width: "auto", margin: "5px" }}
                    className="form-button post-media greenlight-btn"
                    type="button"
                    value="secondary"
                    onClick={(e) => openSponsorshipForm(e)}
                  >
                    Pay now
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          {isAlreadySponsored && !isAlreadyArchive && (
            <ConfirmSponsorship
              isInitOpen={isAlreadySponsored}
              sponsorModal={(status) => {
                setAdditionalSponsorship(status);
                setAdditionalArchive(status);
              }}
            />
          )}
          {isAlreadyArchive && (
            <ConfirmPayment
              isInitOpen={isAlreadyArchive}
              sponsorModal={(status) => {
                setAdditionalArchive(status);
                setAdditionalSponsorship(status);
              }}
            />
          )}

          {isSponsorOpen && isArchivedOpen && (
            <SponsorModal
              arena={arena}
              user={user}
              type={sponsorshipType}
              isInitOpen={isArchivedOpen}
              message="Sponsor"
              title="Open It"
              toggleModal={(status) => {
                setIsArchivedOpen(status);
                console.log(status, " status");
              }}
              arenaSponsors={arenaSponsors}
              fetchArenaSponsors={fetchArenaSponsors}
              fetchSponsorships={fetchSponsorships}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

ArenaSponsor.propTypes = {
  arena: PropTypes.object.isRequired,
  isSponsorPane: PropTypes.bool.isRequired,
};

export default ArenaSponsor;
