import * as types from './actionTypes';
import * as sponsorApi from '../api/sponsorApi';
import { fetchArenasMedia } from './mediaActions';
import { toggleLoadingIndicator, toggleMessageModal } from './commonActions';
import * as constants from '../constants';

export function fetchSponsorshipsFailure(message) {
  return {
    type: types.FETCH_SPONSORSHIPS_FAILURE,
    message,
  };
}

export function fetchArenaSponsorsFailure(message){
  return {
    type: types.FETCH_ARENA_SPONSORS_FAILURE,
    message,
  };
}

export function fetchSponsorshipsSuccess(sponsorships) {
  return {
    type: types.FETCH_SPONSORSHIPS_SUCCESS,
    sponsorships,
  };
}

export function fetchArenaSponsorsSuccess(arenaId,sponsors) {
  return {
    type: types.FETCH_ARENA_SPONSORS_SUCCESS,
    arenaId,
    sponsors
  };
}


export function fetchSponsorships(userId) {
  //alert(userId)
  return (dispatch) => {
    return sponsorApi.fetchSponsorships({"sponsorId":userId})
      .then((response) => {
        console.log('sponsorship success ', response)
        dispatch(fetchSponsorshipsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchSponsorshipsFailure(error));
        console.error(error);
      });
  };
}



export function fetchArenaSponsors(arenaId) {
  return (dispatch) => {
    return sponsorApi.fetchSponsorships({"arenaId":arenaId})
      .then((response) => {
        dispatch(fetchArenaSponsorsSuccess(arenaId, response));
      })
      .catch((error) => {
        dispatch(fetchArenaSponsorsFailure(error));
        console.error(error);
      });
  };
}
