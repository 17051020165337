import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import BlastsTm from "./BlastsTm";

const BlastsTab = (props) => {

  useEffect(() => {
    setRevBlastsTmData(props.blastsTmData);
  },[revBlastsTmData]);
  const [revBlastsTmData, setRevBlastsTmData] = useState();

  return (
    <div className="tab">
      {revBlastsTmData &&
        revBlastsTmData?.map((blasts, index) =>
          blasts.userId == props.user.id ? (
            <BlastsTm key={index} blasts={blasts} />
          ) :null
        )}
    </div>
  );
};

BlastsTab.propTypes = {
  blastsTmData: PropTypes.array,
};

export default BlastsTab;
