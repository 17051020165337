import React, { useEffect, useState } from "react";
import FormTags from "./FormTags";



function UserInput(props) {
  return (
    <>
      <FormTags
        isMulti={true}
        name={props.name}
        defaultValue={props.defaultValue}
        control={props.control}
        onChange={props.onChange}
        loadOptions={props.loadOptions}
        onMenuOpen={props.onMenuOpen}
        options={props.options}
        instanceId={`tagInput-${props.name}`}
        rules={props.validation}
      />
      {props?.errors && (
        <div className="text-danger">{props?.errors?.message}</div>
      )}
    </>
  );
}

export default UserInput;
