import React from 'react'; import PropTypes from 'prop-types';

import ArenaCounter from '../Home/ArenaHub/ArenaCounter';

const ArenaStats = ({ arena }) => (
  <div className="arena-stats-container">
    {
      arena.endDate &&
      <ArenaCounter
        endDate={arena.endDate}
        arenaId={arena.id}
        version="arena-page"
      />
    }
    <div className="arena-stats">
      <div className="arena-stat">
        <label className="stat-title">TOTAL POSTED</label>
        <label className="stat">{arena.mediaContentsCount}</label>
      </div>
      <div className="arena-stat">
        <label className="stat-title">TOTAL GREENLIGHTS®</label>
        <label className="stat">{arena.greenlightsCount}</label>
      </div>
      <div className="arena-stat">
        <label className="stat-title">TOTAL NOTES™</label>
        <label className="stat">{arena?.notesCount ? arena.notesCount: 0}</label>
      </div>
    </div>
  </div>
);

ArenaStats.propTypes = {
  arena: PropTypes.object.isRequired,
};

export default ArenaStats;
