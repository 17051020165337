import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import NotificationsModal from "../Notifications/NotificationsModal";
import notificationIcon from "./img/notifications.png";
import onClickOutside from "react-onclickoutside";
import questionMark from "./img/icons8-help-50.png";
import NotificationBadge from "../Notifications/NotificationBadge";

const NotificationsButton = ({
  toggleNotificationModal,
  hasNewNotification,
  forMobile = false,
}) => {
  const [visible, setVisible] = useState(true);

  NotificationsButton.handleClickOutside = async () => {
    changeState();
    // console.log("outside click ", visible);
  };

  const changeState = async () => {
    await setVisible(true);
  };

  return (
    <div style={{display:'inline-flex'}}>

    <Link to="about#sponsorship"
      className={
        `notification-help ${forMobile ? "notification-help-display": '' }`}>
      <img src={questionMark} style={{height:'25px',width:'25px'}}/>
    </Link>
    <button
      className={`notification-icon
      ${hasNewNotification ? "has-new" : ""}
      ${forMobile ? "notification-burger" : ""}`}
      onClick={toggleNotificationModal}
    > 
    <NotificationBadge/>
      <img src={notificationIcon} />

      {visible ? (
        <div>
          <NotificationsModal />
        </div>
      ) : null}
    </button>
    </div>
  );
};
NotificationsButton.propTypes = {
  toggleNotificationModal: PropTypes.func.isRequired,
  hasNewNotification: PropTypes.bool,
  forMobile: PropTypes.bool,
};

const clickOutsideConfig = {
  handleClickOutside: () => NotificationsButton.handleClickOutside,
};

export default onClickOutside(NotificationsButton, clickOutsideConfig);
// export default NotificationsButton;
