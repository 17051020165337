import api from './apiService.js';
import { getStore } from '../index';

export const greenlightArena = (arenaId) => {
  let user = getStore().getState().loginReducer.user;
  let data = {
    userId: user.id,
    greenlighteableId: arenaId,
    greenlighteableType: "Arena",
  }
  return api.post(`greenlights/greenlit/`, data);
};

export const greenlightVenueRequest = (arenaId) => {
  let user = getStore().getState().loginReducer.user;
  let data = {
    userId: user.id,
    greenlighteableId: arenaId,
    greenlighteableType: "VenueRequest",
  }
  return api.post(`greenlights/greenlit/`, data);
};

export const greenlightMedia = (mediaId, userId, arenaId) => {
  let payload  = {
    "userId": userId,
    "greenlighteableId": mediaId,
    "greenlighteableType": "MediaContent",
    "arenaId": arenaId
  }
  return api.post(`greenlights/greenlit/`, payload);
};

export const greenlightUser = (userId, currentUserId) => {
  console.log('api userId, currentUserId', userId, currentUserId)
  let payload  = {
    "userId": currentUserId,
    "greenlighteableId": userId,
    "greenlighteableType": "User",
  }
  return api.post(`greenlights/greenlit/`, payload);
};

export const greenlightNote = (noteId) => {
  return api.post(`notes/${noteId}/greenlight`);
};
