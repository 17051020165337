import * as types from "./actionTypes";
import * as userApi from "../api/userApi";
import { SubmissionError } from "redux-form";
import { toggleLoadingIndicator } from "./commonActions";

export function switchProfileTab(id) {
  return {
    type: types.SWITCH_PROFILE_TAB,
    id,
  };
}

export function updateUserSuccess(userData) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    userData,
  };
}

export function updateUserFailure(message) {
  return {
    type: types.UPDATE_USER_FAILURE,
    message,
  };
}

export function getProfileSuccess(profileData) {
  return {
    type: types.GET_PROFILE_SUCCESS,
    profileData,
  };
}

export function getProfileFailure(message) {
  return {
    type: types.GET_PROFILE_FAILURE,
    message,
  };
}

export function getMyProfileSuccess(userData) {
  return {
    type: types.GET_MY_PROFILE_SUCCESS,
    userData,
  };
}

export function getMyProfileFailure(message) {
  return {
    type: types.GET_MY_PROFILE_FAILURE,
    message,
  };
}

export function getMyNotesSuccess(myNotesData) {
  console.log(myNotesData, " notes action");
  return {
    type: types.GET_MY_NOTES,
    myNotesData,
  };
}

export function getMyNotesFailure(message) {
  return {
    type: types.GET_MY_NOTES_FAILURE,
    message,
  };
}

export function getMyBlastsSuccess(blastsTmData) {
  return {
    type: types.GET_MY_BLAST_SUCCESS,
    blastsTmData,
  };
}

export function getMyBlastsFailure(message) {
  return {
    type: types.GET_MY_BLAST_FAILURE,
    message,
  };
}

export function getGreenlitPeopleSuccess(greenlitPeople) {
  return {
    type: types.GET_GREENLIT_PEOPLE_SUCCESS,
    greenlitPeople,
  };
}

export function getGreenlitPeopleFailure(message) {
  return {
    type: types.GET_GREENLIT_PEOPLE_FAILURE,
    message,
  };
}

export function getGreenlightPeopleSuccess(greenlightPeople) {
  return {
    type: types.GET_GREENLIGHT_PEOPLE_SUCCESS,
    greenlightPeople,
  };
}

export function getGreenlightPeopleFailure(message) {
  return {
    type: types.GET_GREENLIGHT_PEOPLE_FAILURE,
    message,
  };
}

export function unmountProfile() {
  return {
    type: types.UNMOUNT_PROFILE,
  };
}

export function fetchProfileMediaSuccess(
  profileMedia,
  filtered,
  specificMedia
) {
  return {
    type: types.FETCH_PROFILE_MEDIA_SUCCESS,
    profileMedia,
    filtered,
    specificMedia,
  };
}

export function fetchProfileMediaFailure(message) {
  return {
    type: types.FETCH_PROFILE_MEDIA_FAILURE,
    message,
  };
}

export function updateUser(userData) {
  return (dispatch) => {
    dispatch(toggleLoadingIndicator());
    return userApi
      .updateUser(userData)
      .then((user) => dispatch(updateUserSuccess(user)))
      .catch((error) => {
        dispatch(updateUserFailure());
        error._error = `Username ${error.username}`;
        return Promise.reject(new SubmissionError(error));
      });
  };
}

export function updateUserPicture(userData, id) {
  return (dispatch) => {
    dispatch(toggleLoadingIndicator());
    return userApi
      .updateUserPicture(userData, id)
      .then((user) => {
        console.log("userDatauserDatauserDatauserData", user);

        dispatch(updateUserSuccess(user));
      })
      .catch((error) => {
        dispatch(
          updateUserFailure({
            title: "Oops",
            text: "There was an issue updating your profile picture, please try again",
          })
        );
        console.error(error);
      });
  };
}

export function getProfile(userId) {
  return (dispatch) => {
    return userApi
      .getProfile(userId)
      .then((response) => {
        dispatch(getProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(getProfileFailure(error));
        //console.error(error);
      });
  };
}



export function getMyNotes(userId) {
  return (dispatch) => {
    return userApi
      .getMyNotes(userId)
      .then((response) => {
        console.log(response," notes response");
        dispatch(getMyNotesSuccess(response));
      })
      .catch((error) => {
        dispatch(getMyNotesFailure(error));
        console.error(error);
      });
  };
}



export function getMyProfile(userId) {
  return (dispatch) => {
    return userApi
      .getMyProfile(userId)
      .then((response) => {
        dispatch(getMyProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(getMyProfileFailure(error));
        console.error(error);
      });
  };
}

export function getMyBlast(userId) {
  return (dispatch) => {
    return userApi
      .getMyBlast(userId)
      .then((response) => {
        dispatch(getMyBlastsSuccess(response));
      })
      .catch((error) => {
        dispatch(getMyBlastsFailure(error));
        console.error(error);
      });
  };
}

export function getGreenlitPeople(userId) {
  return (dispatch) => {
    return userApi
      .getGreenlitPeople(userId)
      .then((response) => {
        console.log("getGreenlitPeople", response);
        dispatch(getGreenlitPeopleSuccess(response));
      })
      .catch((error) => {
        dispatch(getGreenlitPeopleFailure(error));
        console.error(error);
      });
  };
}

export function getGreenlightPeople(userId) {
  console.log("getGreenlightPeople userId  , ", userId);
  return (dispatch) => {
    return userApi
      .getGreenlightPeople(userId)
      .then((response) => {
        console.log("getGreenlightPeople   , ", response);
        dispatch(getGreenlightPeopleSuccess(response));
      })
      .catch((error) => {
        dispatch(getGreenlightPeopleFailure(error));
        console.error(error);
      });
  };
}

export function getMyGreenlitPeople(userId) {
  return (dispatch) => {
    return userApi
      .getMyGreenlitPeople(userId)
      .then((response) => {
        console.log("getMyGreenlitPeople", response);
        dispatch(getGreenlitPeopleSuccess(response));
      })
      .catch((error) => {
        dispatch(getGreenlitPeopleFailure(error));
        console.error(error);
      });
  };
}

export function fetchProfileMedia(
  userId,
  page,
  filters,
  sortType,
  sortOrder,
  specificMediaId,
  filtered
) {
  return (dispatch) => {
    return userApi
      .fetchProfileMedia(
        userId,
        page,
        filters,
        sortType,
        sortOrder,
        specificMediaId
      )
      .then((response) => {
        console.log("fetchProfileMedia  ", response);
        dispatch(
          fetchProfileMediaSuccess(response, filtered, response.media_requested)
        );
      })
      .catch((error) => {
        dispatch(fetchProfileMediaFailure(error));
        console.error(error);
      });
  };
}
