import * as types from '../actions/actionTypes';

const initialState = {
  venueRequest: [],
  venueRequestData: [], 
};

export default function venueRequestReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.VENUE_REQUEST_SUCCESS: {
      const venueRequest = action.venueRequest;
      console.log(venueRequest, 'venueRequest1234');

      return {
        ...state,
        venueRequest: venueRequest,
      };
    }
    case types.VENUE_REQUEST_DATA_SUCCESS: {
      const venueRequestData = action.venueRequestData;
      console.log(venueRequestData, 'venueRequestData1234');

      return {
        ...state,
        venueRequestData: venueRequestData,
      };
    }
    default:
      return state;
  }
}
