import React, { useState, useEffect} from 'react';

const LegalTab = ({legal, fetchContent}) => {
  const [state, setState] = useState([])

  useEffect(() => fetchContent('Legal'),[]);
  return (
    <div className="tab-container" dangerouslySetInnerHTML={{__html: legal}}>
      {/* <p>
        Coming soon...
      </p> */}
    </div>
  );
}
export default LegalTab;
