import React from 'react'; import PropTypes from 'prop-types';

import PaymentDone from './PaymentDone'

const PaymentDoneTab = ({ user }) => {
  return (
    <div className="tab">
     <PaymentDone user={user}/>
    </div>
  );
};

PaymentDoneTab.propTypes = {
  user: PropTypes.object.isRequired,  
};

export default PaymentDoneTab;
