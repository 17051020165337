import React from 'react';
import PropTypes from 'prop-types';
import speakerIcon from '../../Blast/img/green-speaker.png';
var moment = require('moment');

const BlastsTm = ({ blasts }) => (
    <div className="greenlit-user-container">
      <div className="greenlit-user">
        <div className="profile-data">
          <div className="label-common-text last-blast">
            <img src={speakerIcon}/>
            <label>{blasts.text}</label></div>
        </div>
        <div className="label-common-text">
          { moment(blasts.createdAt).format("DD-MMM-YYYY") } <br />
          { moment(blasts.createdAt).format("h:mm:ss a") }
        </div>
        </div>
      <div className="divider-line soft"/>
    </div>
  )
BlastsTm.propTypes = {
  blasts: PropTypes.object.isRequired,
};
export default BlastsTm;
