import React from 'react'; import PropTypes from 'prop-types';

import NotificationMessage from './NotificationMessage';
import moment from 'moment';
import profilePlaceholder from '../App/img/profile-ph.png';
import mediaPlaceholder from 'components/Arena/img/media-ph.svg';
import { notificationLink } from 'utils/helpers';
import { connect, useDispatch } from 'react-redux';
import { markNotificationAsReadAndUpdate } from '../../actions/notificationsActions';

const Notification = ({ notification }) => {
  let agoString = moment(notification.createdAt).fromNow();
  const url = notificationLink(notification);
  const dispatch = useDispatch();
  const handleNoticationClick = () => {
   
   dispatch(markNotificationAsReadAndUpdate(notification.id, { read: true }))
   .then(() => {
     //dispatch(markNotificationAsRead(notification.id));
   })
   .catch(error => {
     console.error('Error updating notification status:', error);
   });
  }


  
  return (
    <a className="notification-container" href={url} onClick={handleNoticationClick}>
      <div className="notification" >
        <img
          src={notification.sender_thumbnail || profilePlaceholder}
          className="user-picture"
        />
        <div className="noti-info">
          <NotificationMessage notification={notification} />
          <i className="time-ago">{agoString}</i>
        </div>
        <img
          className="object-picture"
          src={notification.itemLink || mediaPlaceholder}
        />
      </div>
      <div className="divider-line" />
    </a>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  markNotificationAsReadAndUpdate: PropTypes.func.isRequired,
};
export default connect(null, { markNotificationAsReadAndUpdate })(Notification);
