import React from 'react'; import PropTypes from 'prop-types';

import GreenlightUser from './GreenlightUser';

const GreenlitePeopleTab = ({ greenlightUser, greenlightPeople, user }) => {
  console.log('GreenlitePeopleTab people ', greenlightPeople)
  return (
    <div className="tab">
      {
        greenlightPeople && greenlightPeople.length > 0 ? 
        greenlightPeople.map((data, index) =>
          <GreenlightUser
            key={index}
            user={data.user}
            greenlightUser={greenlightUser}
            currentUser={user}
          />
        )
        : 
        <label className="coming-soon">You don't have any GREENLIGHT® PEOPLE.</label>
      }
    </div>
  );
};

GreenlitePeopleTab.propTypes = {
  greenlightUser: PropTypes.func.isRequired,
  greenlightPeople: PropTypes.array,
  user: PropTypes.object.isRequired,
};

export default GreenlitePeopleTab;
