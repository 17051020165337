import React, { useState, useEffect} from 'react';

const SponsorshipTab = ({sponsership, fetchContent}) => {
  const [state, setState] = useState([])

  useEffect(() => fetchContent('Sponsership'),[]);


  return (
    <div className="tab-container" dangerouslySetInnerHTML={{__html: sponsership}}>
      {/* <p>
        7G Venue™ sponsorship opportunities are only provided to businesses who
        are RELEVANT TO — and RESPECTED BY — 7G artists and fans.
      </p>
      <p>
        We simply do not need to violate our users' privacy rights to sustain our
        business.
      </p> */}
    </div>
  );
}
export default SponsorshipTab;
