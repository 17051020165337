import React, { useState, useEffect} from 'react';

const TabDataContainer = ({name,tabsData, fetchContent }) => {

  const [state, setState] = useState([])

  useEffect(() => fetchContent(name));
  return (
    <div className="tab-container" dangerouslySetInnerHTML={{__html: tabsData[name.toLowerCase()]}} ></div>
  )
};

export default TabDataContainer;
