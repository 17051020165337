import { connect } from 'react-redux';
import About from '../components/About/about';
import { switchTab } from '../actions/aboutActions';

import { fetchContent, fetchContentTabs} from '../actions/helpActions';
const mapStateToProps = (state) => {
  return {
    contentTabs: state.helpReducer.tabs,
    tabsData: state.helpReducer.tabsData,
    help: state.helpReducer.help,
    mission: state.helpReducer.mission,
    faq: state.helpReducer.faq,
    about: state.helpReducer.about,
    sponsership: state.helpReducer.sponsership,
    legal: state.helpReducer.legal,
    selectedTab: state.aboutReducer.selectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchTab: (newTab) => dispatch(switchTab(newTab)),
    fetchContent: (name) => {
      dispatch(fetchContent(name));
    },
    fetchContentTabs: () => {
      dispatch(fetchContentTabs());
    },
  };
};

const AboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(About);

export default AboutContainer;
