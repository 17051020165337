import api from './apiService.js';
import * as constants from '../constants.js';
import { applyFilters, applySorting, paginate,
  applyArrayFilters } from './apiHelpers';

export const fetchStream = (userId, page, filters, sortType, sortOrder,
  arenaIds = [], typeIds = [], perPage = constants.ARENA_MEDIA_SIZE) => {
  let url = `greenlights/my-stream/${userId}`;
  let filter = {
    offset: page,
    perPage: perPage,
    arenaIds: arenaIds,
    contentType: typeIds,
    sort: sortType
  };
  url = paginate(url, page, perPage, filter);
  // url = paginate(url, page, perPage);
  // url = applySorting(url, sortType, sortOrder);
  // url = applyFilters(url, filters);
  // url = applyArrayFilters(url, constants.mediaFilters.arenas, arenaIds);
  // url = applyArrayFilters(url, constants.mediaFilters.types, typeIds);

  return api.get(url);
};
