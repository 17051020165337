import * as types from '../actions/actionTypes';
import * as helpers from './helpers';

const initialState = {
    tabs:[],
    tabsData: {}
};

export default function helpReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.FETCH_CONTENT_SUCCESS: {

            let content = action.content;
            console.log('action in help reducers ', content)
            const contentName = content.name.toLowerCase()
            let tabsData = state.tabsData
            tabsData[contentName] = content.content.description
            return {
                ...state,
                tabsData: tabsData,
                [contentName]: content.content.description,
            };
        }
        case types.FETCH_CONTENT_TABS_SUCCESS: {
            let tabLists = action;
            const tabs = tabLists.tabs.content
            return {
                ...state,
                tabs: tabs,
            };
        }
        case types.STOP_MORE_ARENA: {
            /* return {
              ...state,
              loadMoreArenas: false
            }; */
        }
        default:{
            return state;
        }

    }
}