import * as types from './actionTypes';
import { fetchVenueRequest, fetchVenueRequestData } from '../api/venueRequestApi';
import { fetchArenasMedia } from './mediaActions';
import { toggleLoadingIndicator, toggleMessageModal } from './commonActions';
import * as constants from '../constants';



export function fetchVenueRequestSuccess(venueRequest) {

  return {
    type: types.VENUE_REQUEST_SUCCESS,
    venueRequest
  }
}

export function fetchVenueRequestDataSuccess(venueRequestData) {

  return {
    type: types.VENUE_REQUEST_DATA_SUCCESS,
    venueRequestData
  }
}

export function fetchVenueRequestDataAction() {
  return async (dispatch) => {
    try {
      const response = await fetchVenueRequestData();
      console.log(response, "ResponseData1234")
       dispatch(fetchVenueRequestDataSuccess(response));
    } catch (error) {
      console.error(error);
    }
  };
}


export function fetchVenuesRequests(userId) {
  
  return async (dispatch) => {
    try {
          const response = await fetchVenueRequest(userId);
          console.log('venue_request_success', response);
          dispatch(fetchVenueRequestSuccess(response));
      } catch (error) {
          console.error(error);
      }
  };
}



