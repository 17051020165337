import * as types from './actionTypes';
import { getMyNotifications, updateNotificationStatus } from '../api/userApi';

export function toggleNotificationModal() {
  return {
    type: types.TOGGLE_NOTIFICATIONS_MODAL,
  };
}

export function closeNotificationModel(){
  return{
    type:types.CLOSE_NOTIFICATIONS_MODAL,
  }
}

export function getNotificationsSuccess(notifications) {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    notifications,
  };
}

export const markNotificationAsRead = (notificationId) => {
  return {
    type: types.MARK_NOTIFICATION_AS_READ,
    notificationId
  };
};



export function getNotifications(page, user) {
  return (dispatch) => {
    return getMyNotifications(page, user)
      .then((response) => {
        dispatch(getNotificationsSuccess(response));
      })
      .catch(console.error);
  };
}
export const markNotificationAsReadAndUpdate = (id, data) => {
  return dispatch => {
    return updateNotificationStatus(id, data)
      .then(() => {
        dispatch(markNotificationAsRead(id));
        dispatch(getNotifications(1)); 
      })
      .catch(error => {
        console.error('Error updating notification status:', error);
      });
  };
};

