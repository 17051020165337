import api from './apiService.js';
import { paginate, applySorting, applyFilters,
  applySpecificMedia } from './apiHelpers';

const parseSignupData = (signupData) => {
  let newSignupData = { ...signupData };
  newSignupData.name = newSignupData.username;
  // delete newSignupData.username;
  if(newSignupData.dob_day && newSignupData.dob_month && newSignupData.dob_year){
    newSignupData.birthday =
      `${newSignupData.dob_month}/${newSignupData.dob_day}/${newSignupData.dob_year}`;
  }
  delete newSignupData.dob_year;
  delete newSignupData.dob_month;
  delete newSignupData.dob_day;
  delete newSignupData.password_confirmation;
  return newSignupData;
};

export const signup = (signupData) => {

  return api.post('users', parseSignupData(signupData));
};

export const updateUser = (userData) => {
  return api.put(`users/me/${userData.id}`, userData);
};

export const updateUserPicture = (data) => {
  return api.put('users/me/62dd19f9db39c00fae1450a5', Object.fromEntries(data));
};

export const getMyProfile = (userId) => {
  return api.get(`users/${userId}`)
};

export const getMyNotes = (userId)=>{
  return api.get(`/notes/me/${userId}`)
};


export const getMyBlast = (userId) => {
  return api.get(`blasts`)///${userId}
};

export const getProfile = (userId) => {
  return api.get(`users/${userId}`);
};

export const getGreenlitPeople = (userId) => {
  return api.get(`greenlights`);///${userId}/greenlights/users
};

export const getMyGreenlitPeople = (userId) => {
  let filter = JSON.stringify({ "include": [{"relation": "greenlitUser", "scope": {"include": [{"relation": "greenlights", "scope": {"where": {"userId": `${userId}`}}}]}}], "where": {"userId": `${userId}`, "greenlighteableType": "User"} })
  return api.get(`greenlights?filter=${filter}`);
};

export const getGreenlightPeople = (userId) => {
  let filter = JSON.stringify({ "include": [{"relation": "user", "scope": {"include": [{"relation": "greenlights", "scope": {"where": {"userId": `${userId}`}}}]}}], "where": {"greenlighteableId": `${userId}`, "greenlighteableType": "User"} })
  return api.get(`greenlights?filter=${filter}`);
};

export const getMyNotifications = (page, user, perPage = 20) => {
  let url = 'notifications';
  let filter = {
    "include": ["sender", "user", "note", "mediaContent"],
    "where": {"receiverId": user.id},
    'order': "createdAt DESC"
  }
  url = paginate(url, page, perPage, filter);
  return api.get(url);
};


export const fetchProfileMedia = (userId, page, filters, sortType, sortOrder,
  specificMedia = null, perPage = 20) => {
  let url = `MediaContents/me/${userId}`;
  let filter= []
  url = paginate(url, page, perPage, filter);
  url = applySorting(url, sortType, sortOrder);
  url = applyFilters(url, filters);
  url = applySpecificMedia(url, specificMedia);
  return api.get(url);
};

export const fetchAllUsers = () => {
  let filter = JSON.stringify({fields:["id", "name", "email", "profilePicture"], where:{"id":{"nin":[]}}})
  return api.get(`users?filter=${filter}`);
};


export const updateNotificationStatus = (notificationId, updatedNotification) => {
  // alert(JSON.stringify(updatedNotification))
  return api.put(`Notifications/updateNotification/${notificationId}`, updatedNotification);
};
