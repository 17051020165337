import * as React from "react";

export const TabSelector = ({
  isActive,
  children,
  onClick,
}) => (
  <button
    className={`tab-title ${
      isActive
        ? "active-tab"
        : ""
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);