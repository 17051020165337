import React, { Component } from 'react';
import PropTypes from 'prop-types';
import speakerIcon from '../Blast/img/green-speaker.png';
import UsernameForm from './UsernameForm';
import pencilIcon from './img/edit-pencil.png';
import Greenlight from '../Greenlight/Greenlight';
import profilePlaceholder from '../App/img/profile-ph.png';
import Api from '../../api/apiService';

class ProfileBasics extends Component {
  constructor () {
    super ();
    this.state = {
      editMode: false,
      showPictureHover: false,
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {user} = this.props;
    const {editMode} = this.state;

    if (nextProps.user !== user && editMode) {
      this.toggleEditMode (editMode);
    }
  }

  toggleEditMode (editMode) {
    this.setState ({
      editMode: !editMode,
    });
  }

  togglePictureHover (showPictureHover) {
    this.setState ({
      showPictureHover: !showPictureHover,
    });
  }

  async handlePicture (id) {
    let picFile = await document.getElementById ('pic-input').files[0];
    let formData = new FormData ();
    formData.append ('image', picFile);
    // updateUserPicture(formData, id) old code
    await Api.put (`users/updateProfilePicture/${id}`, formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then (result => {
        //console.log ('result is', result);
        window.location.reload (false);
      })
      .catch (error => {
        //console.log ('ERROR is ', error);
      });
  }

  render () {
    const {
      user,
      isMine,
      updateUser,
      greenlightUser,
      // updateUserPicture,
    } = this.props;
    const {editMode, showPictureHover} = this.state;

    return (
      <div className="profile-basics">
        <input
          accept="image/gif, image/jpeg, image/png"
          hidden
          id="pic-input"
          type="file"
          name="picture"
          onChange={() => this.handlePicture (user.id)}
        />
        <img
          id="picture"
          src={user.profilePicture ? user.profilePicture : profilePlaceholder}
          className="profile-picture"
          onClick={() => {
            isMine && document.getElementById ('pic-input').click ();
          }}
          onMouseEnter={() =>
            isMine && this.togglePictureHover (showPictureHover)}
          onMouseLeave={() =>
            isMine && this.togglePictureHover (showPictureHover)}
        />
        {showPictureHover && <div className="picture-hover" />}
        <div className="basic-info">
          <div className="username-form">
            {editMode
              ? <UsernameForm
                  user={user}
                  cancelEdit={() => this.toggleEditMode (editMode)}
                  updateUser={user => {
                    updateUser ({id: user.id, name: user.name});
                  }}
                />
              : <h1>{user.name}</h1>}
            {isMine &&
              !editMode &&
              <button
                type="button"
                onClick={() => this.toggleEditMode (editMode)}
              >
                <img className="pencil-icon" src={pencilIcon} />
              </button>}
            {
              <Greenlight
                greenlit={user.greenlightsCount > 0 ? true : false}
                counter={user.greenlightsCount}
                greenlightAction={() => greenlightUser (user.id)}
                noPadding={true}
              />}
          </div>
          {user.lastBlast &&
            <div className="last-blast">
              <img src={speakerIcon} />
              <label>{`"${user.lastBlast}"`}</label>
            </div>}
          <div className="link-list" />
        </div>
      </div>
    );
  }
}

ProfileBasics.propTypes = {
  user: PropTypes.object.isRequired,
  isMine: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  greenlightUser: PropTypes.func.isRequired,
  updateUserPicture: PropTypes.func.isRequired,
};

export default ProfileBasics;
