import React from 'react'; import PropTypes from 'prop-types';


const PaymentReceived = ({ user }) => ( 
    <table  borderless>
        <thead>
          <tr>
            <td className="coming-soon">Date</td>
            <td className="coming-soon">Transaction ID</td>
            <td className="coming-soon">Venue</td>
            <td className="coming-soon">Amount</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-label">1/28/19</td>
            <td className="table-label">asa7829e28</td>
            <td className="table-label">Venue name</td>
            <td className="table-label">$20</td>
          </tr>
          <tr>
            <td className="table-label">1/28/19</td>
            <td className="table-label">sfv1e321r3</td>
            <td className="table-label">Venue name</td>
            <td className="table-label"> $500</td>
          </tr>
          <tr>
            <td className="table-label">1/28/19</td>
            <td className="table-label">vdv132255e</td>
            <td className="table-label">Venue name</td>
            <td className="table-label">$100</td>
          </tr>
          <tr>
            <td className="table-label">1/28/19</td>
            <td className="table-label">fvds245345</td>
            <td className="table-label">Venue name</td>
            <td className="table-label">$50</td>
          </tr>
        </tbody>
      </table>
  
);

PaymentReceived.propTypes = {
  user: PropTypes.object.isRequired,  
};

export default PaymentReceived
