export function makeUniqueByValue(data) {
    const mapAr = new Map();
    return data.filter(item => {
        if (!mapAr.has(item.value)) {
            mapAr.set(item.value, true);
            return item;
        }
    });
}

export function makeUniqueById(data) {
    const mapAr = new Map();
    return data.filter(item => {
        if (!mapAr.has(item.id)) {
            mapAr.set(item.id, true);
            return item;
        }
    });
}
