import React from 'react';
import AsyncSelect from 'react-select/async';

const FormTags = ({ control, name, defaultValue, options, loadOptions, rules = {}, isMulti = true, instanceId = 'tags-input', onChange = null, width, ...rest }) => {
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '90%',
            position:"absolute",
            marginBottom:"1rem",
            fontSize:"15px"
        }),
        option: (base) => ({
            ...base,
            textAlign: 'left',
            fontSize:"15px"
        }),
        groupHeading: (base) => ({
            ...base,
            flex: '1 1',
            color: 'black',
            margin: 0,
            fontSize: '15px',
            background: '#e6e6e6',
            padding: '2px 0px 2px 4px',
        }),
    };

    return (
        <AsyncSelect
            isMulti={isMulti}
            name={name}
            control={control}
            cacheOptions
            value={defaultValue}
            defaultOptions={options}
            instanceId={instanceId}
            loadOptions={loadOptions}
            onChange={onChange}
            {...rest}
            styles={customStyles}
        />
    );
}

export default FormTags;
