import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../utils/Modal";
import { Link } from "react-router";
import LoginModal from "./LoginModal";
import UserBox from "./UserBox";
import GLogo from "./img/main-logo.png";
import questionMark from "./img/icons8-help-50.png";
import VenueRequestModalComponent from "./venueRequest";

const TopBar = ({
  inHomepage,
  user,
  logout,
  toggleTopBarModal,
  shouldDisplayTopBarModal,
  authenticated,
  toggleNotificationModal,
  hasNewNotification,
  shouldDisplayVenueMenus,
  toggleVenueMenus,
  closeNotificationModel,

}) => {
const shouldDisplayVenueRequest = user && user.email 

  return (
    <div className="top-bar">
      <div className="left-box">
        {((!inHomepage && !authenticated) || authenticated) && (
          <Link to={"/"}>
            <img src={GLogo} className="main-logo" />
          </Link>
        )}
       {shouldDisplayVenueRequest && (
          <VenueRequestModalComponent userDetails={user} />
        )}

      </div>
      <div className="right-box" style={{ display: 'inline-flex' }}>

        {!authenticated ? (
          <>
            <Link to="about#sponsorship" style={{ marginRight: '18px', verticalAlign: 'middle' }}>
              <img src={questionMark} style={{ height: '25px', width: '25px' }} />

            </Link>

            <button
              onClick={() => toggleTopBarModal()}
              className="standard-label sign-in-btn"
            >
              Sign In
            </button>
          </>
        ) : (
          <UserBox
            logout={logout}
            user={user}
            inHomepage={inHomepage}
            toggleTopBarModal={() => toggleTopBarModal()}
            toggleVenueMenus={toggleVenueMenus}
            shouldBeDisplayed={shouldDisplayTopBarModal}
            closeNotificationModel={closeNotificationModel}
            shouldBeDisplayedVenueMenus={shouldDisplayVenueMenus}
            toggleNotificationModal={toggleNotificationModal}
            hasNewNotification={hasNewNotification}
          />

        )}
        {!authenticated && shouldDisplayTopBarModal && (
          <LoginModal
            isOpen={false}
            onRequestClose={() => {
              toggleTopBarModal();
            }}
            eventTypes="click"
          />
        )}
        
      </div>


    </div>
  );
};

TopBar.propTypes = {
  inHomepage: PropTypes.bool,
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  toggleTopBarModal: PropTypes.func.isRequired,
  toggleVenueMenus: PropTypes.func.isRequired,
  shouldDisplayTopBarModal: PropTypes.bool.isRequired,
  shouldDisplayVenueMenus: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool,
  toggleNotificationModal: PropTypes.func.isRequired,
  hasNewNotification: PropTypes.bool,
};

export default TopBar;
