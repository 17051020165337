import React, { Component } from "react";
import PropTypes from "prop-types";
import ProfileCover from "./ProfileCover";
import ProfileTabs from "./ProfileTabs";
import { profileTabs } from "constants";
import { getParameterByName, mediaLink } from "utils/helpers";
import { browserHistory } from "react-router";
import { getMyNotes } from "../../actions/profileActions";

class Profile extends Component {
  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.fetchNextPage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      params,
      unmountProfile,
      filters,
      sortType,
      sortOrder,
      fetchProfileMedia,
    } = this.props;
    const mediaParam = getParameterByName("media");

    if (nextProps.params.profileId != params.profileId) {
      unmountProfile();
      this.loadData(nextProps);
    } else if (
      filters !== nextProps.filters ||
      sortType !== nextProps.sortType ||
      sortOrder !== nextProps.sortOrder
    ) {
      fetchProfileMedia(
        params.profileId,
        nextProps.currentPage,
        nextProps.filters,
        nextProps.sortType,
        nextProps.sortOrder,
        mediaParam
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.fetchNextPage);
  }

  isMine(id = null) {
    const { currentUser, params } = this.props;
    const profileId = id || params.profileId;
    return currentUser !== null && profileId == currentUser.id;
  }

  loadData(props) {
    const {
      getProfile,
      getMyProfile,
      getMyBlast,
      getMyNotes,
      getGreenlitPeople,
      getMyGreenlitPeople,
      getGreenlightPeople,
      fetchProfileMedia,
      switchTab,
      currentUser,
      fetchSponsorships,
      fetchVenuePostedList,
      fetchVenuesRequests,
    } = this.props;
    const isMine = this.isMine(props.params.profileId);
    if (isMine) {
      const path = mediaLink(props.params.profileId, mediaParam, true);
      const mediaParam = getParameterByName("media");
      getMyProfile(currentUser.id);
      getMyNotes(currentUser.id);
      getMyBlast(currentUser.id);
      getMyGreenlitPeople(currentUser.id);
      getGreenlightPeople(currentUser.id);
      
      fetchSponsorships(currentUser.id);
      fetchSponsorships(currentUser.id);
      fetchVenuePostedList(currentUser.id);
      fetchVenuesRequests(currentUser.id);
      switchTab(profileTabs.bio);
      mediaParam && browserHistory.push(path);
    } else {
      getProfile(props.params.profileId);
      getMyBlast(currentUser.id);
      getMyNotes(currentUser.id);
      getMyGreenlitPeople(props.params.profileId);
      getGreenlightPeople(props.params.profileId);
      getGreenlitPeople(props.params.profileId);
      fetchSponsorships(props.params.profileId);
      fetchVenuesRequests(props.params.profileId)
      fetchVenuePostedList(currentUser.id);
      fetchVenuesRequests(props.params.profileId)
      fetchProfileMedia(
        props.params.profileId,
        props.currentPage,
        props.filters,
        props.sortType,
        props.sortOrder,
        getParameterByName("media")
      );
    }
  }

  fetchNextPage = () => {
    const {
      fetchProfileMedia,
      filters,
      sortType,
      sortOrder,
      params,
      currentPage,
    } = this.props;

    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      fetchProfileMedia(
        params.profileId,
        currentPage,
        filters,
        sortType,
        sortOrder
      );
    }
  };

  render() {
    const {
      currentUser,
      switchTab,
      currentTab,
      updateUser,
      profileData,
      greenlightUser,
      updateUserPicture,
      blastsTmData,
      myNotesData,
      getMyNotes,
      greenlitPeople,
      greenlightPeople,
      profileMedia,
      greenlightMedia,
      sponsorships,
      venuePostedList,
      earnedRevenue,
      venueRequest
    } = this.props;
    let isMine = this.isMine();
    let user = isMine ? currentUser : profileData;

    return (
      <div className="full-page outerspace-bg">
        <ProfileCover
          isMine={isMine}
          user={user}
          updateUser={updateUser}
          updateUserPicture={updateUserPicture}
          greenlightUser={greenlightUser}
          earnedRevenue={earnedRevenue}
        />
        <ProfileTabs
          user={user}
          selectedTab={currentTab}
          switchTab={switchTab}
          isMine={isMine}
          updateUser={updateUser}
          blastsTmData={blastsTmData}
          myNotesData={myNotesData}
          greenlightUser={greenlightUser}
          greenlitPeople={greenlitPeople}
          greenlightPeople={greenlightPeople}
          media={profileMedia}
          greenlightMedia={greenlightMedia}
          sponsorships={sponsorships}
          venuePostedList={venuePostedList}
          venueRequest={venueRequest}
        />
      </div>
    );
  }
}

Profile.propTypes = {
  greenlightUser: PropTypes.func.isRequired,
  blastsTmData: PropTypes.array,
  myNotesData:PropTypes.array,
  getMyBlast: PropTypes.func.isRequired,
  greenlitPeople: PropTypes.array,
  greenlightPeople: PropTypes.array,
  getGreenlitPeople: PropTypes.func.isRequired,
  getMyGreenlitPeople: PropTypes.func.isRequired,
  fetchProfileMedia: PropTypes.func.isRequired,
  unmountProfile: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  profileData: PropTypes.object.isRequired,
  switchTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserPicture: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getMyProfile: PropTypes.func.isRequired,
  greenlightMedia: PropTypes.func.isRequired,
  profileMedia: PropTypes.array,
  params: PropTypes.object,
  filters: PropTypes.array,
  sortOrder: PropTypes.number,
  sortType: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
};

export default Profile;
