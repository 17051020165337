import { connect } from 'react-redux';
import Payment from '../components/Payment/Payment';
import * as profileActions from '../actions/profileActions';
import { greenlightUser, greenlightMedia,  } from '../actions/greenlightActions';

const mapStateToProps = (state) => {
 const enabledMediaList = state.filtersortReducer.sorts['mediaList'].enabled;
 return {
   currentUser: state.loginReducer.user,
   profileData: state.profileReducer.profileData,
   myNotesData:state.profileReducer.myNotesData,
   blastsTmData: state.profileReducer.blastsTmData,
   greenlitPeople: state.profileReducer.greenlitPeople,
   greenlightPeople: state.profileReducer.greenlightPeople,
   currentTab: state.profileReducer.currentTab,
   profileMedia: state.profileReducer.profileMedia,
   filters: state.filtersortReducer.filters['mediaList'].enabled,
   sortType: enabledMediaList,
   sortOrder: state.filtersortReducer.sorts['mediaList'].sortOrder[enabledMediaList],
   currentPage: state.profileReducer.currentProfileMediaPage,
 };
};

const mapDispatchToProps = dispatch => {
 return {
   switchTab: id => dispatch(profileActions.switchProfileTab(id)),
   updateUser: userData => dispatch(profileActions.updateUser(userData)),
   updateUserPicture: userData =>
     dispatch(profileActions.updateUserPicture(userData)),
   getProfile: userId => dispatch(profileActions.getProfile(userId)),
   getMyProfile: userId => dispatch(profileActions.getMyProfile(userId)),
   getMyBlast: userId => dispatch(profileActions.getMyBlast(userId)),
   getMyNotes: (userId) => dispatch(profileActions.getMyNotes(userId)),
   greenlightUser: (userId, currentUserId) => dispatch(greenlightUser(userId, currentUserId)),
   getGreenlitPeople: userId =>
     dispatch(profileActions.getGreenlitPeople(userId)),
   getGreenlightPeople: userId => dispatch(profileActions.getGreenlightPeople(userId)),
   getMyGreenlitPeople: userId => dispatch(profileActions.getMyGreenlitPeople(userId)),
   unmountProfile: () => dispatch(profileActions.unmountProfile()),
   fetchProfileMedia: (userId, page, filters, sortType, sortOrder, specificMediaId,
     filtered) =>
     dispatch(profileActions.fetchProfileMedia(userId, page, filters, sortType,
       sortOrder, specificMediaId, filtered)),
   greenlightMedia: mediaId => dispatch(greenlightMedia(mediaId)),
 };
};

const ArenaContainer = connect(
 mapStateToProps,
 mapDispatchToProps
)(Payment);

export default ArenaContainer;
