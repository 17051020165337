const Config = {
  serverUrl: process.env.SERVER_URL,
  facebookAppId: process.env.FACEBOOK_APP_ID,
  instagramClientId: process.env.INSTAGRAM_CLIENT_ID,
  instagramClientSecret: process.env.INSTAGRAM_CLIENT_SECRET,
  pusherKey: process.env.PUSHER_KEY,
  soundCloudClientId: process.env.SOUNDCLOUD_CLIENT_ID,
  bucket: {
    "name": "7g-files-local",
    "uploads": "uploads",
    "arenaImage": "arena/image",
    "mediaContentImage": "media_content/image",
    "noteImage": "note/image",
    "userImage": "user/image",
    "sponsorImage": "sponsor/image",
    "venueRequest":"venue/request"
  }
}

export default Config