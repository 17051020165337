import React from 'react';
import PropTypes from 'prop-types';

import AboutTab from './AboutTab';
import HelpTab from './HelpTab';
import FAQTab from './FAQTab';
import MissionTab from './MissionTab';
import SponsorshipTab from './SponsorshipTab';
import LegalTab from './LegalTab';

const RenderTab = ({ contentTabs, faq, help, mission, about, sponsership, legal, tabs, selectedTab, fetchContent }) => {
  let tab;
  switch (selectedTab) {
    case tabs.help:
      tab = <HelpTab help={help} fetchContent={fetchContent}/>;
      break;
    case tabs.mission:
      tab = <MissionTab mission={mission} fetchContent={fetchContent}/>;
      break;
    case tabs.faq:
      tab = <FAQTab faq={faq} fetchContent={fetchContent} />;
      break;
    case tabs.about:
      tab = <AboutTab about={about} fetchContent={fetchContent}/>;
      break;
    case tabs.sponsors:
      tab = <SponsorshipTab sponsership={sponsership} fetchContent={fetchContent}/>;
      break;
    case tabs.legal:
        tab = <LegalTab legal={legal} fetchContent={fetchContent}/>;
        break;
    default:
      tab = <AboutTab about={about} fetchContent={fetchContent}/>;
      break;
  }

  return tab;
};

RenderTab.propTypes = {
  tabs: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  fetchContent: PropTypes.func.isRequired,
  help: PropTypes.string,
  mission: PropTypes.string,
  faq: PropTypes.string,
  about: PropTypes.string,
  sponsership: PropTypes.string,
  legal: PropTypes.string,
};

export default RenderTab;
