import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaList from "../Media/MediaList";
import ArenaCover from "./ArenaCover";
import PostToArena from "./PostToArena";
import { mediaListIds } from "../../constants";
import ShareModal from "../Share/ShareModal";
import Tooltip from "components/Tooltip/Tooltip";
import { tooltipIds } from "constants";
import InviteModal from "./InviteModal";
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchInvite,
  fetchDate,
  fetchArenaInfo,
  updateArenaInfo,
} from "../../api/arenaApi";
import AlreadyPostedModel from "./AlreadyPostedModel";
import Spinner from "../../utils/Loader";


class Arena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSponsorPane: false,
      isQueued: false,
      isBanned: false,
      isInvited: false,
      requestAccess: false,
      isPosted: false,
      isCutoff: false,
      isEnded: false,
      isInvitedBy: false,
      isInQueued: false,
      isInviteRequested: false,
      loaderIsLoading: false
    };
    this.handlePostToVenue = this.handlePostToVenue.bind(this);
    this.inviteModalCallback = this.inviteModalCallback.bind(this);
  }
  UNSAFE_componentWillMount() {
    const {
      fetchArena,
      params,
      fetchMedia,
      filterSortData,
      currentMyMediaPage,
      authenticated,
      user,
      fetchSponsorships,
      fetchArenaSponsors,
    } = this.props;
    fetchArena(params.arenaId, user.id, filterSortData);
    if (authenticated) {
      fetchSponsorships(user.id); 
    }

    fetchArenaSponsors(params.arenaId); 
    authenticated && fetchMedia(user.id, currentMyMediaPage);
  }

  async componentDidMount() {
    const { fetchArena, params, user, filterSortData, arena } = this.props;
    window.addEventListener("scroll", this.fetchNextPage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params, fetchArenasMedia, filterSortData, user, currentPage } =
      this.props;
    let filtered = true;

    if (this.propsHaveChanged(filterSortData, nextProps.filterSortData)) {
      fetchArenasMedia(
        params.arenaId,
        user.id,
        currentPage,
        nextProps.filterSortData,
        filtered
      );
    }
  }


  componentWillUnmount() {
    const { displayPostToArena, togglePostToArena } = this.props;
    window.removeEventListener("scroll", this.fetchNextPage);
    displayPostToArena && togglePostToArena();

  }

  propsHaveChanged(props, nextProps) {
    return (
      props.filters !== nextProps.filters ||
      props.sortType !== nextProps.sortType ||
      props.sortOrder !== nextProps.sortOrder ||
      props.typeIds !== nextProps.typeIds ||
      props.popIds !== nextProps.popIds
    );
  }

  fetchNextPage = () => {
    const {
      fetchArenasMedia,
      filterSortData,
      currentPage,
      params,
      user,
      loadMoreArenas,
    } = this.props;
    if (
      loadMoreArenas &&
      window.innerHeight + window.scrollY >= document.body.scrollHeight
    ) {
      fetchArenasMedia(params.arenaId, user.id, currentPage, filterSortData);
    }
  };


  //on Click of Post to Venue® button
  async handlePostToVenue(event) {
    const { arena, authenticated, togglePostToArena, toggleAuthModal, user } =
      this.props;

    if (!authenticated) {
      return toggleAuthModal();
    }

    const arenaType = arena.arenaType;
    const admittedUserIds = arena?.admittedUserIds ? arena.admittedUserIds : [];
    const invitedUserIds = arena?.invitedUserIds ? arena.invitedUserIds : [];
    const queuedUserIds = arena?.queuedUserIds ? arena.queuedUserIds : [];
    const bannedUserIds = arena?.bannedUserIds ? arena.bannedUserIds : [];
    const requestInviteUserIds = arena?.requestInviteUserIds ? arena?.requestInviteUserIds : [];

    const postedUser = arena.mediaContents.some((ele) => {
      return ele?.userId == user?.id;
    });
    const isBanned = bannedUserIds.filter(
      (tmpUser) => tmpUser.userId == user.id
    );
    const isAdmitted = admittedUserIds.filter(
      (tmpUser) => tmpUser.id === user.id
    );
    const isInvited = invitedUserIds.filter(
      (tmpUser) => tmpUser.id === user.id
    );
    const isQueued = queuedUserIds.filter(
      (tmpUser) => tmpUser.userId == user.id
    );
    const isInviteRequested = requestInviteUserIds.filter(
      (usr) => usr.userId === user.id
    );

    if (bannedUserIds.length > 0) {
      this.setState({
        isBanned: true,
      });
    }

    const currentDate = await fetchDate();
    const currentDateOnly = new Date(currentDate.object.date);
    currentDateOnly.setHours(0, 0, 0, 0);
    const arenaEndDateOnly = new Date(arena.endDate);
    arenaEndDateOnly.setHours(0, 0, 0, 0);
    const arenaCutOffDateOnly = new Date(arena.cutOffDate);
    arenaCutOffDateOnly.setHours(0, 0, 0, 0);

    if (currentDateOnly > arenaEndDateOnly) {
      this.setState({
        isEnded: true,
      });
      displayPostToArena = "false";
    } else if (currentDateOnly > arenaCutOffDateOnly) {
      this.setState({
        isCutoff: true,
      });
      displayPostToArena = "false";
    } else if (arena.mediaContents.length > 0 && postedUser === true) {
      this.setState({
        isPosted: true,
      });
      displayPostToArena = "false";
    }

    else if (this.state.isPosted) {
      return togglePosted();
    } else if (isAdmitted.length > 0) {
      return togglePostToArena();
    }
    else if (isBanned.length > 0) {
      this.setState({
        isBanned: true,
      });
    }
    else if (isInviteRequested.length > 0) {
      this.setState({
        isInviteRequested: true
      })
    } else if (isInvited.length > 0) {
      const userId = isInvited[0].id;
      const venueId = arena.id;
      let condition = {
        where: {
          invitedTo: { in: [userId] },
          venueId: { in: [arena.id] },
          inviteType: { in: ["Content Creator"] },
        },
      };

      const invitations = await fetchInvite(condition);
      if (invitations.length > 0) {
        const invitation = invitations[0];

        const { accepted } = invitation;
        if (accepted === false) {
          this.setState({
            isInvited: true,
          });
        } else {
          return togglePostToArena();
        }
      }
    } else if (
      arenaType === "inviteonly" && isInvited.length == 0 && !this.state.isPosted) {
      this.setState({
        requestAccess: true,
      });
    }
    else if (isQueued.length > 0) {
      this.setState({ isQueued: true });
    }
    else {
      return togglePostToArena();
    }
  }

  inviteModalCallback(status) {
    const { togglePostToArena } = this.props;
    this.setState({
      isInvited: status,
    });
  }

  handleModalFunctions = () => {
    const { arena, params, user } = this.props;
    const ArenaData = fetchArenaInfo(params.arenaId);
    const postedUser = arena.mediaContents.filter((ele) => {
      ele.userId == user.id;
    });
    ArenaData.then((data) => {
      const inviteduserData = data.invitedUserIds.map((userIds) => userIds.userId);
      let userIdData;
      Promise.resolve(inviteduserData).then((userIds) => {
        userIdData = userIds;
        if (userIdData.includes(user.id)) {
          if (arena.mediaContents.length > 0 && postedUser === true) {
            this.setState({
              isPosted: true,
            });
          }
          else {
            this.handleInvite();
          }
        }
        else {
          this.handlePostToVenue();
        }
      });
    });
  };


  acceptInviteRequest = () => {
    const { params, user, fetchArena, filterSortData } = this.props;
    this.setState({
      loaderIsLoading: true
    })
    fetchArenaInfo(params.arenaId)
      .then((arenaData) => {
        const invitedUsers = arenaData.invitedUserIds || [];
        const userInvitedIndex = invitedUsers.findIndex((invitedUser) => invitedUser.userId === user.id);
        if (userInvitedIndex !== -1) {
          const userToAdmit = invitedUsers.splice(userInvitedIndex, 1)[0];
          arenaData.admittedUserIds = [...(arenaData.admittedUserIds || []), userToAdmit];
          const NotifyUser = { user, MoveTo: "Admited" }
          updateArenaInfo(arenaData.id, {
            invitedUserIds: invitedUsers,
            admittedUserIds: arenaData.admittedUserIds,
            NotifyUser
          })
            .then((updatedData) => {
              this.setState({
                loaderIsLoading: false
              })
              fetchArena(params.arenaId, user.id, filterSortData);
              console.log("Arena data updated:", updatedData);
            })
            .catch((error) => {
              console.error("Error updating arena data:", error);
            });
        } else {
          console.log("User ID not found in invitedUserIds. No update performed.");
        }
      })
      .catch((error) => {
        console.error("Error fetching arena data:", error);
      });
  }


  handleInvite = () => {
    this.setState({
      isInvitedBy: true
    })
  }


  requestInviteClick = () => {
    const { params, user, fetchArena, filterSortData } = this.props;
    fetchArenaInfo(params.arenaId)
      .then((data) => {
        this.setState({
          loaderIsLoading: true
        })
        const queuedUsers = data.queuedUserIds;
        const userIndex = queuedUsers.findIndex((queuedUser) => queuedUser.userId === user.id);
        if (userIndex !== -1) {
          const removedUser = queuedUsers[userIndex];
          const updatedQueuedUsers = [
            ...queuedUsers.slice(0, userIndex),
            ...queuedUsers.slice(userIndex + 1),
          ];
          const requestInviteUsers = [...data.requestInviteUserIds];
          const NotifyUser = { user, MoveTo: "RequestInvite" }
          requestInviteUsers.push(removedUser);
          updateArenaInfo(params.arenaId, {
            queuedUserIds: updatedQueuedUsers,
            requestInviteUserIds: requestInviteUsers,
            updatedBy: user.name,
            NotifyUser: NotifyUser,
          }).then((data) => {
            console.log("Update successful:", data);
            this.setState({
              isQueued: false,
              loaderIsLoading: false
            });
            fetchArena(params.arenaId, user.id, filterSortData);
          })
            .catch((error) => {
              console.error("Error updating arena data:", error);
            });
        } else {
          console.log("User not found in requestInviteUserIds");
        }
      })
      .catch((error) => {
        console.error("Error fetching arena data:", error);
      });
  };
  render() {
    const {
      arena,
      showHideSponsor,
      toggleSponsor,
      setSponsorFalse,
      setSponsorTrue,
      greenlightArena,
      myMedia,
      arenaMedia,
      displayPostToArena,
      togglePostToArena,
      greenlightMedia,
      postMediaToArena,
      mediasToPost,
      addMediaToPost,
      removeMediaToPost,
      currentMyMediaPage,
      fetchMedia,
      toggleAuthModal,
      authenticated,
      displayShareModal,
      toggleShareModal,
      sharingObject,
      user,
      sponsorships,
      arenaSponsors,
      fetchArenaSponsors,
    } = this.props;
    const { isSponsorPane,

    } = this.state;

    return (
      <div className="full-page outerspace-bg">
        {this.state.loaderIsLoading && <Spinner />}
        {this.state.isInvitedBy && (
          <>
            <InviteModal
              isInitOpen={this.state.isInvitedBy}
              message={`Please accept Invite to Post Content`}
              title={`Invite Not Accepted`}
              inviteData={{ venueId: arena.id, invitedTo: user.id }}
              inviteModal={true}
              toggleModal={(status) => {
                this.setState({
                  isInvitedBy: status,
                });
              }}
              callBack={() => {
                this.acceptInviteRequest()
                this.setState({
                  isInvitedBy: false,
                })

                togglePostToArena()
              }}
            />
          </>
        )}

        {this.state.isQueued && (
          <>
            <InviteModal
              isInitOpen={this.state.isQueued}
              message={`Please Request Invite to Post Content`}
              title={`Request Invite`}
              isQueuedModal={true}
              toggleModal={(status) => {
                this.setState({
                  isQueued: status,
                });
              }}
              callBack={() => {
                this.requestInviteClick()
                this.setState({
                  isQueued: false
                })

              }}
            />
          </>
        )}

        <ArenaCover
          toggleSponsor={toggleSponsor}
          setSponsorTrue={setSponsorTrue}
          setSponsorFalse={setSponsorFalse}
          showHideSponsor={showHideSponsor}
          authenticated={authenticated}
          isSponsorPane={isSponsorPane}
          toggleSponerPane={() => {
            if (!authenticated) {
              return toggleAuthModal();
            }
            this.setState({ isSponsorPane: !isSponsorPane });
          }}
          arena={arena}
          user={user}
          sponsorships={sponsorships}
          arenaSponsors={arenaSponsors}
          fetchArenaSponsors={fetchArenaSponsors}
          greenlightArena={(event) => greenlightArena(event, arena.id)}
          image={arena.image}
          toggleShareModal={() => toggleShareModal(arena)}
        />
        <Tooltip tooltipId={tooltipIds.arenaSponsoring} />
        <div className="arena-media-container">
          <div className="arena-controls">
            <div className="has-tooltip">
              <button
                onClick={this.handleModalFunctions}
                className="form-button post-media"
              >
                Post to Venue®
              </button>

              <Tooltip tooltipId={tooltipIds.arenaPost} />
            </div>
          </div>
          <MediaList
            bannedUsers={this.props.arena}
            greenlightMedia={greenlightMedia}
            media={arena.mediaContents}
            hasPopsFilter
            type={mediaListIds.arenaPage}
            arenaId={arena.id}
          />
        </div>
        <PostToArena
          myMedia={myMedia}
          arena={arena}
          user={user}
          displayPostToArena={displayPostToArena}
          togglePostToArena={togglePostToArena}
          postMediaToArena={postMediaToArena}
          addMediaToPost={addMediaToPost}
          removeMediaToPost={removeMediaToPost}
          mediasToPost={mediasToPost}
          currentMyMediaPage={currentMyMediaPage}
          fetchMedia={fetchMedia}
          greenlightMedia={greenlightMedia}
        />
        {sharingObject && (
          <ShareModal
            isOpen={displayShareModal}
            toggleShareModal={toggleShareModal}
            sharingObject={sharingObject}
          />
        )}
        {this.state.isInvited && (
          <>
            <InviteModal
              isInitOpen={this.state.isInvited}
              message={`Please accept Invite to Post Content`}
              title={`Invite Not Accepted`}
              inviteData={{ venueId: arena.id, invitedTo: user.id }}
              inviteModal={true}
              toggleModal={(status) => {
                this.setState({
                  isInvited: status,
                });
              }}
              callBack={(status) => {
                this.inviteModalCallback(status);
              }}
            />
          </>
        )}

        {this.state.isBanned && (
          <InviteModal
            isInitOpen={this.state.isBanned}
            message={`You are banned in this Venue®. Please contact Adminstrator for more details. admin@7greenlights.com`}
            title={`Banned`}
            toggleModal={(status) => {
              this.setState({
                isBanned: status,
              });
            }}
          />
        )}
        {this.state.requestAccess && (
          <InviteModal
            isInitOpen={this.state.requestAccess}
            message={`This is an Invite Only Venue®. Please request access to Host`}
            title={`Request Access`}
            toggleModal={(status) => {
              this.setState({
                requestAccess: status,
              });
            }}
          />
        )}
        {this.state.isPosted && (
          <AlreadyPostedModel
            isInitOpen={true}
            message={`You have already posted to this Venue®`}
            title={`Already Posted!`}
            toggleModal={(status) => {
              this.setState({
                isPosted: status,
              });
            }}
          />
        )}
        {this.state.isInviteRequested && (
          <AlreadyPostedModel
            isInitOpen={true}
            message={`You have already send Invite Request to this Venue®`}
            title={`Already Invite Requested!`}
            toggleModal={(status) => {
              this.setState({
                isInviteRequested: status,
              });
            }}
          />
        )}
        {this.state.isEnded && (
          <AlreadyPostedModel
            isInitOpen={true}
            message={`Venue® has been Archived`}
            title={`Unable to Post to Venue®`}
            toggleModal={(status) => {
              this.setState({
                isEnded: status,
              });
            }}
          />
        )}
        {this.state.isCutoff && (
          <AlreadyPostedModel
            isInitOpen={true}
            message={`Cutoff date has passed`}
            title={`Unable to Post to Venue®`}
            toggleModal={(status) => {
              this.setState({
                isCutoff: status,
              });
            }}
          />
        )}
      </div>
    );
  }
}

Arena.propTypes = {
  arena: PropTypes.object,
  myMedia: PropTypes.array,
  arenaMedia: PropTypes.array,
  displayPostToArena: PropTypes.bool.isRequired,
  togglePostToArena: PropTypes.func.isRequired,
  fetchArenasMedia: PropTypes.func.isRequired,
  greenlightMedia: PropTypes.func.isRequired,
  fetchArena: PropTypes.func.isRequired,
  fetchMedia: PropTypes.func.isRequired,
  greenlightArena: PropTypes.func.isRequired,
  postMediaToArena: PropTypes.func.isRequired,
  mediasToPost: PropTypes.array,
  addMediaToPost: PropTypes.func.isRequired,
  removeMediaToPost: PropTypes.func.isRequired,
  params: PropTypes.object,
  filterSortData: PropTypes.object,
  toggleAuthModal: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  currentMyMediaPage: PropTypes.number.isRequired,
  displayShareModal: PropTypes.bool,
  sharingObject: PropTypes.object,
  toggleShareModal: PropTypes.func.isRequired,
};

export default Arena;
