import React from 'react'; import PropTypes from 'prop-types';

import PaymentReceived from './PaymentReceived'

const PaymentReceivedTab = ({ user }) => {
  return (
    <div className="tab">
     <PaymentReceived user={user} />
    </div>
  );
};

PaymentReceivedTab.propTypes = {
  user: PropTypes.object.isRequired,  
};

export default PaymentReceivedTab;
