import api from './apiService.js';
import { getStore } from '../index';
import { paginate, applyFilters, applySorting,
  applyArrayFilters, applySpecificMedia } from './apiHelpers';
import * as constants from '../constants.js';

export const uploadMedia = (media) => {
  delete media.media_url;
  delete media.media_url;
  delete media.remote_image_url;
  let user = getStore().getState().loginReducer.user;
  media.userId = user.id;
  media.username = user.name;
  return api.post('mediaContents/postMedia', media);
};

export const fetchMedia = (userId, page, filters, sortType, sortOrder, specificMedia,
  typeIds = [], perPage = 20) => {
  let filter = {}
  // let url = 'MediaContents';
  // let url = `arenas/${arenaId}/mediaContetns/${userId}`;
  let url = `MediaContents/me/${userId}`;
  url = paginate(url, page, perPage, filter);
  url = applySorting(url, sortType, sortOrder);
  // url = applyFilters(url, filter);
  url = applyArrayFilters(url, constants.mediaFilters.types, typeIds);
  // url = applySpecificMedia(url, specificMedia);
  return api.get(url);
};

export const deleteMedia = (id) => {
  return api.delete(`mediaContents/${id}`);
};

export const updateMedia = (media) => {
  delete media.user;
  delete media.notes;
  return api.post(`mediaContents/updateMedia/${media.id}/${media.userId}`, {name: media.name, links: media.links});
};

export const reportMedia = (mediaContentId, userId, report) => {
  let payload = {
    "userId": userId,
    "mediaContentId": mediaContentId,
    "message": report
  }
  return api.post(
    `reports`, payload
  );
};

export const uploadMediaNote = (mediaContentId, noteData) => {
  let user = getStore().getState().loginReducer.user;
  console.log("user", user)
  return api.post(`mediaContents/${mediaContentId}/notes/${user.id}`, noteData, true);
};
