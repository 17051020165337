import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderTab from './RenderTab';
import TabDataContainer from './TabDataContainer';
import * as constants from '../../constants';

class About extends Component {

  componentDidMount() {
    const { switchTab, fetchContentTabs, tabsData } = this.props;
    const tabs = constants.aboutTabs;
    const hashTab = window.location.hash.substring(1);
    (hashTab && hashTab !== '') && switchTab(tabs[hashTab]);
    fetchContentTabs()
  }

  render() {
    const { contentTabs, tabsData, /* faq, help, mission, about, sponsership, legal, */ selectedTab, switchTab ,fetchContent } = this.props;
    const tabs = constants.aboutTabs;
    return (
      <div className="full-page outerspace-bg">
        <div className="about-container">
        <div className="tab-headers-container">
            {
              Object.values(contentTabs).map((key) =>

               <button
                key={key['name']}
                type="button"
                className="tab-header"
                onClick={() => switchTab(key['name'])}
                >
                <h1 className={(selectedTab === key['name']) ? 'active': ''}>
                  {key['label'].toUpperCase()}
                </h1>
                { (selectedTab === key['name']) && <hr />}
              </button>
            )
            }
        </div>
        <TabDataContainer
          name={selectedTab}
          fetchContent={fetchContent}
          tabsData={tabsData}
        />
      </div>
    </div>);
  }
}

About.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  switchTab: PropTypes.func.isRequired,
  contentTabs: PropTypes.array,
  tabsData: PropTypes.object,
  fetchContent: PropTypes.func.isRequired,
  /* help: PropTypes.string,
  mission: PropTypes.string,
  faq: PropTypes.string,
  about: PropTypes.string,
  sponsership: PropTypes.string,
  legal: PropTypes.string, */

};

export default About;
