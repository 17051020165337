import React from 'react'; import PropTypes from 'prop-types';

import MediaTab from './MediaTab';
import BioTab from './BioTab';
import BlastsTab from './BlastsTab';
import GreenlitPeopleTab from './GreenlitPeopleTab';
import GreenlightPeopleTab from './GreenlightPeopleTab';
import NotesTab from './NotesTab';
import VenueSponsorshipTab from './VenueSponsorshipTab';
import VenuePostedTab from './VenuePostedTab';
import { profileTabs } from 'constants';
import VenueRequestedTab from './VenueRequestTab';


const RenderTab = ({ user, selectedTab, isMine, updateUser, greenlightUser,
  blastsTmData,myNotesData, greenlightPeople, greenlitPeople, greenlightMedia, media, sponsorships, venuePostedList, venueRequest }) => {
     console.log(myNotesData, " renderTab notes");
 
  
    switch (selectedTab) {
    case profileTabs.media:
      return <MediaTab greenlightMedia={greenlightMedia} media={media} user={user} />;
    case profileTabs.bio:
     return <BioTab isMine={isMine} user={user} updateUser={updateUser} />;
    case profileTabs.blasts:
     return <BlastsTab blastsTmData={blastsTmData} user={user} />;
    // case profileTabs.greenlitPeople:
    //  return (
    //     <GreenlitPeopleTab
    //       greenlitPeople={greenlitPeople}
    //       greenlightUser={greenlightUser}
    //       user={user}
    //     />
    //   );
    // case profileTabs.greenlightPeople:
    // return (
    //     <GreenlightPeopleTab
    //       greenlightPeople={greenlightPeople}
    //       greenlightUser={greenlightUser}
    //       user={user}
    //     />
    //   );
    case profileTabs.notes:
     return (
        <NotesTab
          // user={user}
          myNotesData={myNotesData}
        />
      );
    case profileTabs.venuePostedTo:
      return (
        <VenuePostedTab
          user={user}
          sponsorships={venuePostedList}
        />
      );
    case profileTabs.venueSponsorship:
      return (
        <VenueSponsorshipTab
          user={user}
          sponsorships={sponsorships}
        />
      ); 
    case profileTabs.venueRequest:
      return (
        <VenueRequestedTab
        user={user}
        venueRequest={venueRequest}
        />
      )
    
      
    default:
     return <MediaTab greenlightMedia={greenlightMedia} media={media} user={user} />;
  }
};

RenderTab.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  blastsTmData: PropTypes.array,
  greenlightPeople: PropTypes.array,
  greenlitPeople: PropTypes.array,
  greenlightUser: PropTypes.func.isRequired,
  media: PropTypes.array,
  greenlightMedia: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isMine: PropTypes.bool.isRequired,
};

export default RenderTab;
