import React, { Component } from 'react'; import PropTypes from 'prop-types';
import ProfileCover from '../Profile/ProfileCover';
import PaymentTabs from './PaymentTabs';
import { profileTabs } from 'constants';
import { getParameterByName, mediaLink } from 'utils/helpers';
import { browserHistory } from 'react-router';

class Payment extends Component {

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.fetchNextPage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params, unmountProfile, filters, sortType, sortOrder,
      fetchProfileMedia } = this.props;
    const mediaParam = getParameterByName('media');

    if (nextProps.params.profileId != params.profileId) {
      unmountProfile();
      this.loadData(nextProps);
    } else if (filters !== nextProps.filters ||
      sortType !== nextProps.sortType ||
      sortOrder !== nextProps.sortOrder) {

      fetchProfileMedia(params.profileId, nextProps.currentPage, nextProps.filters,
        nextProps.sortType, nextProps.sortOrder, mediaParam);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.fetchNextPage);
  }

  isMine(id = null) {
    const { currentUser, params } = this.props;
    const profileId = id || params.profileId;
    return (currentUser !== null && (profileId == currentUser.id));
  }

  loadData(props) {
    const { getProfile, getMyProfile, switchTab, currentUser } = this.props;
    const isMine = this.isMine(props.params.profileId);

    if (isMine) {
      const path = mediaLink(props.params.profileId, mediaParam, true);
      const mediaParam = getParameterByName('media');
      getMyProfile(currentUser.id);     
      switchTab(profileTabs.paymentDetails);
      mediaParam && browserHistory.push(path);
    } else {
      getProfile(props.params.profileId);     
    }
  }

  fetchNextPage = () => {
    const { fetchProfileMedia, filters, sortType, sortOrder, params,
      currentPage } = this.props;

    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      fetchProfileMedia(params.profileId, currentPage, filters, sortType,
        sortOrder);
    }
  }

  render() {
    const { currentUser, switchTab, currentTab, updateUser,
      profileData, greenlightUser, updateUserPicture, paymentDetails } = this.props;
    let isMine = this.isMine();
    let user = isMine ? currentUser : profileData;

    return (
      <div className="full-page outerspace-bg">
        <ProfileCover
          isMine={false}
          user={user}
          updateUser={updateUser}
          updateUserPicture={updateUserPicture}
          greenlightUser={greenlightUser}
        />
        <PaymentTabs
          user={user}
          selectedTab={currentTab}
          switchTab={switchTab}
          isMine={isMine}
          updateUser={updateUser}
        />
      </div>
    );
  }
}

Payment.propTypes = {
  paymentDetails: PropTypes.array,
  paymentDone: PropTypes.array,
  paymentReceived: PropTypes.array,
  unmountProfile: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  profileData: PropTypes.object.isRequired,
  switchTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserPicture: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getMyProfile: PropTypes.func.isRequired,
  greenlightMedia: PropTypes.func.isRequired,
  profileMedia: PropTypes.array,
  params: PropTypes.object,
  filters: PropTypes.array,
  sortOrder: PropTypes.number,
  sortType: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
};

export default Payment;
