import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchDate } from "../../../api/arenaApi";

import profilePlaceholder from "../../App/img/profile-ph.png";
import { Link } from "react-router";
import Greenlight from "../../Greenlight/Greenlight";

const VenuePostedList = ({ sponsorships }) => {
  const [currentDate, setCurrentDate] = useState();
  useEffect(() => {
    fetchCurrentDate();
  
  }, []);

  const fetchCurrentDate = async () => {
    setCurrentDate(await fetchDate());
  };
  const formatDate = (dt) => {
    return new Date(dt).toLocaleDateString("en-us", {
      //weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
          flexWrap: "wrap",
          padding: "1rem",
        }}
      >
        {sponsorships &&
          sponsorships.map((sponsorship, index) => (
            <div class="card" key={index}>
              <div class="img">
                <img src={sponsorship.image || profilePlaceholder} />
              </div>
              <span>
                <Link to={`/arena/${sponsorship?.arena?.id}`}>
                  <p class="info title"> Venue : {sponsorship.arena.name} </p>
                </Link>
              </span>
              <p class="info">Type : {sponsorship?.arena?.arenaType}</p>
              {sponsorship?.arena?.endDate > currentDate?.object?.date ? (
                <span>
                  <div class="info">
                    Venue Status :<span>Active</span>
                    <div className="show-date">
                      <p>Ends on : {formatDate(sponsorship?.arena?.endDate)}</p>
                    </div>
                  </div>
                </span>
              ) : (
                <p class="info">Venue Status :<span>Archived</span></p>
              )}
            </div>
          ))}
      </div> */}
      { <table className="profile-table">
        <thead>
          <tr>
            <td className="coming-soon"><label className="profile-table-heading">Content</label></td>
            <td className="coming-soon"><label className="profile-table-heading">Venue®</label></td>
            <td className="coming-soon"><label className="profile-table-heading">Date Posted</label></td>
            <td className="coming-soon"><label className="profile-table-heading">Venue Revenue™</label></td>
          </tr>
        </thead>
        <tbody>
          {sponsorships &&
            sponsorships.map((sponsorship, index) => (
              <tr key={index} className="notes-list-row">
                <td className="table-label">
                  <div className="notes-list-item">
                      <div className="posted-content">
                        <div className="content-img">
                        <img className="media-item-img" src={sponsorship?.mediaContent?.image} />
                        </div>
                        <Greenlight
                          greenlit={sponsorship?.mediaContent?.arenaGreenlightCount > 0 ? true: false}
                          counter={sponsorship?.mediaContent?.arenaGreenlightCount}
                          greenlightAction={() => {}}
                          noPadding={true}
                        />
                      </div>
                  </div>
                </td>
                <td className="table-label">
                  <Link to={`/arena/${sponsorship?.arena?.id}`}>
                    {sponsorship?.arena?.name}
                  </Link>
                </td>
                <td className="table-label">{formatDate(sponsorship?.mediaContent?.createdAt)}</td>

                {/* <td className="table-label">
                  {sponsorship?.arena?.endDate > currentDate?.object?.date ? (
                    <div>
                      <div>Active</div>
                      <div className="show-date">
                        Ends on : {formatDate(sponsorship?.arena?.endDate)}
                      </div>
                    </div>
                  ) : (
                    `Archived`
                  )}
                </td> */}

                <td className="table-label">

                  {
                    sponsorship?.payoutCalculated == false &&
                    `N/A`
                  }

                  {
                    sponsorship?.payoutCalculated != false && sponsorship?.arena?.Payments?.length == 0 &&
                    `$ 0`
                  }

                  {
                    sponsorship?.payoutCalculated != false && sponsorship?.arena?.Payments?.length > 0 && sponsorship?.payout?.myTotalRevenue == null &&
                    `$ 0`
                  }

                  {
                    sponsorship?.payoutCalculated != false && sponsorship?.arena?.Payments?.length > 0 && sponsorship?.payout?.myTotalRevenue != null &&
                    `$ ${sponsorship?.payout?.myTotalRevenue}`
                  }


                  </td>
              </tr>
            ))}
        </tbody>
      </table> }
    </>
  );
};

VenuePostedList.propTypes = {
  user: PropTypes.object,
  sponsorships: PropTypes.array,
};

export default VenuePostedList;
