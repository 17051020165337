import { createTransform } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';

const loginReducerFilter = createFilter(
    'loginReducer',
    ['user', 'authenticated']
  );

const tooltipsFilter = createFilter(
'commonReducer',
['showTooltips']
);

const SetTransform = createTransform(

  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    return { ...inboundState, mySet: [...inboundState.mySet] };
  },

  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    return { ...outboundState, mySet: new Set(outboundState.mySet) };
  },

  // define which reducers this transform gets called for.
  { whitelist: [loginReducerFilter, tooltipsFilter] }
);

export default SetTransform;