import { connect } from "react-redux";
import Arena from "../components/Arena/Arena";
import {
  fetchArena,
  togglePostToArena,
  postMediaToArena,
  addMediaToPost,
  removeMediaToPost,
} from "../actions/arenaActions";
import { greenlightArena, greenlightMedia } from "../actions/greenlightActions";
import { fetchMedia, fetchArenasMedia } from "../actions/mediaActions";
import {
  toggleAuthModal,
  toggleShareModal,
  toggleSponsor,
  setSponsorFalse,
  setSponsorTrue,
} from "../actions/commonActions";
import {
  fetchSponsorships,
  fetchArenaSponsors,
} from "../actions/sponsorActions";

const mapStateToProps = (state) => {
  const enabledMediaList = state.filtersortReducer.sorts["mediaList"].enabled;
  return {
    arena: state.arenasReducer.currentArena,
    showHideSponsor: state.commonReducer.showHideSponsor,
    sponsorships: state.sponsorReducer.sponsorships,
    arenaSponsors: state.sponsorReducer.arenaSponsors,
    myMedia: state.mediaReducer.myMedia,
    arenaMedia: state.mediaReducer.arenaMedia,
    displayPostToArena: state.arenasReducer.displayPostToArena,
    mediasToPost: state.arenasReducer.mediasToPost,
    filterSortData: {
      filters: state.filtersortReducer.filters["mediaList"].enabled,
      sortType: enabledMediaList,
      sortOrder:
        state.filtersortReducer.sorts["mediaList"].sortOrder[enabledMediaList],
      popIds: state.filtersortReducer.filters["mediaList"].popIds,
      typeIds: state.filtersortReducer.filters["mediaList"].typeIds,
    },
    authenticated: state.loginReducer.authenticated,
    user: state.loginReducer.user,
    currentPage: state.arenasReducer.currentArenaMediaPage,
    currentMyMediaPage: state.arenasReducer.currentMyMediaPage,
    displayShareModal: state.shareReducer.displayShareModal,
    sharingObject: state.shareReducer.sharingObject,
    loadMoreArenas: state.arenasReducer.loadMoreArenas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArena: (arenaId, userId, filterSortData) => {
      dispatch(fetchArena(arenaId, userId, filterSortData));
    },
    fetchSponsorships: (userId) => {
      dispatch(fetchSponsorships(userId));
    },
    fetchArenaSponsors: (arenaId) => {
      dispatch(fetchArenaSponsors(arenaId));
    },
    greenlightArena: (event, arenaId) => {
      dispatch(greenlightArena(arenaId));
      event.stopPropagation();
    },
    fetchMedia: (userId, page) => dispatch(fetchMedia(userId, page)),
    togglePostToArena: () => dispatch(togglePostToArena()),
    fetchArenasMedia: (arenaId, userId, page, filterSortData, filtered) =>
      dispatch(
        fetchArenasMedia(arenaId, userId, page, filterSortData, filtered)
      ),
    greenlightMedia: (mediaId, userId, arenaId) =>
      dispatch(greenlightMedia(mediaId, userId, arenaId)),
    addMediaToPost: (id) => dispatch(addMediaToPost(id)),
    removeMediaToPost: (id) => dispatch(removeMediaToPost(id)),
    postMediaToArena: (medias, arenaId, userId) => {
      let data = { arena_id: arenaId, medias, userId };
      dispatch(postMediaToArena(data));
    },
    toggleAuthModal: () => dispatch(toggleAuthModal()),
    toggleShareModal: (media) => dispatch(toggleShareModal(media)),
    toggleSponsor: () => dispatch(toggleSponsor()),
    setSponsorTrue: () => dispatch(setSponsorTrue()),
    setSponsorFalse: () => dispatch(setSponsorFalse()),
  };
};

const ArenaContainer = connect(mapStateToProps, mapDispatchToProps)(Arena);

export default ArenaContainer;
