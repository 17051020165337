import React from "react";
import PropTypes from "prop-types";
var moment = require("moment");

import profilePlaceholder from "../../App/img/profile-ph.png";

const NotesMedia = ({ user, myNotesData }) => {
  console.log(myNotesData.myNotesData, "note..........1");
  return (
    <table>
      <thead>
        <tr>
          <td className="coming-soon">Note Image</td>
          <td className="coming-soon">Venue Name</td>
          <td className="coming-soon">Feature</td>
          {/* <td className="coming-soon">Media Title</td> */}
          <td className="coming-soon">Date Updated</td>
        </tr>
      </thead>
      <tbody>
        {myNotesData &&
          myNotesData?.myNotesData?.map((note, index) => (
            <tr key={index}>
              <td className="table-label">
                <div className="notes-list-item">
                  <img
                    className="media-item-img"
                    src={note?.image}
                  />
                </div>
              </td>
              <td className="table-label">{note?.mediaContent.name}</td>
              <td className="table-label">
                {note?.isFeatured ? "Featured" : "Not featured"}
              </td>
              <td className="table-label">
                {moment(note?.createdAt.createdAt).format("DD-MM-YYYY")}
              </td>
            </tr>
          ))}

        {/* <tr>
            <td className="table-label">
              <div className="notes-list-item">
                <img
                  className="media-item-img"
                  src={note?.image}
                  // src={'https://images.freeimages.com/images/large-previews/535/natural-wonders-1400924.jpg'}
                />
              </div>
            </td>
            <td className="table-label">{note?.mediaContentId}</td>
            <td className="table-label">{note?.isFeatured}</td>
            <td className="table-label">{note?.createdAt}</td>
          </tr> */}
        {/* <tr>
            <td className="table-label">1/28/19</td>
            <td className="table-label">sfv1e321r3</td>
            <td className="table-label">Primary Sponsor</td>
            <td className="table-label"> $500</td>
          </tr>
          <tr>
            <td className="table-label">1/28/19</td>
            <td className="table-label">vdv132255e</td>
            <td className="table-label">Secondary Sponsor</td>
            <td className="table-label">$100</td>
          </tr>
          <tr>
          <td className="table-label">1/28/19</td>
            <td className="table-label">fvds245345</td>
            <td className="table-label">Secondary Sponsor</td>
            <td className="table-label">$50</td>
          </tr> */}
      </tbody>
    </table>
  );
};

NotesMedia.propTypes = {
  // user: PropTypes.object.isRequired,
  // myNotesData: PropTypes.array.isRequired,
};

export default NotesMedia;
