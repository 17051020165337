import React from 'react'; import PropTypes from 'prop-types';

import speakerIcon from './img/green-speaker.png';
import BlastForm from './BlastForm';
import { connect } from 'react-redux';
import { sendBlast } from '../../actions/blastActions';
import Tooltip from '../Tooltip/Tooltip';
import ToolTipR from '../Common/Tooltip/Tooltip';
import { tooltipIds, toolTipMessages } from '../../constants';
import { reset } from 'redux-form';
const {id, place, type, effect, message } = toolTipMessages.blastInfo

let MediaBlastBox = ( { postBlast, lastBlast, resetBlastForm } ) => (


  <div className="media-blast has-tooltip">
    <div className="blast-box">
      <img src={speakerIcon} />
      <h2>Post a Blast™ <ToolTipR
          id={id}
          place={place}
          type={type}
          effect={effect}
          message={message}
      /></h2>
    </div>
    <BlastForm postBlast={postBlast} hasButton lastBlast={lastBlast} resetBlastForm={resetBlastForm} name="blast"/>
    <Tooltip tooltipId={tooltipIds.myMediaBlast} />

  </div>
);

MediaBlastBox.propTypes = {
  postBlast: PropTypes.func.isRequired,
  lastBlast: PropTypes.string,
};

MediaBlastBox = connect(
  () => ({}),
  (dispatch) => {
    return {
      postBlast: (text) => dispatch(sendBlast(text)),
      resetBlastForm: () => dispatch(reset('blast')),
    };
  }
)(MediaBlastBox);

export default MediaBlastBox;
