import api from './apiService.js';

export const forgotPassword = (email) => {
  return api.post('users/reset', email);
};

export const updatePassword = (data) => {
  const token = data?.reset_password_token
  return api.post(`Users/reset-password?access_token=${token}`, { newPassword: data?.password });
};
