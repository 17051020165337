import { connect } from "react-redux";
import Profile from "../components/Profile/Profile";
import * as profileActions from "../actions/profileActions";
import { greenlightUser, greenlightMedia } from "../actions/greenlightActions";
import {
  fetchSponsorships,
  fetchArenaSponsors,
} from "../actions/sponsorActions";
import {fetchArenasWhereUserPosted} from "../actions/arenaActions";
import {
  fetchVenuesRequests
} from "../actions/venueActions";

const mapStateToProps = (state) => {
  const enabledMediaList = state.filtersortReducer.sorts["mediaList"].enabled;
  return {
    currentUser: state.loginReducer.user,
    profileData: state.profileReducer.profileData,
    blastsTmData: state.profileReducer.blastsTmData,

    myNotesData:state.profileReducer.myNotesData,

    greenlitPeople: state.profileReducer.greenlitPeople,
    greenlightPeople: state.profileReducer.greenlightPeople,
    currentTab: state.profileReducer.currentTab,
    profileMedia: state.profileReducer.profileMedia,
    filters: state.filtersortReducer.filters["mediaList"].enabled,
    sortType: enabledMediaList,
    sortOrder:
      state.filtersortReducer.sorts["mediaList"].sortOrder[enabledMediaList],
    currentPage: state.profileReducer.currentProfileMediaPage,
    sponsorships: state.sponsorReducer.sponsorships,
    venuePostedList: state.arenasReducer.postedVenues,
    earnedRevenue: state.arenasReducer.earnedRevenue,
    venueRequest : state.venueRequestReducer.venueRequest
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchTab: (id) => dispatch(profileActions.switchProfileTab(id)),
    updateUser: (userData) => dispatch(profileActions.updateUser(userData)),
    updateUserPicture: (userData) =>
      dispatch(profileActions.updateUserPicture(userData)),
    getProfile: (userId) => dispatch(profileActions.getProfile(userId)),
    getMyProfile: (userId) => dispatch(profileActions.getMyProfile(userId)),
    getMyBlast: (userId) => dispatch(profileActions.getMyBlast(userId)),

    getMyNotes: (userId) => {console.log("inside Disp Note")
    dispatch(
      profileActions.getMyNotes(userId))},

    greenlightUser: (userId, currentUserId) =>
      dispatch(greenlightUser(userId, currentUserId)),
    getGreenlitPeople: (userId) =>
      dispatch(profileActions.getGreenlitPeople(userId)),
    getGreenlightPeople: (userId) =>
      dispatch(profileActions.getGreenlightPeople(userId)),
    getMyGreenlitPeople: (userId) =>
      dispatch(profileActions.getMyGreenlitPeople(userId)),
    unmountProfile: () => dispatch(profileActions.unmountProfile()),
    fetchProfileMedia: (
      userId,
      page,
      filters,
      sortType,
      sortOrder,
      specificMediaId,
      filtered
    ) =>
      dispatch(
        profileActions.fetchProfileMedia(
          userId,
          page,
          filters,
          sortType,
          sortOrder,
          specificMediaId,
          filtered
        )
      ),
    greenlightMedia: (mediaId) => dispatch(greenlightMedia(mediaId)),
    fetchSponsorships: (userId) => {
      
      dispatch(fetchSponsorships(userId));
    },
    fetchVenuesRequests: (userId) => {      
    
      dispatch(fetchVenuesRequests(userId));
    },
    fetchVenuePostedList: (userId) => {
      dispatch(fetchArenasWhereUserPosted(userId));
    }
  };
};

const ArenaContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ArenaContainer;
