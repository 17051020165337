import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import BlastBox from "../Blast/BlastBox";
import triangleIcon from "./img/triangle.png";
import UserDropDown from "./UserDropDown";
import { Link } from "react-router";
import Tooltip from "components/Tooltip/Tooltip";
import { tooltipIds } from "constants";
import Modal from "react-modal";
import NotificationsButton from "./NotificationsButton";
import onClickOutside from "react-onclickoutside";

const UserBox = ({
  user,
  logout,
  toggleTopBarModal,
  shouldBeDisplayed,
  disableNotification,
  toggleNotificationModal,
  closeNotificationModel,
  hasNewNotification,
  inHomepage,
  shouldBeDisplayedVenueMenus,
  toggleVenueMenus,
  closeVenueMenus,
}) => {
  const [isVenueMenu, setIsVenue] = useState(false);

  UserBox.handleClickOutside = () => {
    setIsVenue(false);
    closeNotificationModel();
  };

  const handelVenueMenu = () => {
    // setIsVenue(true);
    // toggleVenueMenus();
  };

  return (
    <div className="user-box">
      <div className="user-links">
        <BlastBox inHomepage={inHomepage} />

        <div className="divider-line" />
        <nav
          style={{ position: "relative", display: "block" }}
          // onMouseOver={toggleVenueMenus}
          // onMouseOut={closeVenueMenus}
          onClick={handelVenueMenu}
          className="has-tooltip link"
        >
          Venues®
          {inHomepage && <Tooltip tooltipId={tooltipIds.homeArena} />}
          {/* {shouldBeDisplayedVenueMenus ? ( */}
          {/* {isVenueMenu ? (
            <ul
              style={{
                listStyleType: "none",
                margin: 0,
                padding: 0,
                top: "30px",
                right: "0px",
                width: "220px",
                backgroundColor: "white",
                fontWeight: "bold",
                position: "absolute",
                boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                zIndex: 1,
              }}
            >
              <li
                style={{
                  padding: "8px 16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <a
                  className="active"
                  href="#Create Page"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    fontSize: "1.0em",
                  }}
                >
                  My Venue®
                </a>
              </li>
              <li
                style={{
                  padding: "8px 16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <a
                  href="#Manage Pages"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    fontSize: "1.0em",
                  }}
                >
                  My Blasts™
                </a>
              </li>
              <li
                style={{
                  padding: "8px 16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <a
                  href="#Manage Pages"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    fontSize: "1.0em",
                  }}
                >
                  My GreenLights®
                </a>
              </li>
              <li
                style={{
                  padding: "8px 16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <a
                  href="#Create Ads"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    fontSize: "1.0em",
                  }}
                >
                  My Sponsorships
                </a>
              </li>
            </ul>
          ) : null} */}
        </nav>
        <UserDropDown
          user={user}
          onRequestClose={toggleTopBarModal}
          isOpen={shouldBeDisplayed}
          logout={logout}
        />

        <Link to="/mymedia">My Dock™</Link>
        <Link to="/mystream">My 7G Stream™</Link>
        <Link to="/about">Help</Link>

        <NotificationsButton
          toggleNotificationModal={toggleNotificationModal}
          hasNewNotification={hasNewNotification}
        />

        <Link className="username-link" to={`/profile/${user.id}`}>
          {user.name}
        </Link>
        <img
          className="triangle-icon"
          src={triangleIcon}
          onClick={toggleTopBarModal}
        />
        <UserDropDown
          user={user}
          onRequestClose={toggleTopBarModal}
          isOpen={shouldBeDisplayed}
          logout={logout}
        />
      </div>

      <NotificationsButton
        toggleNotificationModal={toggleNotificationModal}
        hasNewNotification={hasNewNotification}
        forMobile
      />
    </div>
  );
};

UserBox.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  toggleTopBarModal: PropTypes.func.isRequired,
  toggleVenueMenus: PropTypes.func.isRequired,
  closeVenueMenus: PropTypes.func,
  shouldBeDisplayed: PropTypes.bool,
  shouldBeDisplayedVenueMenus: PropTypes.bool,
  toggleNotificationModal: PropTypes.func.isRequired,
  hasNewNotification: PropTypes.bool.isRequired,
  inHomepage: PropTypes.bool,
};

const clickOutsideConfig = {
  handleClickOutside: () => UserBox.handleClickOutside,
};

export default onClickOutside(UserBox, clickOutsideConfig);
// export default UserBox;
