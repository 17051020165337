import React from 'react'; import PropTypes from 'prop-types';

import MediaForm from './MediaForm';

const EditMedia = ({ mode, uploadMedia, updateCurrentLink,
  currentWhereLink, mediaUrl, contentMedium, setMediaLink, toggleUploadMedia }) => {

  let isEdit = (mode === 'edit');
  return (
    <div className="edit-media">
      <h1>{isEdit ? 'Edit Venue™' : 'Upload to Venue™'}</h1>
      <MediaForm
        submitMedia={uploadMedia}
        updateCurrentLink={updateCurrentLink}
        currentWhereLink={currentWhereLink}
        mediaUrl={mediaUrl}
        contentMedium={contentMedium}
        setMediaLink={setMediaLink}
        toggleUploadMedia={toggleUploadMedia}
        isEdit={isEdit}
      />
    </div>
  );
};

EditMedia.propTypes = {
  mode: PropTypes.oneOf(['edit', 'upload']).isRequired,
  uploadMedia: PropTypes.func.isRequired,
  updateCurrentLink: PropTypes.func.isRequired,
  currentWhereLink: PropTypes.string,
  mediaUrl: PropTypes.string,
  contentMedium: PropTypes.string,
  setMediaLink: PropTypes.func.isRequired,
  toggleUploadMedia: PropTypes.func.isRequired,
};

export default EditMedia;
