import api from './apiService.js';
import * as constants from '../constants';

export const fetchContent = (filter) => {
    let url = `SiteContents?filter=${JSON.stringify(filter)}`;
    return api.get(url);
  };

export const fetchContentTabs = (filter) => {
    let url = `SiteContents?filter=${JSON.stringify(filter)}`;
    return api.get(url);
  };