import React from "react";
import PropTypes from "prop-types";

import NotesMedia from "./NotesMedia";

const NotesTab = (myNotesData) => {
  console.log(" notes notesTabs",  myNotesData);
  return (
    <div className="tab">
      {/* {myNotesData &&
        Object.entries(myNotesData).map((notes, index) => (
          <NotesMedia key={index} notes={notes} />
        ))} */}

      <NotesMedia myNotesData={myNotesData}/>
    </div>
  );
};

NotesTab.propTypes = {
  // myNotesData: PropTypes.array.isRequired,
};

export default NotesTab;
