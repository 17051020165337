import api from './apiService.js';
import { paginate, notesToObject } from './apiHelpers';

export const fetchNotes = (mediaId, page, perPage = 50) => {
  let url = `mediaContents/${mediaId}/getMediaNotes`;
  let filter = {};
  url = paginate(url, page, perPage, filter);
  return api.get(url);
};

export const featureNotes = async (notes, disregardedNotes, mediaId) => {
  return api.post(`mediaContents/${mediaId}/updateNotes`,
    notesToObject(notes, disregardedNotes));
};
