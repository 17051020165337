import * as types from '../actions/actionTypes';

const initialState = {
  notifications: [],                   
  displayNotificationsModal: false,  
  newNotification: false,               
  currentPage: 1,                      
  unreadNotificationCount: 0,       
};

export default function notificationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGOUT_SUCCESS: {
    
      return {
        ...state,
        notifications: initialState.notifications,
      };
    }
    case types.CLOSE_NOTIFICATIONS_MODAL: {

      return {
        ...state,
        displayNotificationsModal: false,
      };
    }
    case types.TOGGLE_NOTIFICATIONS_MODAL: {
   
      return {
        ...state,
        displayNotificationsModal: !state.displayNotificationsModal,
        newNotification: false, 
      };
    }
    case types.NEW_NOTIFICATION: {
     
      const notification = action.notification;
      const newNotificationsArray = [notification, ...state.notifications];
      return {
        ...state,
        notifications: newNotificationsArray,
        newNotification: true, 
        unreadNotificationCount: state.unreadNotificationCount + 1, 
      };
    }
    case types.MARK_NOTIFICATION_AS_READ: {

      const { notificationId } = action;
      const updatedNotifications = state.notifications.map(notification =>
        notification.id === notificationId
          ? { ...notification, read: "true" }
          : notification
      );
      return {
        ...state,
        notifications: updatedNotifications,
        unreadNotificationCount: state.unreadNotificationCount - 1
      };
    }
    case types.GET_NOTIFICATIONS_SUCCESS: {
      const notificationsArray = action.notifications;
      let nextPage = state.currentPage + (action.notifications.length === 0 ? 0 : 1);
      const updatedNotifications = state.currentPage !== 1
        ? state.notifications.concat(notificationsArray)
        : notificationsArray;

   
      const uniqueNotifications = Array.from(new Map(updatedNotifications.map(notification =>
        [notification.id, notification]
      )).values());


      const unreadNotificationsCount = uniqueNotifications.reduce((count, notification) =>
        count + (notification.read === "false" ? 1 : 0), 0);

      return {
        ...state,
        notifications: uniqueNotifications,
        unreadNotificationCount: unreadNotificationsCount,
        currentPage: nextPage,
      };
    }
    default:
      return state;
  }
}
