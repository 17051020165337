import React, { useState, useEffect} from 'react';

const AboutTab = ({about, fetchContent}) => {
  const [state, setState] = useState([])

  useEffect(() => fetchContent('About'),[]);

  return (
    <div className="tab-container" dangerouslySetInnerHTML={{__html: about}}>
      {/* <p>
        7G's objective from Day 1 has been to provide a one-of-a-kind resource and
        launchpad for Independent Artists, Filmmakers, and Designers — one that
        also serves as a unique aggregator and discovery hub for knowledgeable
        fans and enthusiasts appreciative of original, independently-produced
        work. The goal of 7GreenLights™ is to empower Independent Artists to share
        their creations with far greater, far more relevant, populations of fans
        and enthusiasts than ever before.
      </p>
      <p>
        7G values original storytellers who are willing to take risks to craft and impart their visions and narratives.  Artists can include their work and media in a 7G VenueTM in order to be recognized, be featured, and to increase their discoverability.   Independent Artists, Filmmakers, and Designers can increase their visibility by listing content on A 7G VenueTM, ensuring to their target audience engages with the content when the users of the site sort and search for content of interest. We firmly
        believe that true fans of Independent Art, Film, and Design will
        appreciate the 7G platform, our mission, and the methods we develop and
        employ to improve the playing field for dedicated Independent Artists,
        Filmmakers, and Designers who are striving to generate visibility for
        their work on their own — without any labels or middlemen.
      </p>
      <p><b>Thank you for joining us on this journey!</b></p> */}
    </div>
  );
}
export default AboutTab;
