import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SponsorshipList from './SponsorshipList'

const VenueSponsorshipTab = (props) => {

  const {user, sponsorships } = props
  return (
    <div className="tab">
      <SponsorshipList sponsorships={sponsorships} user = {user}/>
    </div>
  );
};

VenueSponsorshipTab.propTypes = {
  user: PropTypes.object,
  sponsorships: PropTypes.object,
};

export default VenueSponsorshipTab;
