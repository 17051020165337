import React from 'react'; import PropTypes from 'prop-types';

import TabHeader from './../Profile/TabHeader';
import RenderTab from './Tabs/renderTab';
import Tooltip from 'components/Tooltip/Tooltip';
import { tooltipIds, profileTabs } from 'constants';

const PaymentTabs = ({ user, switchTab, selectedTab, isMine, updateUser }) => {
  return (
    <div className="profile-content-container">
      <div className="profile-tab-headers has-tooltip">
        <TabHeader
          switchTab={switchTab}
          name="PAYMENT DETAILS" id={profileTabs.paymentDetails}
          selectedTab={selectedTab}
          isMine={isMine}
        />
        <TabHeader
          switchTab={switchTab}
          name="PAYMENT DONE"
          id={profileTabs.paymentDone}
          selectedTab={selectedTab}
        />
        <TabHeader
          switchTab={switchTab}
          name="PAYMENT RECEIVED"
          id={profileTabs.paymentReceived}
          selectedTab={selectedTab}
        />        
      <Tooltip tooltipId={tooltipIds.myProfile} />
      </div>
      <div className="divider-line" />
      <RenderTab
        selectedTab={selectedTab}
        user={user}
        isMine={isMine}
        updateUser={updateUser}
      />
    </div>
  );
};

PaymentTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  switchTab: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  paymentDetails: PropTypes.array,
  paymentDone: PropTypes.array,
  paymentReceived: PropTypes.array,
  user: PropTypes.object.isRequired,
  isMine: PropTypes.bool.isRequired,
};

export default PaymentTabs;
