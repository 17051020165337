import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../utils/Modal';
import xIcon from '../App/img/x-dark.png';
import Invite from './Invite';
import { acceptInvite } from '../../api/arenaApi';

const InviteModal = ({
  isInitOpen,
  title,
  message,
  toggleModal,
  inviteData = {},
  inviteModal = false,
  isQueuedModal = false,
  callBack
}) => {
  const handleOpen = () => {
    toggleModal(!isInitOpen);
  };

  const onAccept = () => {
    callBack(!isInitOpen);
  };
  

  return (
    <Modal
      isOpen={isInitOpen}
      className={`message-modal`}
      overlayClassName={`auth-popup-overlay`}
      style={{ height: '400px' }}
    >
      <div className="message-container">
        <button>
          <img src={xIcon} onClick={handleOpen} alt="close" />
        </button>
        <h1>{title}</h1>
        <p style={{ marginTop: '5px' }}>{message}</p>

        {isQueuedModal ? (
          <>
            <button
              onClick={onAccept}
              style={{
                background: 'rgb(53, 172, 54)',
                color: 'black',
                width: '120px',
                height: '25px',
                marginTop: '5px'
              }}
            >
              Request Invite
            </button>
           
          </>
        ) : inviteModal ? (
          <>
            <button
              onClick={onAccept}
              style={{
                background: 'rgb(53, 172, 54)',
                color: 'black',
                width: '120px',
                height: '25px',
                marginTop: '5px'
              }}
            >
              Accept Invite
            </button>
            <Invite
              onAccept={onAccept}
              data={inviteData}
              type="button"
              ishost={false}
              fromnotification={false}
            />
          </>
        ) : (
          <div>
            <button
              type="button"
              className="dark-button"
              onClick={handleOpen}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

InviteModal.propTypes = {
  isInitOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  inviteData: PropTypes.object,
  inviteModal: PropTypes.bool,
  isQueuedModal: PropTypes.bool
};

export default InviteModal;
