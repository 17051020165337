import React from 'react';
import {  useSelector } from 'react-redux';


const NotificationBadge = () => {
    const unreadNotificationCount = useSelector(state => state.notificationsReducer.unreadNotificationCount);
  
  return (
    <div style={{color:"white", marginLeft:"35px"}}>
      <span>{unreadNotificationCount}</span>
    </div>
  );
};


export default NotificationBadge;
