import React from 'react';
import PropTypes from 'prop-types';
import TabHeader from './TabHeader';
import RenderTab from './Tabs/renderTab';
import Tooltip from 'components/Tooltip/Tooltip';
import { tooltipIds, profileTabs } from 'constants';

const ProfileTabs = ({ user, switchTab, selectedTab, isMine, updateUser,
  blastsTmData,myNotesData, greenlightUser, greenlitPeople, greenlightPeople, greenlightMedia, media, sponsorships, venuePostedList, venueRequest }) => {

    // console.log(" notes profileTabs",myNotesData);
console.log(venueRequest, "hey2342355454")
    return (
    <div className="profile-content-container" style={{maxWidth:"100rem"}}>
      <div className="profile-tab-headers has-tooltip">
        {
          !isMine &&
          <TabHeader
            switchTab={switchTab}
            name="Media" id={profileTabs.media}
            selectedTab={selectedTab}
          />
        }
        <TabHeader
          switchTab={switchTab}
          name="Bio" id={profileTabs.bio}
          selectedTab={selectedTab}
          isMine={isMine}
        />
        <TabHeader
          switchTab={switchTab}
          name="Blasts™"
          id={profileTabs.blasts}
          selectedTab={selectedTab}
        />
        {/* <TabHeader
          switchTab={switchTab}
          name="GreenLight® People"
          id={profileTabs.greenlightPeople}
          selectedTab={selectedTab}
        /> */}
        {/* <TabHeader
          switchTab={switchTab}
          name="GreenLight® People"
          id={profileTabs.greenlitPeople}
          selectedTab={selectedTab}
        /> */}
        {/* <TabHeader
          switchTab={switchTab}
          name="Notes™ to Content"
          id={profileTabs.notes}
          selectedTab={selectedTab}
        /> */}
        <TabHeader
          switchTab={switchTab}
          name="Venues® Posted to"
          id={profileTabs.venuePostedTo}
          selectedTab={selectedTab}
        />
        <TabHeader
          switchTab={switchTab}
          name="Venues® Sponsored"
          id={profileTabs.venueSponsorship}
          selectedTab={selectedTab}
        />
         <TabHeader
          switchTab={switchTab}
          name="Venues® Request"
          id={profileTabs.venueRequest}
          selectedTab={selectedTab}
        />
      <Tooltip tooltipId={tooltipIds.myProfile} />
      </div>
      <div className="divider-line" />
      <RenderTab
        selectedTab={selectedTab}
        user={user}
        isMine={isMine}
        updateUser={updateUser}
        blastsTmData={blastsTmData.reverse()}
        myNotesData={myNotesData}
        greenlightPeople={greenlightPeople}
        greenlitPeople={greenlitPeople}
        greenlightUser={greenlightUser}
        greenlightMedia={greenlightMedia}
        media={media}
        sponsorships={sponsorships}
        venuePostedList={venuePostedList}
        venueRequest={venueRequest}
      />
    </div>
  );
};

ProfileTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  switchTab: PropTypes.func.isRequired,
  greenlightUser: PropTypes.func.isRequired,
  greenlightPeople: PropTypes.array,
  greenlitPeople: PropTypes.array,
  blastsTmData: PropTypes.array,
  greenlightMedia: PropTypes.func.isRequired,
  media: PropTypes.array,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isMine: PropTypes.bool.isRequired,
};

export default ProfileTabs;
