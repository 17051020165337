import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FormField = ({
    input,
    label,
    type,
    meta: { touched, error },
    accept,
    id,
    value,
    maxLength,
    allowedFileTypesLabel,
    options,
    ...rest
}) => {
    const [charCount, setCharCount] = useState(input.value ? input.value.length : 0);
    const [charCountMsg, setCharCountMsg] = useState("Characters Only");
    const handleTextArea = (e) => {
        const text = e.target.value;
        if (text.length <= maxLength) {
            input.onChange(text);
            setCharCount(text.length);
            if (text.length === maxLength) {
                setCharCountMsg("Characters Reached");
            } else {
                setCharCountMsg("Characters Remaining");
            }
        }
        if (text.length === 0) {
            setCharCountMsg("Characters Only");
        } else {
            setCharCountMsg("Characters Remaining");
        }
    };
    const charCountStyle = {
        color: charCount >= 200 ? 'red' : 'black',
    };
    
    const renderInput = () => {
        switch (type) {
            case 'text':
                return (
                    <div className={`field-container`} style={{ marginTop: "1rem" }}>
                        <div className="field-title-container">
                            <label className={`field-title dark-color ${touched && error ? "error-color" : ""}`}>
                                {label}
                            </label>
                            {touched && error && (
                                <i className="field-error strong error-color">{error}</i>
                            )}
                        </div>
                        <input
                            {...input}
                            type="text"
                            className="field-text"
                            style={{ borderBottom: "1px solid #1d2535", color: "#000" }}
                            placeholder={rest.placeholder}

                        />
                    </div>
                );
            case 'textarea':
                return (
                    <>
                        <div className={`field-container`} style={{ marginTop: "1rem" }}>
                            <div className="field-title-container">
                                <label className={`field-title dark-color ${touched && error ? "error-color" : ""}`}>
                                    {label}
                                </label>
                                {touched && error && (
                                    <i className="field-error strong error-color">{error}</i>
                                )}
                            </div>
                            <textarea
                                {...input}
                                rows={4}
                                cols={50}
                                className="field-text"
                                style={{ borderBottom: "1px solid #1d2535", color: "black" }}
                                placeholder={rest.placeholder}
                                onChange={handleTextArea}
                                maxLength={maxLength}
                                {...rest}
                            />
                            <div className="char-count" style={{ marginTop: "1rem", display: "flex", justifyContent: 'end', ...charCountStyle }}>
                                {maxLength - charCount} {charCountMsg}
                            </div>
                        </div>
                    </>
                );
            case 'file':
                return (
                    <>
                        <div className={`field-container`} style={{ marginTop: '1rem', display: "flex" }}>
                            <div>
                                <div className="field-title-container" style={{ marginBottom: '1rem' }}>
                                    <label className={`field-title dark-color ${touched && error ? 'error-color' : ''}`}>
                                        {label}
                                    </label>
                                    {touched && error && (
                                        <i className="field-error strong error-color">{error}</i>
                                    )}
                                </div>
                                <input
                                    onChange={handleImageChange}
                                    onBlur={adaptFileEventToValue(input.onBlur)}
                                    type="file"
                                    accept={accept}
                                    {...rest}
                                    style={{ marginBottom: '2px' }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <label className="allowed-file-types-label" style={{ color: 'black' }}>
                                        {allowedFileTypesLabel}
                                    </label>

                                </div>

                            </div>


                        </div>
                        {input.value && input.value instanceof File && (
                            <img src={URL.createObjectURL(input.value)} alt="Image Preview" style={{ width: '75px', marginTop: "2rem" }} />
                        )}

                    </>
                );
            case 'radio':
                return (
                    <>
                        <div className={`field-container`} style={{ marginTop: "" }}>
                            <div style={{ display: 'flex', width: "120px", marginTop: "-5px" }}>
                                <input
                                    type="radio"
                                    {...input}
                                    id={id}
                                    value={value}
                                />
                                <label className={`field-title dark-color }`} style={{ width: "200%", marginLeft: "-30px" }}>{label}</label>
                            </div>

                        </div>


                    </>

                );
            case 'label':
                return (
                    <>
                        <div className={`field-container`} style={{ marginTop: "1rem", }}>
                            <div className="field-title-container" style={{ marginBottom: "1rem" }}>
                                <label className={`field-title dark-color ${touched && error ? "error-color" : ""}`} >
                                    {label}
                                </label>
                                {touched && error && (
                                    <i className="field-error strong error-color">{error}</i>
                                )}
                            </div>
                        </div>
                    </>
                );                
            default:
                return null;
        }
    };

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (allowedFileTypes.includes(selectedFile.type)) {
                input.onChange(selectedFile);
            } else {
                input.onChange(null);
                alert('Invalid file type. Please upload a JPEG, PNG, or JPG file.');
            }
        }
    };

    const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);
    return (
        <>
            {renderInput()}
        </>
    );
};

FormField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'textarea', 'file', 'radio', 'dropdown']),
    meta: PropTypes.object.isRequired,
    accept: PropTypes.string,
    allowedFileTypesLabel: PropTypes.string,
    maxLength: PropTypes.number,
};

export default FormField;
