import api from './apiService.js';

export const login = (loginData) => {
  console.log("232323232323",loginData)

  return api.post('Users/login?include=user', loginData);
};

export const loginWithFacebook = (response) => {
  return api.post('users/facebook', {
    name: response.name,
    email: response.email,
    birthday: response.birthday,
    facebookId: response.userID,
    reauthorizeRequiredIn: response.reauthorize_required_in
   });
};

export const loginWithInstagram = (accessToken) => {
  return api.get(`https://api.instagram.com/v1/users/self/?access_token=${accessToken}`)
  .then((response) => {
    if(response && response.data ){
      return api.post('users/instagram', {
        name: response.data.full_name,
        username: response.data.username,
        about: response.data.bio,
        instagramId: response.data.id,
        profilePicture: response.data.profile_picture
      });
    } else {
      alert("Failed to login.")
    }
  })
  //return api.post('users/instagram', { access_token: accessToken });
};

export const logout = (accessToken) => {
  return api.post('Users/logout?access_token='+accessToken);
};
