import React, { useState } from "react";
import { saveVenueRequest } from "../../api/venueRequestApi";
import Spinner from "../../utils/Loader";


const ConfirmDetails = ({ submittedData,
  meta,
  changeSelectedTabToVenue,
  closeModal,
  venueForm,
  clearSubmittedData,
  userDetails
}) => {

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [submiting, setSubmiting] = useState(false)
  const [loaderIsLoading, setLoaderIsLoading] = useState(false);

  const venueRequestFieldLables = {
    image: 'Venue® Image',
    name: 'Venue® Name',
    type: 'Venue® Type',
    description: 'Venue® Description',
    invitedUserIds: 'Invite Users'
  };

  const handleEditClick = () => {
    changeSelectedTabToVenue("Venue®")
  }


  const handleConfirmClick = async () => {
    if (!submittedData) {
      return;
    }
    setSubmiting(true)
    try {
      setLoaderIsLoading(true)
      if (submittedData.has("invitedUserIds")) {
        const inviteUsers = submittedData.get("invitedUserIds");
        const parsedInvitedUserIds = JSON.parse(inviteUsers);
        submittedData.set("invitedUserIds", JSON.stringify(parsedInvitedUserIds));
        submittedData.set("venueRequestedBy", JSON.stringify(userDetails));
        saveVenueRequest(submittedData).then((response) => {
          setIsConfirmed(true);
          setSubmiting(false);
          closeModal();
          venueForm.clearForm();
          clearSubmittedData("")
          setLoaderIsLoading(false)
        });
      }

    } catch (error) {
      console.error("Fetch error:", error);
    }

    venueForm.clearForm();
    clearSubmittedData("")
  };

  if (!submittedData) {
    return null;
  }



  return (
    <div style={{ marginTop: "10px" }}>
      {loaderIsLoading && <Spinner />}
      <ul>
        {[...submittedData.entries()].map(([key, value]) => (
           key !== "venueRequestedBy" && (
          <li key={key} style={{ display: "flex", marginTop: "25px", width: "100%" }}>
            <label
              className={`field-title dark-color ${meta && meta.touched && meta.error ? "error-color" : ""
                }`}
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {venueRequestFieldLables[key] || key}:
            </label>
            {key === "invitedUserIds" ? (
              <label
                className={``}
                style={{
                  color: "black",
                  fontSize: "15px",
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                  inlineSize: "150px",
                  overflowWrap: "anywhere",
                }}
              >
                {JSON.parse(value).map((invite) => invite.email).join(", ")}
              </label>
            ) : (
              value instanceof File ? (
                <img
                  src={URL.createObjectURL(value)}
                  alt={value.name}
                  width={100}
                  style={{ marginLeft: "5px" }}
                />
              ) : (
                <label
                  className={``}
                  style={{
                    color: "black",
                    fontSize: "15px",
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginLeft: "10px",
                    inlineSize: "150px",
                    overflowWrap: "anywhere",
                  }}
                >
                  {value}
                </label>
              )
            )}
          </li>
           )
        ))}
      </ul>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {!isConfirmed && (
          <button
            disabled={submiting}
            name="button"
            className={`form-button greenlight-btn`}
            onClick={handleConfirmClick}
          >
            Confirm
          </button>
        )}
        <button
          type="button"
          className={`form-button `}
          onClick={handleEditClick}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default ConfirmDetails;
