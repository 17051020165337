import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../utils/Modal";
import { TabPanel, useTabs } from "react-headless-tabs";
import xIcon from "../App/img/x-dark.png";
import { TabSelector } from "../Arena/TabSelector";
import VenueForm from "./venueForm";
import ConfirmDetails from "./confirmDetails";
import ShowVenueList from "./showVenueList";
import venueForm from "./venueForm";

const VenueRequestModalComponent = ({
  userDetails,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [formDataFromChild, setFormDataFromChild] = useState(null);
  const handleFormSubmit = (formData) => {
    setFormDataFromChild(formData);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [selectedTab, setSelectedTab] = useTabs(["Venue®", "Confirm", "VenueList"]);
  const changeSelectedTab = (tabName) => {
    setSelectedTab(tabName);
  };
  const changeSelectedTabToVenueTab = (tabName) => {
    setSelectedTab(tabName);
  };

  const clearForm = () => {
    // Reset the form fields or perform necessary actions to clear the form
    // Example: Resetting form values using redux-form reset function
    // Replace 'yourReduxFormName' with the actual name of your redux-form
    props.reset('yourReduxFormName');
  };

  const venueFormComponent = (
    <VenueForm
      onFormSubmit={handleFormSubmit}
      changeSelectedTab={changeSelectedTab}
      clearForm={clearForm} 
    />
  );



  return (
    <>
      <button
        className={`form-button greenlight-btn`}
        onClick={openModal}
        style={{
          background: "rgb(53, 172, 54)",
          color: "black",
          width: '120px',
          height: '25px',
          position: 'fixed',
          bottom: '20%',
          right: '10px',
          border: 'none',
          bordeRadius: "5px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          borderRadius: '2px',
          cursor: 'pointer',
          transition: 'background 0.3s, color 0.3s',
        }}
      >
        Venue Request
      </button>

      <Modal
        isOpen={isOpen}
        className={`sponsor-modal`}
        overlayClassName={`auth-popup-overlay`}

      >
        <div className="message-container">
          <span
            onClick={closeModal}
            title="Close"
            style={{
              position: "absolute",
              top: "3px",
              right: "4px",
              background: "#ff00006b",
              padding: "6px 8px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <img src={xIcon} alt="Close" />
          </span>
          <h1 style={{ margin: "1.2rem" }}>{`Request Venue®`}</h1>
          <nav className="tab-container flex border-b border-gray-300" style={{ width: '122%' }}>
            <TabSelector
              isActive={selectedTab === "Venue®"}
              onClick={() => {
                setSelectedTab("Venue®");
              }}
            >
              Venue® Details
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "Confirm"}
              onClick={() => {
                if (!handleFormSubmit) {
                  setSelectedTab("Confirm");
                }
                else {
                  setSelectedTab("Venue®")
                }
              }}
            >
              Confirm Details
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "VenueList"}
              onClick={() => {
                setSelectedTab("VenueList")
              }}
            >
              Venue List
            </TabSelector>
          </nav>
          <div className="tab-data-container" style={{ marginTop: "25px" }}>
            <TabPanel hidden={selectedTab !== "Venue®"}>
              <VenueForm
                onFormSubmit={handleFormSubmit}
                changeSelectedTab={changeSelectedTab}

              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "Confirm"}>
              <ConfirmDetails
                closeModal={closeModal}
                submittedData={formDataFromChild}
                clearSubmittedData={setFormDataFromChild}
                changeSelectedTabToVenue={changeSelectedTabToVenueTab}
                venueForm={venueFormComponent}
                userDetails={userDetails}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "VenueList"} >
              <ShowVenueList changeSelectedTabToVenue={changeSelectedTabToVenueTab} />
            </TabPanel>
          </div>
        </div>
      </Modal>
    </>
  );
};

VenueRequestModalComponent.propTypes = {
 
};

export default VenueRequestModalComponent;
