import React from 'react';
import PropTypes from 'prop-types';
import ArenaBoxCover from './ArenaBoxCover';
import ArenaCounter from './ArenaCounter';
import { browserHistory } from 'react-router';
import { tooltipIds } from 'constants';
import Tooltip from 'components/Tooltip/Tooltip';

const ArenaBox = ({ arena, greenlightArena, shouldDisplayTooltip, user }) => {
  const hasTooltipClass = shouldDisplayTooltip ? 'has-tooltip' : '';
  if(arena.id == '6375cf1c0bc94705111408e4'){

    console.log('ArenaId = ', arena.id, arena )
  }

  return (
    <div
      className={`arena-container pointer ${hasTooltipClass}`}
      onClick={() => browserHistory.push(`/arena/${arena.id}`)}
    >
      {
        shouldDisplayTooltip &&
        <Tooltip tooltipId={tooltipIds.homeGreenlight} />
      }
      <ArenaBoxCover
        greenlit={arena.greenlit}
        greenlightCount={arena.greenlightsCount}
        image={arena.image}
        arenaId={arena.id}
        greenlightAction={() => greenlightArena(arena.id)}
        user={user}
        arena={arena}
      />

      <div className="arena-name pointer"><label>{arena.name}</label></div>
      <div className="arena-divider-line" />
      <ArenaCounter
        endDate={arena.endDate}
        arenaId={arena.id}
      />
    </div>
  );
};

ArenaBox.propTypes = {
  arena: PropTypes.object.isRequired,
  greenlightArena: PropTypes.func.isRequired,
  shouldDisplayTooltip: PropTypes.bool,
};

export default ArenaBox;
