import * as types from '../actions/actionTypes';
import * as helpers from './helpers';

const initialState = {
  arenas: [],
  arenasPage: 1,
  recentArenas: [],
  postedArenas: [],
  postedVenues:[],
  earnedRevenue: 0,
  currentArena: {
    id: 0,
    greenlightsCount: 0,
    mediaContents: [],
  },
  currentArenaMediaPage: 1,
  displayPostToArena: false,
  mediasToPost: [],
  currentMyMediaPage: 1,
  hasMoreArenas: true,
  loadMoreArenas: true
};

export default function arenasReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.STOP_ARENA_LOADING: {
      return {
        ...state,
        hasMoreArenas: false
      };
    }
    case types.RESET_ARENA_LOADING: {
      return {
        ...state,
        hasMoreArenas: true
      };
    }
    case types.STOP_MORE_ARENA: {
      return {
        ...state,
        loadMoreArenas: false
      };
    }
    case types.FETCH_ARENAS_SUCCESS: {
      const { page, filtered, arenas } = action;
      console.log("filteredfiltered",arenas)
      let arenasArray = state.arenas.slice();
      let nextArenaPage = state.arenasPage + 1;
      let recentArenas = state.recentArenas;
      let isFirst = (page == 1 && !filtered);

      if ((arenas && arenas.length === 0 ) || filtered) {
        nextArenaPage = state.arenasPage;
      }

      if (page === 1 && filtered) {
        arenasArray = arenas.venues;
      } else if (isFirst) {
        arenasArray = arenas.venues;
      } else {
        arenasArray = arenasArray.concat(arenas.venues);
      }

      return {
        ...state,
        arenas: arenasArray,
        arenasPage: nextArenaPage,
        recentArenas: arenas.mostGreenlightVenues,
      };
    }
    case types.FETCH_FEATURED_ARENAS_SUCCESS: {
      let { arenas } = action;
      let arenasArray = state.arenas.slice();
      arenasArray = arenas.concat(arenasArray);

      return {
        ...state,
        arenas: arenasArray,
      };
    }
    case types.FETCH_ARENAS_USER_POSTED_SUCCESS: {
      let { arenas } = action;
      const maxAllowed = 3;
      (arenas && arenas.length > maxAllowed) && (arenas = arenas.splice(0, maxAllowed));

      let newRecentArenas = state.recentArenas.slice();
      let newArenas = state.arenas.slice();
      let lacking = maxAllowed - arenas.length;

      if (lacking > 0) {
        let moving = newArenas.splice(0, lacking);
        newRecentArenas = newRecentArenas.concat(moving);
      }

      return {
        ...state,
        postedArenas: arenas,
        arenas: newArenas,
        recentArenas: newRecentArenas,
      };
    }

    case types.FETCH_VENUES_USER_POSTED_SUCCESS: {
      let { arenas, userId } = action.data;
      // Remove duplicate records
      let postedVenues = [...new Map(arenas.map(item => [item['id'], item])).values()]
      //Set Earned Revenue of user
      let myRevenue = helpers.calculateMyRevenue(postedVenues, userId)
      return {
        ...state,
        postedVenues: postedVenues,
        earnedRevenue: myRevenue
      };
    }

    case types.RESET_ARENAS_PAGE: {
      return {
        ...state,
        arenas: initialState.arenas,
        arenasPage: initialState.arenasPage,
        currentArenaMediaPage: initialState.currentArenaMediaPage,
        currentMyMediaPage: initialState.currentMyMediaPage,
        hasMoreArenas: initialState.hasMoreArenas
      };
    }
    case types.GREENLIGHT_ARENA_REQUEST:
    case types.GREENLIGHT_ARENA_FAILURE: {
      let id = action.id;

      let newArenas = helpers.greenlightInArrayById(state.arenas, id);
      let newRecentArenas =
        helpers.greenlightInArrayById(state.recentArenas, id);
      let newPostedArenas =
        helpers.greenlightInArrayById(state.postedArenas, id);

      let newCurrentArena = { ...state.currentArena };
      if (state.currentArena.id === id) {
        newCurrentArena = helpers.greenlight(newCurrentArena);
      }

      return {
        ...state,
        arenas: newArenas,
        recentArenas: newRecentArenas,
        postedArenas: newPostedArenas,
        currentArena: newCurrentArena,
      };
    }
    case types.FETCH_ARENA_SUCCESS: {
      let arena = action.arena;
      if (!arena.MediaContents) {
        arena.mediaContents = [];
      } else {
        arena.mediaContents = arena.MediaContents;
      }

      return {
        ...state,
        currentArena: action.arena,
      };
    }
    case types.TOGGLE_POST_TO_ARENA: {
      return {
        ...state,
        displayPostToArena: !state.displayPostToArena,
      };
    }
    case types.POST_MEDIA_ARENA_REQUEST: {
      return {
        ...state,
        displayPostToArena: false,
      };
    }
    case types.FETCH_ARENAS_MEDIA_SUCCESS: {
      const { currentArena } = state;
      let { page, media, filtered } = action;let nextArenaMediaPage =
        state.currentArenaMediaPage +
        ((media.length === 0 || filtered) ? 0 : 1);

      let newCurrentArena = { ...currentArena };
      if (page !== 1) {
        media = newCurrentArena?.mediaContents?.concat(media.MediaContents);
      }
      newCurrentArena.mediaContents = media?.MediaContents;

      return {
        ...state,
        currentArena: newCurrentArena,
        currentArenaMediaPage: nextArenaMediaPage,
      };
    }
    case types.GREENLIGHT_MEDIA_TOGGLE:
    case types.GREENLIGHT_MEDIA_REQUEST: {
      const id = action.id;
      console.log("GREENLIGHT_MEDIA_REQUEST", state)
      let newCurrentArena = { ...state.currentArena };
      let mediaContents = newCurrentArena.mediaContents;
      mediaContents = helpers.greenlightInArrayById(mediaContents, id);
      newCurrentArena.mediaContents = mediaContents;

      let mediaContent = newCurrentArena.mediaContents.find(x => x.id === id);
      if (mediaContent) {
        mediaContent.greenlit ?
        newCurrentArena.mediaContents.greenlightsCount++ :
        newCurrentArena.mediaContents.greenlightsCount-- ;
      }

      return {
        ...state,
        currentArena: newCurrentArena,
      };
    }
    case types.ADD_MEDIA_TO_POST: {
      let newMediasToPost = state.mediasToPost.slice();
      newMediasToPost.push(action.id);

      return {
        ...state,
        mediasToPost: newMediasToPost,
      };
    }
    case types.REMOVE_MEDIA_TO_POST: {
      let newMediasToPost = state.mediasToPost.slice();
      newMediasToPost = newMediasToPost.filter(x => x !== action.id);

      return {
        ...state,
        mediasToPost: newMediasToPost,
      };
    }
    case types.POST_MEDIA_ARENA_SUCCESS: {
      let newCurrentArena = { ...state.currentArena };
      let newMediaContent = action.medias.concat(newCurrentArena.mediaContents);
      newCurrentArena.mediaContents = newMediaContent;
      newCurrentArena.mediaContentsCount++;

      return {
        ...state,
        mediasToPost: [],
        displayPostToArena: false,
        currentArena: newCurrentArena,
      };
    }
    case types.POST_MEDIA_ARENA_FAILURE: {
      return {
        ...state,
        mediasToPost: [],
        displayPostToArena: false,
      };
    }
    case types.GREENLIGHT_USER_TOGGLE:
    case types.GREENLIGHT_USER_REQUEST: {
      let id = action.id;
      console.log('id', id)
      let newCurrentArena = { ...state.currentArena };
      let mediaContents = newCurrentArena.mediaContents.slice();
      console.log('mediaContents', mediaContents)
      mediaContents.map((media, index) => {
        if (media.user.id == id) {
          media.user = helpers.greenlight(media.user);
          mediaContents[index] = { ...media };
        }
      });
      newCurrentArena.mediaContents = mediaContents;

      return {
        ...state,
        currentArena: newCurrentArena,
      };
    }
    case types.GREENLIGHT_NOTE_REQUEST:
    case types.GREENLIGHT_NOTE_TOGGLE: {
      const id = action.id;
      let newCurrentArena = { ...state.currentArena };
      let newMediaArray = newCurrentArena.media_contents.slice();
      newMediaArray.forEach(media => {
        let notesArray = media.featured_notes;
        notesArray = helpers.greenlightInArrayById(notesArray, id);
        media.featured_notes = notesArray;
      });

      newCurrentArena.media_contents = newMediaArray;

      return {
        ...state,
        currentArena: newCurrentArena,
      };
    }
    case types.FETCH_MEDIA_SUCCESS: {
      let { medias } = action;

      let newCurrentMyMediaPage =
        state.currentMyMediaPage +
        ((medias.length === 0) ? 0 : 1);

      return {
        ...state,
        currentMyMediaPage: newCurrentMyMediaPage,
      };
      
    }
    
    default:
      return state;
  }
}
