import * as types from '../actions/actionTypes';

const initialState = {
  sponsorships: [],
  arenaSponsors: {},

};

export default function sponsorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_ARENA_SPONSORS_SUCCESS: {
      const { arenaId, sponsors } = action;
      const arenaSponsors = {...state.arenaSponsors}
      arenaSponsors[arenaId] = sponsors
      return {
        ...state,
        arenaSponsors: arenaSponsors,
      };
    }

    case types.FETCH_SPONSORSHIPS_SUCCESS: {
      const sponsorships = action.sponsorships;
      return {
        ...state,
        sponsorships: sponsorships,
      };
    }



    default:
      return state;
  }
}
