import React from 'react'; import PropTypes from 'prop-types';

import Greenlight from '../../Greenlight/Greenlight';

import Invite from '../../Arena/Invite';

const ArenaBoxCover = ({ image, greenlit, greenlightCount, greenlightAction, arenaId, user , arena}) => {
  let backgroundStyle = {
    backgroundImage: `url(${image})`,
  };
  console.log('ArenaID = ', arenaId, ' Is Green ', greenlit)
  const IsInvited =  ({userId, arenaData}) => {
    const invitedUserIds = arenaData.invitedUserIds;
    if(invitedUserIds?.length > 0) {
      const userInfo = invitedUserIds.filter( (invitedUser) =>  invitedUser.id === userId )
      if(userInfo?.length > 0) {
        return(
          <Invite
            data={{venueId:arenaId, invitedTo:user.id}}
            type="link"
            ishost={false}
            fromnotification={false}
          />
        )
      } else {
        return false
      }
    } else {
      return false
    }
  }
//image && backgroundStyle
  return (
    <div className="arena-cover" style={(image) ? backgroundStyle : null}>
      <div className={`arena-gradient ${greenlit ? 'gradient-greenlit' : ''}`}>
        <IsInvited userId={user.id} arenaData={arena}/>
        <Greenlight
          greenlit={greenlit}
          counter={greenlightCount}
          greenlightAction={greenlightAction}
        />
      </div>
    </div>
  );
};

ArenaBoxCover.propTypes = {
  image: PropTypes.string,
  greenlightCount: PropTypes.number,
  greenlit: PropTypes.bool,
  greenlightAction: PropTypes.func.isRequired,
};

export default ArenaBoxCover;
