import api from "./apiService.js";
import * as constants from "../constants";
import {
  applyFilters,
  applyArrayFilters,
  applySorting,
  paginate,
} from "./apiHelpers";

// import Api from "../../api/apiService";

export const acceptInvite = async (id, data) => {
  let url = `invites/${id}`;
  return api.patch(url, data);
};


export const fetchInvite = async (filter) => {
  let url = `invites?filter=${JSON.stringify(filter)}`;
  //let url = `invites/${id}`
  return api.get(url);
};

export const fetchArenaInfo = (id, data) => {

  if (id) {
    let url = `arenas/${id}`;
    return api.get(url);
  }

};
export const updateArenaInfo = async (id, data) => {
  try {
   await api.post(`arenas/updateArena/${id}`, data) 
  } catch (error) {
    console.log(error);
  }
}


export const fetchArena = (id, userId) => {
  let url = `arenas/${id}/mediaContetns/${userId}`;
  return api.get(url);
};

export const fetchDate = () => {
  let url = `Reports/date`;
  return api.get(url);
};

export const fetchArenas = (
  page,
  filters,
  sortType,
  sortOrder = 1,
  userId,
  perPage = constants.LANDING_ARENA_PAGE_SIZE
) => {
  // let filter = {
  //   "order": "endDate ASC", "fields" : ["id", "name", "image", "description", "isFeatured", "endDate", "oldId", "greenlightsCount"],
  //   "include": [{"relation": "Greenlights", "scope": {"where": {"userId": userId}}}]
  // };
  //if(userId){ 

  let filter = {}
  let url = `arenas/venues/${userId}`;
  url = paginate(url, page, perPage, filter);
  url = applySorting(url, sortType, filters);
  return api.get(url);
  //}
};

export const fetchArenasPostedByUser = (userId) => {
  let url = `ArenasMediaContents/posted-media/${userId}`;
  return api.get(url)
}

export const fetchFeaturedArenas = (page, perPage) => {
  let url = "arenas";
  let filter = {};
  url = paginate(url, page, perPage, filter);
  url = `${url}&filters[]=3`;

  return api.get(url);
};

export const fetchArenasMedia = (
  arenaId,
  userId,
  page,
  filterSortData,
  perPage = constants.LANDING_ARENA_PAGE_SIZE
) => {
  let url = `arenas/${arenaId}/mediaContetns/${userId}`;
  let filter = {};
  url = paginate(url, page, perPage, filter);
  url = applySorting(url, filterSortData?.sortType, filterSortData?.sortOrder);
  url = applyFilters(url, filterSortData?.filters);
  url = applyArrayFilters(
    url,
    constants.mediaFilters?.pops,
    filterSortData?.popIds
  );
  url = applyArrayFilters(
    url,
    constants.mediaFilters?.types,
    filterSortData?.typeIds
  );

  return api.get(url);
};

const getIds = (list) => {
  let ids = [];
  list.map((x) => ids.push(x.id));
  return ids;
};

export const postMediaToArena = (data) => {
  let dataToSend = [];
  data.medias.map((item) => {
    dataToSend.push({
      arenasId: data.arena_id,
      mediaContentsId: item.id,
      userId: data.userId,
    });
  });
  return api.post("arenasMediaContents", dataToSend);
};


