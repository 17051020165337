import React from 'react'; import PropTypes from 'prop-types';

import PaymentDetails from './PaymentDetails'

const PaymentDetailsTab = ({ user }) => {
  return (
    <div className="tab">
      <label className="coming-soon">Coming soon</label>
      {/* <PaymentDetails user={user}/> */}
    </div>
  );
};

PaymentDetailsTab.propTypes = {
  user: PropTypes.object.isRequired,  
};

export default PaymentDetailsTab;
