import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchDate } from "../../../api/arenaApi";

import profilePlaceholder from "../../App/img/profile-ph.png";
import { Link } from "react-router";

const SponsorshipList = ({ sponsorships }) => {
  const [currentDate, setCurrentDate] = useState();

  useEffect(() => {
    fetchCurrentDate();
  }, []);

  const fetchCurrentDate = async () => {
    setCurrentDate(await fetchDate());
  };
  const formatDate = (dt) => {
    return new Date(dt).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const formatMoney = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount)
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
          flexWrap: "wrap",
          padding: "1rem",
        }}
      >
        {sponsorships &&
          sponsorships.map((sponsorship, index) => (
            <div className="card" key={index}>
              <div className="img">
                <img src={sponsorship.logo || profilePlaceholder} />
              </div>
              <span className="title">
                <Link to={`/arena/${sponsorship.arenaId}`}>
                  <p className="title" title={sponsorship.venue.name} style={{fontWeight: 'bold'}}>{sponsorship.venue.name}</p>
                </Link>
              </span>
              <p className="info">Type: {sponsorship?.type}</p>
              { sponsorship?.payment?.amount > 0 ?
                <p className="info">
                  Amount: {formatMoney(sponsorship?.payment?.amount)}
                </p> :
                <p className="info">
                  Amount: $0
                </p>
              }
              {sponsorship?.venue?.endDate > currentDate?.object?.date ? (
                <div>
                  <div className="info">
                    <p>Venue Status: <label>Live</label></p>
                    <div className="show-date">
                      <p>Ends on : {formatDate(sponsorship?.venue?.endDate)}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="info">
                    Venue Status: <label>Archived</label>
                </p>

              )}


            </div>
          ))}
      </div>
      {/* <table>
        <thead>
          <tr>
            <td className="coming-soon">Logo</td>
            <td className="coming-soon">Venue</td>
            <td className="coming-soon">Type</td>
            <td className="coming-soon">Venue Status</td>
            <td className="coming-soon">Amount</td>
          </tr>
        </thead>
        <tbody>
          {sponsorships &&
            sponsorships.map((sponsorship, index) => (
              <tr key={index}>
                <div className="notes-list-item">
                  <img className="media-item-img" src={sponsorship.logo} />
                </div>
                <td className="table-label">
                  <Link to={`/arena/${sponsorship.arenaId}`}>
                    {sponsorship.venue.name}
                  </Link>
                </td>
                <td className="table-label">{sponsorship?.type}</td>

                <td className="table-label">
                  {console.log(
                    sponsorship?.venue?.endDate +
                      "vs " +
                      currentDate?.object?.date +
                      " result" +
                      sponsorship?.venue?.endDate <
                      currentDate?.object?.date
                  )}

                  {sponsorship?.venue?.endDate > currentDate?.object?.date ? (
                    <div>
                      <div>Active</div>
                      <div className="show-date">
                        Ends on : {formatDate(sponsorship?.venue?.endDate)}
                      </div>
                    </div>
                  ) : (
                    `Archived`
                  )}
                </td>

                <td className="table-label">${sponsorship?.payment?.amount}</td>
              </tr>
            ))}
        </tbody>
      </table> */}
    </>
  );
};

SponsorshipList.propTypes = {
  user: PropTypes.object,
  sponsorships: PropTypes.object,
};

export default SponsorshipList;
