import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { validate as validateForm } from "validate.js";
import FormField from "./FormFields";
import UserInput from "../Common/Tooltip/UserInput";
import { useForm } from "react-hook-form";
import { fetchAllUsers } from "../../api/userApi";
import { makeUniqueByValue } from "../../utils/makeUnique";



const RenderRadio = ({ input, label, id, value, meta: { touched, error }, }) => (
    <div style={{ display: 'flex', width: "120px", marginTop: "-5px" }}>

        <input
            type="radio"
            id={id}
            value={value}
            {...input}
        />
        <label htmlFor={id} className={`field-title dark-color }`} style={{ width: "200%", marginLeft: "-30px" }}>{label}</label>

    </div>
);


const validate = (values) => {
    const errors = {};
    if (!values["name"]) {
        errors["name"] = "Venue Name is required";
    }
    if (!values["arenaType"]) {
        errors["arenaType"] = "Venue® Type is required";
    }
    return errors;
};

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);
const required = (value) => (value ? undefined : "Required");


const VenueForm = (props) => {
    const {
        handleSubmit,
        submitting,
        onFormSubmit,
        changeSelectedTab,
    } = props;
    const [useProfile, setSetProfile] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const [formData, setFormData] = useState(new FormData());
    const [imagePreview, setImagePreview] = useState(null);
    const [tagsList, setTagsList] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const {
        formState: { errors, ...form },
        control,
        setError,
        clearErrors,
    } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });

    const toggleCheckBox = () => {
        setSetProfile(!useProfile);
    };
    const handleRadioChange = (value) => {
        setSelectedValue(value);
    };
    const handleFormSubmit = async (values) => {
        onFormSubmit(null)
        const newFormData = new FormData();
        for (const key in values) {
            formData.append(key, values[key]);
        }
        formData.append("invitedUserIds", JSON.stringify(selectedTags));
        setFormData(newFormData);
        changeSelectedTab("Confirm")
        onFormSubmit(null)
        onFormSubmit(formData);

        if (!values["name"]) {
            throw new SubmissionError({
                "name": "Venue Name is required",
            });
        }

        if (!selectedValue) {
            throw new SubmissionError({
                "arenaType": "Venue® Type is required",
            });
        }
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagePreview(previewURL);
        }
    };

    useEffect(() => {
        fetchAllUsers()
            .then((res) => {
                const newData = makeUniqueByValue(
                    res.map((r) => ({
                        label: `${r.name} (${r.email})`,
                        email: r.email,
                        value: r.name,
                        userId: r.id,
                        profilePicture: r.profilePicture,
                    }))
                );
                setTagsList(newData);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const loadOptions = async (searchTerm) => {

    };


    return (
        <div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>

                <Field
                    name="name"
                    component={FormField}
                    type="text"
                    label="Venue® Name"
                    placeholder="Enter Venue® Name"
                />
                <Field
                    type="label"
                    name="arenaType"
                    component={FormField}
                    label="Venue® Type"
                />
                <div style={{ display: "flex" }}>
                    <Field
                        id="Invite"
                        name="arenaType"
                        component={RenderRadio}
                        type="radio"
                        value="inviteonly"
                        label="Invite Only"
                    />
                    <Field
                        id="Open"
                        name="arenaType"
                        component={RenderRadio}
                        type="radio"
                        value="opentoall"
                        label="Open To All"
                    />
                </div>
                <Field
                    name="description"
                    component={FormField}
                    type="textarea"
                    label="Venue® Description"
                    placeholder="Describe Venue®"
                    rows="4"
                    maxLength={200}
                />


                <div style={{ display: 'flex' }}>
                    <Field
                        name="image"
                        component={FormField}
                        type="file"
                        accept=".jpeg, .jpg, .png"
                        allowedFileTypesLabel="Allowed files: JPEG, PNG, JPG"
                        label="Venue® Image"
                        onChange={handleImageChange}
                    />
                    {imagePreview && (
                        <img src={imagePreview} alt="Image Preview" style={{ width: '100px', }} />
                    )}
                </div>
                <Field
                    type="label"
                    name="invitedUserIds"
                    component={FormField}
                    label="Invited Users"
                />
                <UserInput
                    validation={{ required: true }}
                    control={control}
                    setError={setError}
                    clearErrors={clearErrors}
                    name="invitedUserIds"
                    value={selectedTags}
                    onChange={setSelectedTags}
                    loadOptions={loadOptions}
                    options={tagsList}
                    errors={errors.hosts}
                    defaultValue={selectedTags}
                    form={form}
                />


                <div className="form-group row">
                    <div className="col-sm-10" style={{ marginTop: "5rem" }}>
                        <button
                            disabled={submitting}
                            name="button"
                            type="submit"
                            className={`form-button greenlight-btn`}
                            onSubmit={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>

            </form>

        </div>
    );
};

export default reduxForm({
    form: "VenueForm",
    touchOnBlur: true,
    touchOnChange: true,
    validate,
})(VenueForm);
