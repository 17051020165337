import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import api from "../../api/apiService";
import FormField from "./FormField";
import { validate as validateForm } from "validate.js";
import { SubmissionError } from "redux-form";
import profilePlaceholder from "../App/img/profile-ph.png";

const validate = (values) => {
  if (values?.link) {
    let errors = validateForm(values, { link: { url: true } });
    return errors ? errors : {};
  }
};
const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);
const required = (value) => (value ? undefined : "Required");
const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, error, warning },
  label,
  ...props
}) => {
  return (
    <div className={`field-container`}>
      <div className="field-title-container">
        <label
          className={`field-title dark-color ${
            touched && error ? "error-color" : ""
          }`}
        >
          {label}
        </label>
        {touched && error && (
          <i className="field-error strong error-color">{error}</i>
        )}
      </div>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        name="attachment"
        className="field-text"
        {...props.input}
        {...props}
      />
    </div>
  );
};

const SponsorForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    type,
    fetchArenaSponsors,
    data,
    initialize,
    profile,
  } = props;
  const resetFileInput = () => {
    inputRef.current.value = null;
  };

  const [linkDefaultValue, setLinkDefaultValue] = useState(null);
  const [logoDefaultValue, setLogoDefaultValue] = useState(null);
  const [blastDefaultValue, setBlastDefaultValue] = useState(null);
  const [useProfile, setSetProfile] = useState(true);

  const toggleCheckBox = () => {
    setSetProfile(!useProfile);
  };

  useEffect(() => {
    if (Object?.keys(data).length > 0) {
      setLinkDefaultValue(data?.link);
      setLogoDefaultValue(data?.logo);
      setBlastDefaultValue(data?.blast);
      initialize({
        link: data?.link,
        attachment: data?.logo,
        blast: data?.blast,
        joining_terms: true,
      });
    }
    setLogoDefaultValue(profile ? profile : profilePlaceholder);
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="link"
        component={FormField}
        type="Link"
        label="Link*"
        placeholder="http://example.com"
      />
      {/* {type=='Primary Sponsor' && <Field name="blast" component={FormField} type="text" label="Blast" placeholder="Your message" />} */}
      <Field
        name="blast"
        component={FormField}
        type="text"
        label="Blast"
        placeholder="Your message"
      />

      <Field
        name="profile"
        component={FormField}
        type="checkbox"
        defaultChecked={useProfile}
        label="Use my Profile Image"
        onChange={toggleCheckBox}
      />

      {useProfile && (
        <div className="" style={{ textAlign: "left" }}>
          <img
            src={logoDefaultValue}
            placeholder={"Profile Image"}
            style={{
              width: "35px",
              height: "35px",
              border: "1px solid black",
              backgroundColor: "grey",
            }}
          />
        </div>
      )}

      {!useProfile && (
        <Field
          name="attachment"
          component={FileInput}
          type="file"
          label="Logo"
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      )}

      <Field
        name="joining_terms"
        component={FormField}
        type="checkbox"
        label="Joining Terms"
      />

      <div className="form-group row">
        <div className="col-sm-10">
          <button
            name="button"
            type="submit"
            className={`form-button greenlight-btn`}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "sponsorForm",
  touchOnBlur: true,
  touchOnChange: true,
  // persistentSubmitErrors: true,
  validate,
})(SponsorForm);
