import React from "react";
import PropTypes from "prop-types";

import { notificationTypes } from "constants";
import Invite from "../Arena/Invite";

const NotificationMessage = ({ notification }) => {
  let type = notification.type;
  let username = <b>{notification.sender && notification.sender.name}</b>;
  const isUnread = notification.read === "false";
  const textColor = isUnread ? 'rgb(53, 172, 54)' : '#1d2535';
  const fontWeight = isUnread ? 'bold' : 'normal';
  switch (type) {
    case notificationTypes.MEDIA_CONTENT_GL: {
      return (
        <label style={{ color: textColor, fontWeight}}>
          {username} GreenLit®{" "}
          <b>
            {notification.mediaContent && notification.mediaContent.name
              ? notification.mediaContent.name
              : null}
          </b>
        </label>
      );
    }
    case notificationTypes.NOTE_FEATURED: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          {username} featured <b>your Note™</b>
        </label>
      );
    }
    case notificationTypes.NOTE_GREENLIT: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          {username} GreenLit® the Note™ you uploaded to{" "}
          <b>
            {notification.mediaContent && notification.note.mediaContent.name}
          </b>
        </label>
      );
    }
    case notificationTypes.SIGN_UP: {
      return (
      <label style={{ color: textColor, fontWeight }}>
        Welcome to 7GreenLights® {username}
        <p>Congratulations for creating new account!</p>
      </label>
      );
    }
    case notificationTypes.NOTE_UPLOADED: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          {username} uploaded{" "}
          <b>
            a Note™ to{" "}
            {notification.note && notification.note.mediaContent
              ? notification.note.mediaContent.name
              : null}
          </b>
        </label>
      );
    }
    case notificationTypes.USER_GREENLIT: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          {username} GreenLit® <b>you</b>
        </label>
      );
    }
    case notificationTypes.MEDIA_CONTENT_REPORTED: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          {username} has reported your media content{" "}
          <b>{notification.mediaContent.name}</b>
        </label>
      );
    }

    case notificationTypes.VENUE_INVITE: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          You have received an Invite to Venue{" "}
          <Invite
            data={notification}
            type="link"
            ishost={false}
            fromnotification={true}
          />
        </label>
      );
    }
    case notificationTypes.VENUE_HOST: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          You are now host of Venue{" "}
        </label>
      );
    }

    case notificationTypes.VENUE_REQUEST: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          We have received your Venue Request{" "}   
        </label>
      );
    }


    case notificationTypes.VENUE_REQUEST_INVITE: {
      return (
        <label style={{ color: textColor,fontWeight }}>
          Your request is under review{" "}   
        </label>
      );
    }


    case notificationTypes.VENUE_ADMITED: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          You have accepted Invite{" "}  
        </label>
      );
    }

    case notificationTypes.VENUE_QUEUED: {
      return (
        <label style={{ color: textColor, fontWeight }}>
          You are in Queue please request Invite for post to Venue{" "}
        </label>
      );
    }
  }
};

NotificationMessage.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default NotificationMessage;
