import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ItemsCarousel from "react-items-carousel";
import mediaPlaceholder from "./img/media-ph.svg";
import profilePlaceholder from "../App/img/profile-ph.png";
import { getCurrentBlast } from "../../api/blastsApi";

const SponsorContainer = ({
  sponsors,
  arenaSponsors,
  arena,
  toggleSponsor,
  showHideSponsor,
  setSponsorTrue,
  user,
}) => {
  const causedUsers = arena?.causesUserIds;
  const arenaId = arena.id;
  const sponsorShips = arenaSponsors[arenaId];
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [activeItemIndex1, setActiveItemIndex1] = useState(0);
  const [causedItemIndex, setCausedItemIndex] = useState(0);
  const chevronWidth = 40;

  useEffect(() => {
    setTimeout(() => {
      setActiveItemIndex(activeItemIndex + 1);
      setActiveItemIndex1(activeItemIndex1 + 1);
      setCausedItemIndex(causedItemIndex + 1);
    }, 7000);
  }, [activeItemIndex, activeItemIndex1, causedItemIndex]);

  const [blast, setBlast] = useState("");

  // const setBlastMsg = (msg) => {
  //   blast == msg ? setBlast("") : setBlast(msg);
  //   setSponsorTrue();
  // };

  const getUserCurrentBlast = async (userId) => {
    const userCurrentBlast = await getCurrentBlast(userId);
    blast == userCurrentBlast[0]?.text
      ? setBlast("")
      : setBlast(userCurrentBlast[0].text);
    setSponsorTrue();
  };

  return (
    <>
      {showHideSponsor ? (
        <div
          className="blast-input"
          style={{
            border: "none",
            textAlign: "center",
            alignContent: "center",
            width: "100%",
            marginLeft: "2%",
            marginRight: "2%",
          }}
        >
          <marquee scrollAmount="4">
            <h1>{blast}</h1>
          </marquee>
        </div>
      ) : null}
      <div
        className="arena-sponsors"
        style={{
          display: "Block",
          marginBottom: 15,
          alignSelf: "center",
        }}
      >
        <div
          style={{
            width: 150,
            padding: `0 ${chevronWidth}px`,
            marginBottom: 2,
            marginLeft: 85,
          }}
        >
          <div
            className="blast-input"
            style={{
              border: "none",
              height: "100%",
              width: "150%",
              alignSelf: "center",
            }}
          ></div>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={2}
            gutter={4}
            outsideChevron
            chevronWidth={chevronWidth}
            infiniteLoop={true}
          >
            {sponsorShips?.map((k, offset) => {
              if (k.type === "Primary" && k?.payment?.status== 'succeeded') {
                // console.log(k.logo.split('.').pop()=="jpg"?'true':'false', "******************************************");
                return (
                  <div
                    // onClick={() => setBlastMsg(k.blast)}
                    onClick={() => getUserCurrentBlast(k.user.id)}
                    style={{
                      height: 70,
                      width: 70,
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      marginBottom: 15,
                    }}
                    key={offset}
                  >
                    <a
                      href={k?.link}
                      target="_blank"
                    >
                    <img
                      className="sponsor-logo"
                      title={k.blast}
                      src={k?.logo}
                      // src={k?.logo?.split('.').pop()=="jpg"?k?.logo : profilePlaceholder}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    />
                    </a>
                  </div>
                );
              }
            })}
          </ItemsCarousel>
        </div>

        <div
          style={{
            width: 230,
            height: 90,
            padding: `0 ${chevronWidth}px`,
            marginLeft: 50,
          }}
        >
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={3}
            gutter={4}
            outsideChevron
            chevronWidth={chevronWidth}
            infiniteLoop={true}
          >
            {sponsorShips?.map((k, index) => {
              if (k.type === "Preferred" && k?.payment?.status== 'succeeded') {
                return (
                  <div
                    style={{
                      height: 70,
                      width: 70,
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      marginBottom: 15,
                    }}
                    key={index}
                  >
                    <a
                      href={k?.link}
                      target="_blank"
                    >
                    <img
                      className="sponsor-logo"
                      title={k.blast}
                      src={k?.logo}
                      // src={k?.logo?.split('.').pop()=="jpg"?k?.logo : profilePlaceholder}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    />
                    </a>
                  </div>
                );
              }
            })}
          </ItemsCarousel>
        </div>

        <div
          style={{
            width: 300,
            height: 50,
            flex: 1,
            padding: `0 ${chevronWidth}px`,
            marginLeft: 10,
            marginBottom: 15,
          }}
        >
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={causedItemIndex}
            numberOfCards={4}
            gutter={4}
            outsideChevron
            chevronWidth={chevronWidth}
            infiniteLoop={true}
          >
            {causedUsers?.map((k,oIndex) => {
              return (
                <div
                  key={oIndex}
                  style={{
                    height: 70,
                    width: 70,
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    className="sponsor-logo"
                    src={k?.logo?.split('.').pop()=="jpg"?k?.logo : profilePlaceholder}
                    title={k.value}
                    alt={k.value}
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  />
                </div>
              );
            })}
          </ItemsCarousel>
        </div>
      </div>
    </>
  );
};

SponsorContainer.propTypes = {
  sponsors: PropTypes.array,
};

export default SponsorContainer;
