import React, { useState, useEffect} from 'react';

const MissionTab = ({mission, fetchContent}) => {
  const [state, setState] = useState([])

  useEffect(() => fetchContent('Mission'),[]);

  return (
    <div className="tab-container" dangerouslySetInnerHTML={{__html: mission}}>
      {/* <p>
        We built 7GreenLights™ to help Independent Artists, Filmmakers, Designers,
        and Developers make their work visible to greater, more relevant,
        audiences. We also wanted to help fans and enthusiasts discover content
        of interest to them, and potentially connect with the very artists and
        makers creating it. To assist in our pursuit of this goal, we developed
        Venues™ — location-specific, genre-based streaming hubs to which
        Independent Artists and other content-creators may post their work, from
        which fans and enthusiasts have the ability to curate their personal
        GreenLight® Streams™ (filtered by Venue™), and also happen to be
        time-sensitive with each Venue™ eventually being locked (to new posts) and
        archived for the continued benefit and entertainment of artists and fans
        alike.
      </p>
      <p>
        We expect Independent Artists who participate in Venues™ to experience a
        bonus from the increased awareness they will generate for one another by
        posting their work, collectively, to the same location. Every GreenLight®
        awarded to an artist's song or video by a fan on  will make it slightly
        more realistic for that artist to free himself or herself from whatever
        3rd parties are siphoning funds from his or her respective revenue streams
        (i.e. via streaming, downloads, tickets, merch, etc.). To a certain
        extent, every active artist on the site should experience an opportunity
        to leverage the spike in recognition of their work (however fleeting it
        may be) to increase their share of future earnings, and also to avoid
        any additional contracts that enable greedy 3rd parties to reap more
        revenue from their downloads, streams and work, in general, than they
        do themselves.
      </p>
      <p>
        We have designed Venues™ to be driven and grown by Independent Artists and
        their fans. Our company's official motto is 'Momentum for the
        Independent®'.   intends to contribute to the success and financial
        security of Independent Artists and various other types of innovators from
        all over the world. We want to help dedicated Independent Artists and
        original storytellers thrive by making it easier for them to immerse
        themselves in their actual crafts and creative endeavors, rather than the
        marketing and promoting of them. We also want to help those Independent
        Artists who most listeners and/or viewers would agree — after streaming
        their song or video — probably should have at least 10X the
        fans/plays/follows that they count currently. We want to enable these
        artists' work to flourish by providing them with a platform that makes it
        far simpler, and less time- and energy-intensive, for them to share their
        work and connect with potential fans who, at the moment, are unaware they
        exist.
      </p>
      <p>
        Every unique Venue™ is a potential time capsule/launchpad/streaming hub
        all its own. The upside for Independent Artists is the opportunity to
        increase visibility of their work among relevant, engaged fans. The upside
        for fans and enthusiasts is the opportunity to discover and GreenLight®
        the dedicated Independent Artists who speak to them. The more
        Independent Artists, Filmmakers, Designers and Developers who post their
        work to Venues™ the more interesting and captivating each of these Venues™
        will be for fans, in terms of their ability to discover compelling voices
        and creators, and to access and enjoy their work. 7G™ is constantly
        developing, testing, and implementing fan-focused 'in-house' features and
        functionality (such as 'Venues™', 'GreenLight® Pop', 'Blasts™', and
        Notes™) to ensure that the experience for fans and enthusiasts is
        optimized as much as it is for artists.
      </p>
      <p>
        Stay tuned for 7G's Venue-driven™ causes of 2016!
      </p> */}
    </div>
  );
}
export default MissionTab;
