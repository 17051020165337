export const AUTH_TOKEN_KEY = 'auth_token';
export const TOKEN_HEADER_NAME = 'X-USER-TOKEN';
export const MIN_PASSWORD_LENGTH = 8;
export const LANDING_ARENA_PAGE_SIZE = 20;
export const ARENA_MEDIA_SIZE = 20;
export const STREAM_PAGE_SIZE = 20;
export const MAX_BLAST_LENGTH = 189;

export const oppositeFilters = [[0, 2], [3, 4],[1, 5, 6, 7, 8, 9, 10]];

export const oppositeSorts = [[1, 5, 2, 6, 7, 8]];

export const arenasFiltersIds = {
  active: 0,
  greenlit: 1,
  archived: 2,
  featured: 3,
  nonFeatured: 4,
  opentoall: 5,
  inviteonly: 6,
  inviteto:7,
  hosting:8,
  postedTo:9,
  sponsored:10
};

export const arenasSortIds = {
  most_greenlit: 1,
  least_greenlit: 5,
  most_time_remaining: 2,
  least_time_remaining: 6,
  venue_name_az: 7,
  venue_name_za: 8,
};

export const arenasFilters = [
  { id: arenasFiltersIds.greenlit, name: 'greenlit', label: 'GreenLit® Venues®' },
  { id: arenasFiltersIds.active, name: 'active', label: 'Live Venues®' },
  { id: arenasFiltersIds.archived, name: 'archived', label: 'Archived Venues®' },
  { id: arenasFiltersIds.featured, name: 'featured', label: 'Featured Venues®' },
  { id: arenasFiltersIds.nonFeatured,
    name: 'non_featured',
    label: 'Non-featured Venues®'
  },
];

export const arenasFiltersLeveled = [
  {
    "label": "Primary Filters",
    "isActive": true,
    "filters":[
      { id: arenasFiltersIds.active, name: 'active', label: 'Live Venues®' },
      { id: arenasFiltersIds.archived, name: 'archived', label: 'Archived Venues®' }
    ]
  },
  {
    "label": "Secondary Filters",
    "isActive": true,
    "filters":[
      { id: arenasFiltersIds.opentoall, name: 'opentoall', label: 'Public Venues®' },
      { id: arenasFiltersIds.inviteonly, name: 'inviteonly', label: 'Invite-Only Venues®' },
      { id: arenasFiltersIds.greenlit, name: 'greenlit', label: 'GreenLit Venues®' },
      { id: arenasFiltersIds.inviteto, name: 'inviteto', label: 'Venues® invited to' },
      { id: arenasFiltersIds.hosting, name: 'hosting', label: 'Venues® hosting' },
      { id: arenasFiltersIds.postedTo, name: 'postedTo', label: 'Venues® posted to' },
      { id: arenasFiltersIds.sponsored, name: 'sponsored', label: 'Venues® sponsored' },

    ]
  }
];

export const userPostedFilterId = 5;

export const arenasSortingOptions = [
  { id: arenasSortIds.most_greenlit , name: 'total_greenlights', label: 'Most GreenLit®' },
  { id: arenasSortIds.least_greenlit , name: 'total_greenlights', label: 'Least GreenLit®' },
  { id: arenasSortIds.most_time_remaining , name: 'remaining', label: 'Most Time Remaining' },
  { id: arenasSortIds.least_time_remaining , name: 'remaining', label: 'Least Time Remaining' },
  { id: arenasSortIds.venue_name_az , name: 'alphabetically', label: 'Venue® Name A-Z' },
  { id: arenasSortIds.venue_name_za , name: 'alphabetically', label: 'Venue® Name Z-A' },
];

export const mediaFilters = {
  arenas: { id: 0, name: 'arenas_ids' },
  types: { id: 1, name: 'content_types' },
  pops: { id: 2, name: 'pop_types' },
};

export const contentTypes = {
  other: 0,
  music: 1,
  video: 2,
};

// We also have a Others type defined on the API but was disabled since we
// dont support other media types yet.
export const mediaTypeFilters = [
  { id: contentTypes.music, name: 'music', label: 'Music' },
  { id: contentTypes.video, name: 'video', label: 'Video' },
  { id: contentTypes.other, name: 'other', label: 'Other' },
];

export const popsFilters = [
  { id: 4, name: 'show-all', label: 'Show All' },
  { id: 0, name: 'pops-a', label: 'Pops A' },
  { id: 1, name: 'pops-b', label: 'Pops B' },
  { id: 2, name: 'pops-c', label: 'Pops C' },
  { id: 3, name: 'pops-d', label: 'Pops D' },
];

/* export const mediaSortingOptions = [
  { id: 1, name: 'total_greenlights', label: 'Total GreenLights®' },
  { id: 3, name: 'newest', label: 'Recently Added' },
  { id: 0, name: 'alpha_creator', label: 'Creator Name' },
  { id: 2, name: 'alphabetically', label: 'Content Title' },
]; */

export const mediaSortingOptions = [
  { id: 0, name: 'most_greenlights', label: 'Most GreenLights®' },
  { id: 1, name: 'least_greenlights', label: 'Least GreenLights®' },
  { id: 2, name: 'title_az', label: 'Title (A-Z)' },
  { id: 3, name: 'title_za', label: 'Title (Z-A)' },
  { id: 4, name: 'newest', label: 'Newest' },
  { id: 5, name: 'oldest', label: 'Oldest' },
];

export const mediaStreamSortingOptions = [
  { id: 0, name: 'most_greenlights', label: 'Most GreenLights®' },
  { id: 1, name: 'least_greenlights', label: 'Least GreenLights®' },
  { id: 6, name: 'creator_az', label: 'Creator (A-Z)' },
  { id: 7, name: 'creator_za', label: 'Creator (Z-A)' },
  { id: 2, name: 'title_az', label: 'Title (A-Z)' },
  { id: 3, name: 'title_za', label: 'Title (Z-A)' },
  { id: 4, name: 'newest', label: 'Newest' },
  { id: 5, name: 'oldest', label: 'Oldest' },

];

export const aboutTabs = {
  help: 'help',
  mission: 'mission',
  faq: 'faq',
  about: 'about',
  sponsorship: 'sponsorship',
  legal: 'legal',
};

export const dndItemTypes = {
  NOTE: 'note'
};

export const notificationTypes = {
  MEDIA_CONTENT_GL: 'Notifications::MediaContentGreenlit',
  NOTE_FEATURED: 'Notifications::NoteFeatured',
  NOTE_GREENLIT: 'Notifications::NoteGreenlit',
  NOTE_UPLOADED: 'Notifications::NoteUploaded',
  USER_GREENLIT: 'Notifications::UserGreenlit',
  MEDIA_CONTENT_REPORTED: 'Notifications::MediaContentReported',
  VENUE_INVITE: 'Notifications::Invite',
  VENUE_HOST: 'Notifications::Host',
  SIGN_UP:'Notifications::Signup', 
  VENUE_REQUEST:'Notifications::VenueRequest',
  VENUE_QUEUED:'Notifications::Queued',
  VENUE_ADMITED:'Notifications::Admited',
  VENUE_REQUEST_INVITE:'Notifications::RequestInvite',
};

export const mediaListIds = {
  myMedia: 1,
  myStream: 2,
  arenaPage: 3,
  profilePage: 4,
};

export const shareableTypes = {
  media: 0,
  arena: 1,
};

export const supportedMediaSources = {
  spotify: { urlPieces: ['spotify:', 'spotify.com', 'open.spotify.com'] },
  soundcloud: { urlPieces: ['soundcloud.com'] },
  vimeo: { urlPieces: ['vimeo.com'] },
  youtube: { urlPieces: ['youtube.com'] },
  youtu: { urlPieces: ['youtu.be'] },
};

export const tooltipPages = {
  home: /\/$/g,
  mystream: /\/mystream$/g,
  mymedia: /\/mymedia$/g,
  profile: /\/profile\/\d+$/g,
  media: /(\/mymedia|\/mystream|\/arena\/\d+$)$/g,
  arena: /\/arena\/\d+$/g,
  featuredNotes: /\/media\/\d+\/notes$/g,
};

export const tooltipIds = {
  homeBlast: 'homeBlast',
  homeGreenlight: 'homeGreenlight',
  homeArena: 'homeArena',
  myMediaUpload: 'myMediaUpload',
  myMediaExternalLinks: 'myMediaExternalLinks',
  myMediaBlast: 'myMediaBlast',
  mediaNotes: 'mediaNotes',
  notesGreenlight: 'notesGreenlight',
  myStream: 'myStream',
  myStreamFilters: 'myStreamFilters',
  myProfile: 'myProfile',
  arenaTime: 'arenaTime',
  arenaPost: 'arenaPost',
  arenaSponsoring: 'arenaSponsoring',
  featuredNotes: 'featuredNotes',
};

export const tooltipArray = [
  {
    id: tooltipIds.homeBlast,
    order: 1,
    text: 'Blasts™ are updates that travel with songs and videos you post to Venues™',
    classname: 'home-blast',
    page: tooltipPages.home,
  },
  {
    id: tooltipIds.homeArena,
    order: 2,
    text: 'Venues™ are genre-based, location-specific events that you may post your songs and videos to',
    classname: 'home-arena',
    page: tooltipPages.home,
  },
  {
    id: tooltipIds.homeGreenlight,
    order: 3,
    text: 'GreenLight® Venues™ of interest in order to track what\'s happening and stay informed with notifications',
    classname: 'home-greenlight',
    page: tooltipPages.home,
  },
  {
    id: tooltipIds.myMediaUpload,
    order: 1,
    text: 'Once you upload your content from an approved 3rd party platform (e.g. SoundCloud, Vimeo, Spotify, YouTube), you may post that content to an Venue™',
    classname: 'mymedia-upload',
    page: tooltipPages.mymedia,
  },
  {
    id: tooltipIds.myMediaBlast,
    order: 2,
    text: 'Post a Blast™ — Share news and updates that will travel with your content to any Venues™ you post your content to',
    classname: 'mymedia-blast',
    page: tooltipPages.mymedia,
  },
  {
    id: tooltipIds.myMediaExternalLinks,
    order: 3,
    text: 'Is your song or video available to stream from multiple platforms? Add all relevant links here prior to selecting ‘Upload Media\'',
    classname: 'mymedia-external-links',
    page: tooltipPages.mymedia,
  },
  {
    id: tooltipIds.mediaNotes,
    order: 1,
    text: 'Notes™ are images fans may post to artist content.  Notes™ posted to an artist\'s song or video will not be visible to other users unless \'featured\' by the artist.  Notes™ ‘featured\' by an artist will travel with his or her content to any Venue™ the artist posts their content to',
    classname: 'media-notes',
    page: tooltipPages.media,
  },
  {
    id: tooltipIds.notesGreenlight,
    order: 2,
    text: 'GreenLight® any Notes™ that artists feature with their content by selecting the GreenLight® icon appearing in the upper-right of every featured Note™.  Users will have the ability to sort content posted to Venues™ by Total GreenLights®',
    classname: 'notes-greenlight',
    page: tooltipPages.media,
  },
  {
    id: tooltipIds.myStream,
    order: 1,
    text: 'All content you GreenLight® appears here.  You can "Filter by Venue™" in order to stream only the content you have GreenLit® in a specific Venue™',
    classname: 'mystream',
    page: tooltipPages.mystream,
  },
  {
    id: tooltipIds.myStreamFilters,
    order: 2,
    text: 'Filter content you have GreenLit® by Venue™ here',
    classname: 'mystream-filters',
    page: tooltipPages.mystream,
  },
  {
    order: 1,
    id: tooltipIds.myProfile,
    text: 'View users you have GreenLit® and update your bio from here',
    classname: 'myprofile',
    page: tooltipPages.profile,
  },
  {
    id: tooltipIds.arenaTime,
    order: 1,
    text: 'Time remaining before this Venue™ is archived',
    classname: 'arena-time',
    page: tooltipPages.arena,
  },
  {
    id: tooltipIds.arenaPost,
    order: 2,
    text: 'Post your songs or videos to this Venue™ here!',
    classname: 'arena-post',
    page: tooltipPages.arena,
  },
  {
    id: tooltipIds.arenaSponsoring,
    order: 3,
    text: 'Interested in sponsoring this Venue™?  If you would like to show your support for the community of independent artists and fans who are posting and streaming content from this Venue™, send us your info and we will follow up soon!',
    classname: 'arena-sponsoring',
    page: tooltipPages.arena,
  },
  {
    id: tooltipIds.featuredNotes,
    order: 1,
    text: 'Feature any Notes™ that other users have posted to your content by "dragging" them to the \'featured\' row above.  Any Notes™ that you feature will be visible to other users wherever you post your content',
    classname: 'featured-notes-tt',
    page: tooltipPages.featuredNotes,
  }
];

export const profileTabs = {
  media: 'media',
  bio: 'bio',
  blasts: 'blasts',
  greenlitPeople: 'greenlit_people',
  greenlightPeople: 'greenlight_people',
  notes: 'notes',
  paymentDetails: 'paymentDetails',
  paymentDone: 'paymentDone',
  paymentReceived: 'paymentReceived',
  venueSponsorship: 'venueSponsorship',
  venueRequest:'venueRequest'
};

export const toolTipMessages = {
  venueInfo: {
    id: "venueId",
    place:"top",
    type:"info",
    effect:"float",
    /* message: 'Venues® are time-sensitive digital events that Creators can post their content to and participate together.', */
    message: 'Venues® are time-sensitive events that Creators post their content to and participate in collectively'
  },
  blastInfo: {
    id: "blastId",
    place:"top",
    type:"info",
    effect:"float",
    /* message: ' Blasts™ are announcements that update alongside your content in whatever Venues® you post to.' */
    message: 'Blasts™ are updates that travel alongside your content in any Venue® you post to'
  },
  notesInfo: {
    id: "notesId",
    place:"top",
    type:"info",
    effect:"float",
    /* message: "Notes™ are images posted by fans and tied to Creators' content - they are only visible to others if the Creator features them." */
    message: "Notes™ are images posted by fans that Creators can feature alongside their Content – if a Creator does not feature a Note™ it will not be visible to any other users"

  },
  dockInfo: {
    id: "dockId",
    place:"top",
    type:"info",
    effect:"float",
    /* message: "Your Dock™ in order to post your content to a Venue® you must first post it to your Dock™ from YouTube or Spotify." */
    message: "In order to Post to a Venue® you must first Post your Content to your Dock™ from an approved 3rd party (YouTube, Spotify, etc.)"
  },
  greenLightInfo: {
    id: "greenLightInfo",
    place:"top",
    type:"info",
    effect:"float",
    /* message: 'Your 7G Stream™ features all content you have GreenLit® - you can filter by Venue® among other options.' */
    message: 'Your 7G Stream™ allows you to access all content you have GreenLit™ – you can filter by Venue® to stream content you have GreenLit™ in a specific Venue®'
  },
  sponsorship: {
    id:"sponsorshipId",
    place:"top",
    type:"info",
    effect:"float",
    /* message: "Interested in sponsoring this Venue®?  If you would like to show your support for the community of independent artists and fans who are posting and streaming content from this Venue®, you can sponsor Venues®" */
    message: "Sponsor this Venue® to show support and connect with communities of Creators and fans – Primary Sponsors can earn a share of Venue Revenue™"
  }

}