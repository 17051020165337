import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../utils/Modal";
import xIcon from "../App/img/x-dark.png";

import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./TabSelector";
import SponsorForm from "./SponsorForm";
import PaymentForm from "./PaymentForm";
import { SubmissionError } from "redux-form";

import Api from "../../api/apiService";

function ConfirmPayment({ isInitOpen, sponsorModal }) {
  const toggleModalWindow = (event) => {
    event.stopPropagation();
    console.log(" Confirm Payment modal ", event, event.target.value);
    let toProceed;
    if (event.target.value == "continue") {
      toProceed = true;
    } else {
      toProceed = false;
    }
    sponsorModal(toProceed);
  };
  return (
    <Modal
      isOpen={isInitOpen}
      className={`sponsor-modal`}
      overlayClassName={`auth-popup-overlay`}
    >
      <div className="message-container" style={{ height: "50vh" }}>
        <span
          onClick={toggleModalWindow}
          title="Close"
          style={{
            position: "absolute",
            top: "3px",
            right: "4px",
            background: "#ff00006b",
            padding: "6px 8px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <img src={xIcon} />
        </span>
        <h1>{`Venue has been Archived`}</h1>

        <div className="tab-data-container" style={{ height: "25vh", overflowY:'hidden'}}>
          <h1>{`Would you like to proceed?`}</h1>
          <button
            name="button"
            type="button"
            className={`form-button greenlight-btn`}
            style={{ marginRight: "5px" }}
            value="continue"
            onClick={toggleModalWindow}
          >
            Continue
          </button>
          <button
            name="button"
            type="button"
            className={`form-button dark-button`}
            value="cancel"
            onClick={toggleModalWindow}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmPayment;
