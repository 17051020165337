import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchDate } from "../../../api/arenaApi";
import { generateImagePath } from "../../../utils/helpers";

const VenueRequestedTabList = ({ venueRequest }) => {
  const [currentDate, setCurrentDate] = useState();
  const [expandedDescription, setExpandedDescription] = useState({});

  useEffect(() => {
    fetchCurrentDate();

  }, []);
  const toggleDescription = (itemId) => {
    setExpandedDescription((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };
  const fetchCurrentDate = async () => {
    setCurrentDate(await fetchDate());
  };
  const formatDate = (dt) => {
    return new Date(dt).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  return (
    <>

      {<table className="profile-table">
        <thead>
          <tr>
              <td className="coming-soon"><label className="profile-table-heading">Venue® Image</label></td>
              <td className="coming-soon"><label className="profile-table-heading">Venue® Name</label></td>
              <td className="coming-soon"><label className="profile-table-heading">Venue® Type</label></td>
              <td className="coming-soon"><label className="profile-table-heading">Venue® Description</label></td>
              <td className="coming-soon"><label className="profile-table-heading">Venue® Status</label></td>
          </tr>
        </thead>
        <tbody>
          {venueRequest.map((item, index) => (
            <tr key={item.id} className="notes-list-row">
              <td className="table-label">
                <div className="notes-list-item">
                  <div className="posted-content">
                    <div className="content-img">
                      <img className="media-item-img" src={generateImagePath(item)} alt="Venue Image" />
                    </div>

                  </div>
                </div>
              </td>
              <td className="table-label">
                {item.name}
              </td>
              <td className="table-label">
                {item.arenaType}
              </td>
              <td className="table-label" style={{width:"50%"}}>
                {item?.description?.length > 50 && !expandedDescription[item.id] ? (
                  <span>{item?.description.slice(0, 50)}...</span>
                ) : (
                  <span>{item?.description}</span>
                )}
                {item?.description?.length > 50 && (
                  <button
                    style={{ color: "blue" }}
                    className="toggle-button"
                    onClick={() => toggleDescription(item.id)}
                  >
                    {expandedDescription[item.id] ? "Show Less" : "Show More"}
                  </button>
                )}
              </td>

              <td className="table-label">
                {item.status}
              </td>
            </tr>
          ))}

        </tbody>
      </table>}
    </>
  );
};

VenueRequestedTabList.propTypes = {
  user: PropTypes.object,
  sponsorships: PropTypes.array,
};

export default VenueRequestedTabList;
