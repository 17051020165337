import React, { useState, useEffect} from 'react';

const HelpTab = ({help, fetchContent}) => {

  const [state, setState] = useState([])

  useEffect(() => fetchContent('Help'),[]);

  return (
  <div className="tab-container" dangerouslySetInnerHTML={{__html: help}}>
    {/* <p>
      Question or comment about 7G
    </p>
    <p>
      Send us a message and we'll respond:
      <a
        className="support-mail"
        href="mailto:hello@7greenlights.com"
      >
        hello@7greenlights.com
      </a>
    </p> */}
  </div>
  )
};

export default HelpTab;
