import api from './apiService.js';
import { getStore } from '../index';

export const sendBlast = (blast) => {
  let user = getStore().getState().loginReducer.user;
  let data = {
    userId: user.id,
    text: blast.text
  }
  return api.post('Blasts', data );
};

export  const getCurrentBlast=(userId)=>{
  let url = `Blasts/currentBlast/${userId}`;
  return api.get(url);
}
