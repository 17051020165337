import * as types from './actionTypes';
import * as helpApi from '../api/helpApi';
import * as constants from '../constants';

export function fetchContentRequest() {
    return {
        type: types.FETCH_CONTENT_REQUEST,
    };
}
export function fetchContentSuccess(content,name) {

    return {
        type: types.FETCH_CONTENT_SUCCESS,
        content,
    };
}

export function fetchContentFailure(message) {
    return {
        type: types.FETCH_CONTENT_FAILURE,
        message,
    };
}

export function fetchContentTabsSuccess(tabs) {

    return {
        type: types.FETCH_CONTENT_TABS_SUCCESS,
        tabs,
    };
}

export function fetchContentTabsFailure(message) {
    return {
        type: types.FETCH_CONTENT_TABS_FAILURE,
        message,
    };
}

export function stopMoreContent() {
    return {
        type: types.STOP_MORE_CONTENT
    };
}

export function fetchContent(name) {
    return (dispatch) => {
        dispatch(fetchContentRequest());
        const filter = {"where":{"name": name, "isActive": true}, limit:1, fields: ['name','description', 'label']}
        return helpApi.fetchContent(filter)
        .then((response) => {
            const content = {
                    content: response[0],
                    name: name
            }
            dispatch(fetchContentSuccess(content, name));
            dispatch(stopMoreContent())
        })
        .catch((error) => {
            dispatch(fetchContentFailure(error));
            console.error(error);
        });
    };
}

export function fetchContentTabs() {
    return (dispatch) => {
        dispatch(fetchContentRequest());
        const filter = {where: {"type": "tabs", "isActive": true}, fields: ['name','label'], order:['orderBy ASC']}
        return helpApi.fetchContentTabs(filter)
        .then((response) => {
            const content = {
                content: response
            }
            dispatch(fetchContentTabsSuccess(content, name));
        })
        .catch((error) => {
            dispatch(fetchContentTabsFailure(error));
            console.error(error);
        });
    };
}