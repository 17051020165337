import React from 'react'; import PropTypes from 'prop-types';


const ProfileStats = ({ user, earnedRevenue }) => {
  const formatMoney = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount)
  }
  return (
  <div className="profile-stats-container">
    <div className="profile-stats">
      <div className="profile-stat">
        <label className="stat-title">Total GreenLights™ to Content</label>
        <label className="stat">{user?.greenlightsToMediaCount || 0}</label>
      </div>
      <div className="profile-stat">
        <label className="stat-title">Total Notes™ to Content</label>
        <label className="stat">{user?.noteUploadedCount || 0}</label>
      </div>
      <div className="profile-stat">
        <label className="stat-title">Total Blasts™ Posted</label>
        <label className="stat">{user?.blastCount || 0}</label>
      </div>
      <div className="profile-stat">
        <label className="stat-title">Total Venues® Posted to</label>
        <label className="stat">{user?.arenasMediaContentCount || 0}</label>
      </div>
      <div className="profile-stat">
        <label className="stat-title">Total Venues® Sponsored</label>
        <label className="stat">{(user?.primarySponsorsCount + user.secondarySponsorsCount) || 0}</label>
      </div>
      <div className="profile-stat">
        <label className="stat-title">Total Venue Revenue™ Earned </label>
        <label className="stat">{formatMoney(earnedRevenue)} </label>
      </div>
    </div>
  </div>
);
}

ProfileStats.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfileStats;
