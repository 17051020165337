import React from 'react'; import PropTypes from 'prop-types';

import GreenlitUser from './GreenlitUser';

const GreenlitPeopleTab = ({ greenlightUser, greenlitPeople, user }) => {

  return (
    <div className="tab">

      {
        greenlitPeople && greenlitPeople.length > 0 ?
        greenlitPeople.map((data, index) =>
          <GreenlitUser
            key={index}
            user={(data.greenlitUser) ? data.greenlitUser : data.user}
            greenlightUser={greenlightUser}
            currentUser={user}
          />
        )
         :
         <label className="coming-soon">You don't have any GREENLIT® PEOPLE.</label>
      }
    </div>
  );
};

GreenlitPeopleTab.propTypes = {
  greenlightUser: PropTypes.func.isRequired,
  greenlitPeople: PropTypes.array,
  user: PropTypes.object.isRequired,
};

export default GreenlitPeopleTab;
