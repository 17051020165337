import React from 'react'; import PropTypes from 'prop-types';

import PaymentDetailsTab from './PaymentDetailsTab';
import PaymentDoneTab from './PaymentDoneTab';
import PaymentReceivedTab from './PaymentReceivedTab';
import { profileTabs } from 'constants';

const RenderTab = ({ user, selectedTab, isMine, updateUser, tab1 }) => {

  switch (selectedTab) {    
    case profileTabs.paymentDetails:
    return (
        <PaymentDetailsTab          
          user={user}
        />
      );  
    case profileTabs.paymentDone:
    return (
        <PaymentDoneTab          
          user={user}
        />
      );
      case profileTabs.paymentReceived:
      return (
          <PaymentReceivedTab          
            user={user}
          />
        ); 
    default:
     return <PaymentDetailsTab user={user} />
  }
};

RenderTab.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired, 
  paymentDetails: PropTypes.array,
  paymentDone: PropTypes.array,
  paymentReceived: PropTypes.array,  
  user: PropTypes.object.isRequired,
  isMine: PropTypes.bool.isRequired,
};

export default RenderTab;
