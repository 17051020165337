import React from 'react'; import PropTypes from 'prop-types';

import Filter from './Filter';
import { arenasFilters, arenasFiltersLeveled } from 'constants';
import { preventBoth } from '../../../utils/helpers';

const FilterBox = ({ enabled, toggleFilter, authenticated }) => {
  let filters = arenasFilters;
  let filterGroup = arenasFiltersLeveled;
  const getFilterGroup = (filters) => {

    return (
        filters.map((filter, index) => {
            let isActive = enabled.indexOf(filter.id) !== -1;
            return (
              <Filter
                key={index}
                filter={filter}
                isActive={isActive}
                toggleFilter={(id) => {
                  preventBoth(id, enabled, toggleFilter)
                }}
              />
            )
          })
    )
  }
  return (
    <div className="filters-box">
      <div className="filter-header">
        <h2>Filters</h2>
      </div>
      <div className="filter-container">
        {
          filterGroup.map((group, offset) => {
              const filters = group.filters
              return (
                <div className="filter-list" key={offset}>
                  <div className="filter-label">
                    {group.label}
                  </div>
                    {getFilterGroup(filters)}
                </div>
              )
          })
        }
      </div>
    </div>
  );
};

FilterBox.propTypes = {
  allArenas: PropTypes.array,
  enabled: PropTypes.array,
  toggleFilter: PropTypes.func,
  filterSortId: PropTypes.string,
  authenticated: PropTypes.bool,
};

export default FilterBox;
