import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VenueRequestedTabList from './VenueRequestedTabList';

const VenueRequestedTab = (props) => {

  const {user, venueRequest } = props
  return (
    <div className="tab">
      <VenueRequestedTabList venueRequest={venueRequest} user = {user}/>
    </div>
  );
};

VenueRequestedTab.propTypes = {
  user: PropTypes.object,
  venueRequest: PropTypes.array,
};

export default VenueRequestedTab;
