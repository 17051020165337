import React from "react";
import PropTypes from "prop-types";

import Modal from "../../utils/Modal";
import { Link } from "react-router";

const UserDropDown = ({ isOpen, onRequestClose, logout, user }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    className="dropdown-bubble"
    overlayClassName="modal-overlay"
  >
    <div className="user-dropdown">
      <button> <Link to="">My Blasts™</Link></button>
      {/* <button> <Link to="">My GreenLights®</Link></button> */}
      <button> <Link to="">My Sponsorships</Link></button>
      {/* <button>
        <Link to={`/payment/${user.id}`}>Payment</Link>
      </button> */}
      <button onClick={logout}>Log out</button>
    </div>
  </Modal>
);

UserDropDown.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserDropDown;
