import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import Api from "../../api/apiService";
import FormField from "./FormField";

import { validate as validateForm } from "validate.js";
import { SubmissionError } from "redux-form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const validate = (values) => {
  if (values?.link) {
    let errors = validateForm(values, { link: { url: true } });
    return errors ? errors : {};
  }
};

const PaymentForm = (props) => {
  const stripe = loadStripe("pk_live_u9Bghp7efoIFcbExU4gh4h9K");
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    arena,
    user,
    type,
    fetchArenaSponsors,
    arenaSponsors,
    paymentStatus,
  } = props;
  const [clientSecret, setClientSecret] = useState("");
  const [paymentAmount, setPaymentAmount ] = useState(0);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    let amount;
    //alert(type)
    if (type == "Primary Sponsor") {
      amount = arena.primarySponsorPrice;
      setPaymentAmount(amount)
    } else {
      amount = arena.secondarySponsorPrice;
      setPaymentAmount(amount)
    }

    Api.post("Payments/payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: type, amount: amount }] }),
    }).then((data) => setClientSecret(data.clientSecret));
  }, []);

  const savePayment = (paymentData) => {

    const paymentIntent = paymentData.paymentIntent;
    //console.log('PaymentIntent ',paymentIntent)
    const venueSponsorship = arenaSponsors[arena.id][0];
    const date = new Date();
    const dd = today.getDate();
    const mm = today.getMonth()+1;
    const yyyy = today.getFullYear();
    const dateString = `${yyyy}${mm}${dd}`;
    const data = {
      amount: paymentIntent.amount / 100,
      userId: user.id,
      arenaId: arena.id,
      orderId: `sponsor-${arena.id}-${dateString}`,
      arenasSponsorId: venueSponsorship.id,
      currency: paymentIntent.currency,
      stripeCustomerId: "string",
      stripeCardId: "string",
      purpose: "string",
      status: paymentIntent.status,
      channel: "stripe",
      paymentIntent: paymentIntent,
      type: type === "Primary Sponsor" ? "Primary" : "Preferred",
    };

    Api.post("Payments", data).then((response) => {
      fetchArenaSponsors(arena.id);
    });
  };

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      {clientSecret && (
        <Elements stripe={stripe} options={options}>
          <CheckoutForm
            paymentStatus={paymentStatus}
            arena={arena}
            user={user}
            type={type}
            savePayment={savePayment}
            paymentAmount={paymentAmount}
          />
        </Elements>
      )}
    </>
  );
};

export default reduxForm({
  form: "paymentForm",
  touchOnBlur: true,
  touchOnChange: true,
  // persistentSubmitErrors: true,
  validate,
})(PaymentForm);
