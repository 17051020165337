import api from './apiService.js';
import * as constants from '../constants';

const createQueryString = (params) => {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
};

const orderColumn = 'greenlightsCount';
const orderDirection = 'DESC';

const filter = {
  order: `${orderColumn} ${orderDirection}`,
  fields: {
    image: true,
    name: true,
    arenaType: true,
    endDate: true,
    status: true,
    createdBy: true,
    id: true,
    greenlightsCount: true,
    createdAt: true,
  },
};

export const fetchVenueRequest = (userId) => {
  return api.get(`VenueRequests`);
};
export const fetchVenueRequestData = () => {
  const filterString = JSON.stringify(filter);
  const queryString = createQueryString({ filter: filterString });
  return api.get(`VenueRequests?${queryString}`);
};
// filter[include]=Greenlights
export const saveVenueRequest = (venueData) => {
  for (const [key, value] of venueData.entries()) {
  }
  return api.post(`VenueRequests/createVenueRequest`, venueData);
};

