import React from 'react'; import PropTypes from 'prop-types';

import speakerIcon from '../../Blast/img/green-speaker.png';
import Greenlight from '../../Greenlight/Greenlight';
import { Link } from 'react-router';
import profilePlaceholder from '../../App/img/profile-ph.png';

const GreenlightUser = ({ user, greenlightUser, currentUser }) => (
  <div className="greenlit-user-container">
    <div className="greenlit-user">
      <img
        className="profile-pic"
        src={user.profilePicture || profilePlaceholder}
      />

      <div className="profile-data">
        <Link to={`/profile/${user.id}`}>{user.name}</Link>
        {
          user.lastBlast &&
          <div className="last-blast">
            <img src={speakerIcon}/>
            <label>{`"${user.lastBlast}"`}</label>
          </div>
        }
      </div>
        
      <Greenlight
        greenlit={user.greenlights && user.greenlights.length > 0 ? true : false}
        counter={user.greenlightsCount}
        greenlightAction={() => greenlightUser(user.id, currentUser.id)}
        noPadding={true}
      />
    </div>
    <div className="divider-line soft"/>
  </div>
);

GreenlightUser.propTypes = {
  user: PropTypes.object.isRequired,
  greenlightUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default GreenlightUser;
