import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../utils/Modal'
import xIcon from '../App/img/x-dark.png'
import { fetchInvite, acceptInvite, fetchArenaInfo } from '../../api/arenaApi';


const Invite = ({ data, type, title, ishost, fromnotification, onAccept = null }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [venueTitle, setVenueTitle] = useState(title ? title: null)
  const [venueId, setVenueId] = useState(null)
  const [isInviteAccepted, setIsInviteAccepted] = useState(false)
  const [isInviteExpired, setIsInvitedExpired] = useState(false)
  const [venue, setVenue] = useState(null)
  const [message, setMessage] = useState(`Are you sure to accept Invite?`)

  useEffect( () => {
    let condition;
    //console.log('in useeffect ', fromnotification ,data)
    if(fromnotification === true) {
      condition = {"where":{ id: data.eventEntityId }}
    } else {
      condition = {
        "where": {
          venueId: {"in":[data.venueId]},
          invitedTo: {"in":[data.invitedTo]},
          inviteType: {"in":['Content Creator']}
        }
      }
    }

    fetchInvite(condition).then((inviteData) => {
      inviteData = inviteData[0]
      if(inviteData?.accepted === true) {
        setIsInviteAccepted(true)
      }
      if(inviteData?.status === 'inactive'){
        setIsInvitedExpired(true)
        setIsAccepted(true)
        setMessage('Oops! Invite is expired')
      }
      fetchArenaInfo(inviteData?.venueId).then(result => {
        setVenue(result)
        setVenueTitle(result.name)
        setVenueId(result.id)
      }).catch((error ) => {
        console.log('Error in fetching Venue Details ', error)
      })
    }).catch((error) => {
      console.log('invited data invitation is already accepted ', error)
    })
  },[])
  //if(data.venueId == '6385f2bc098bdf79240ec29f'){
    //console.log('ON ACCEPT ', data.venueId, onAccept)
  //}

  const acceptVenueInvite = async (event) => {
    try {
      event.stopPropagation()
      let condition;// = {"where":{ id: data.eventEntityId }}
      if(fromnotification === true) {
        condition = {"where":{ id: data.eventEntityId }}
      } else {
        condition = {
          "where": {
            venueId: {"in":[data.venueId]},
            invitedTo: {"in":[data.invitedTo]},
            inviteType: {"in":['Content Creator']}
          }
        }
      }
      const invites = await fetchInvite(condition)
      const invite = invites[0]
      console.log('before accepting invite ', invite)
      if(invite?.accepted == false && invite?.status == 'active') {
        const dataToUpdate = {
          "accepted": true
        }
        console.log('inside of it' )
        const inviteResult = await acceptInvite(invite.id, dataToUpdate)
        onAccept()
        setMessage('Invite accepted!')
      } else {
        console.log('ELSE' )
        if(invite?.accepted == true) {
          setMessage('You already have accepted invite!')
        }
        if(invite?.status == 'inactive') {
          setMessage('Oops! Invite is expired')
        }
      }
      setIsAccepted(true)
    }catch(error) {
      console.log('Error in accepting invite sss', error)
      setMessage('Error in accepting invite sss', error)
    }

  }
  const handleOpen = (event) => {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }
  return (
    <div>
    { !isInviteAccepted ?
    <>
      {
        !ishost && venueTitle &&
        <button
          onClick={handleOpen}
          className={type == 'button' ? 'dark-button' : 'invite-link'}
        >
          {venueTitle ? `Join ${venueTitle}`: 'Join Venue!'}
        </button>
      }
      {
        ishost &&
        <span className='link-text'> {venueTitle} </span>
      }
      <Modal
        isOpen={isOpen}
        className={`message-modal`}
        overlayClassName={`auth-popup-overlay`}
      >
        <div className="message-container">
            <button>
              <img src={xIcon} onClick={handleOpen} />
            </button>
            <h1>{`Venue Invite ${venue?.name}`}</h1>

            <p style={{marginTop:'5px'}}>{message}</p>
            <div>
            {!isAccepted &&
              <button
                type="button"
                className="invite-button"
                onClick={acceptVenueInvite}
              >
                Accept
              </button>
            }
            {isAccepted &&
              <button
                type="button"
                className="dark-button"
                onClick={handleOpen}
              >
                Close
              </button>
            }
            </div>
          </div>
      </Modal>
      </>
    : ''}

  </div>

  )
};

Invite.propTypes = {
  //arena: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onAccept: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])

};

export default Invite;
