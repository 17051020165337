import React from "react";
import xIcon from '../App/img/x-dark.png';
import Modal from '../../utils/Modal';

const AlreadyPostedModel = ({ isInitOpen, title, toggleModal, message}) => {

  const handleOpen = (event) => {
    event.stopPropagation();
    toggleModal(!isInitOpen);
  };
  // const onAccept = () => {
  //   callBack(!isInitOpen);
  // };

  return (
    <Modal
      isOpen={isInitOpen}
      className={`message-modal`}
      overlayClassName={`auth-popup-overlay`}
    >
      <div className="message-container">
        <button>
          <img src={xIcon} onClick={handleOpen} />
        </button>
        <h1>{title}</h1>

        <p style={{ marginTop: "5px" }}>{message}</p>

        <div>
          <button type="button" className="dark-button" onClick={handleOpen}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AlreadyPostedModel;
